<template>
  <UIModal v-bind="{ actions, icon, isOpen, title, type }" @hide="handleHide">
    {{ message }}
  </UIModal>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { DialogActionButton, DialogIcon } from './utils/types'
import { ModalType } from './utils/enums'

import { UIModal } from '..'
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'

export type Props = {
  title: string
  message?: string
  label: string
}

type Emits = {
  remove: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const isOpen = defineModel<boolean>({ required: true })

const exposeObj = {
  toggle(flag: boolean) {
    isOpen.value = flag
    return exposeObj
  },
}

defineExpose(exposeObj)

const icon: DialogIcon = {
  name: ExclamationCircleIcon,
  color: 'red',
  position: 'left',
}

const type = ModalType.ACTION_DIALOG

const actions = computed<DialogActionButton[]>(() => [
  {
    label: props.label,
    onClick: handleRemove,
    buttonVariant: 'danger',
  },
])

const handleHide = () => {
  isOpen.value = false
}

const handleRemove = () => {
  isOpen.value = false
  emit('remove')
}
</script>

<script lang="ts">
export default {
  name: 'UIRemoveDialog',
}
</script>
