<template>
  <UIButton
    v-if="!isReadonly"
    v-bind="{ size }"
    :leading="ArrowDownTrayIcon"
    label="Import"
    variant="gray"
    fill="light"
    hide-label-to-sm
  />
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { ButtonSize, ReadonlyMode } from '@types'

import { READONLY_MODE } from '@/const/common'

import { ArrowDownTrayIcon } from '@heroicons/vue/24/outline'

import { UIButton } from '@ui'

const size = inject<ButtonSize>('size')
const isReadonly = inject<ReadonlyMode>(READONLY_MODE)
</script>
