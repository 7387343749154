<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog v-bind="{ zIndex, size: '3md', title }" @hide="handleClose">
      <div v-if="$slots.loading" class="wizzard__loading">
        <UIPreloader />
        <div class="wizzard__loading-text">
          <slot name="loading" />
        </div>
      </div>
      <template v-else>
        <slot name="top" />
        <div class="wizzard">
          <div class="wizzard__sidebar">
            <UIStepsNavigation
              v-model="currentStep"
              v-bind="{ shrink, steps, skipped, completed }"
            />
          </div>
          <slot />
        </div>
      </template>
      <template v-if="!$slots.loading" #buttons>
        <slot name="buttons" />
      </template>
    </UIDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { watch } from 'vue'

import { StepsPosition } from '@types'

import { TransitionRoot } from '@headlessui/vue'
import { UIDialog, UIPreloader, UIStepsNavigation } from '@ui'

type Props = {
  title: string
  isOpen: boolean
  zIndex: number
  steps: StepsPosition[]
  completed: number[]
  skipped: number[]
  shrink?: boolean
}

type Emits = {
  close: []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const currentStep = defineModel<number>({ default: 0 })

const visited = defineModel<number[]>('visited', { default: [] })

const handleClose = () => {
  emit('close')
}

watch(
  currentStep,
  value => {
    if (visited.value.includes(value)) return
    visited.value.push(value)
  },
  { immediate: true },
)
</script>

<style>
.wizzard {
  @apply flex flex-col md:flex-row;
  @apply gap-6;

  &__sidebar {
    @apply md:w-1/3;
    @apply shrink-0;
  }

  &__form {
    @apply flex flex-col flex-auto;
    @apply gap-6 md:gap-8;
  }

  &__summary {
    @apply flex flex-col;
    @apply gap-3;
  }

  &__summary-title {
    @apply font-medium text-gray-600;
  }

  &__loading {
    @apply min-h-56;
    @apply flex items-center justify-center;
    @apply gap-4;
  }
}
</style>
