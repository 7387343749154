<template>
  <div v-if="!isReadonly" class="asset-drawer__form">
    <UIServicesDropdown
      v-model="service"
      :disabled="isButtonDisabled"
      label="Document link"
      placement="bottom-start"
      variant="secondary"
      size="sm"
      show-label-on-mobile
      full
    />
  </div>
  <UIGridSkeleton
    v-if="isLoading"
    v-bind="{ collapseWidth, columns, sm }"
    message="Loading documents..."
  />
  <UIGrid
    v-else
    v-model:sort="sort"
    data-refid="assetDocumentsList"
    v-bind="{ collapseWidth, columns, items, sm }"
  >
    <template v-if="!isReadonly" #actions="{ item, size }">
      <UIButton
        v-if="item"
        v-bind="{ size }"
        label="Edit"
        :leading="PencilIcon"
        fill="light"
        @click="handleClickEdit(item)"
      />
      <UIButton
        v-if="item"
        v-bind="{ size }"
        label="Unlink"
        :leading="TrashIcon"
        variant="danger"
        fill="light"
        @click="handleClickUnlink(item)"
      />
      <UIButton
        v-if="item"
        v-bind="{ size }"
        label="Go to link"
        :leading="ArrowTopRightOnSquareIcon"
        variant="gray"
        fill="light"
        @click="handleClickExternalLink(item)"
      />
    </template>
    <template #cellName="{ displayValue, item }">
      <div class="asset-drawer-documents__link">
        <div class="asset-drawer-documents__link-icon">
          <UIServiceIcon :path="displayValue" />
        </div>
        <a
          v-if="isPathClickable(item.path)"
          :href="item.path"
          target="_blank"
          class="ui-link"
        >
          {{ displayValue }}
        </a>
        <span v-else>{{ displayValue }}</span>
      </div>
    </template>
    <template #cellTags="{ displayValue }">
      <UITagsList
        v-if="displayValue.length"
        :model-value="displayValue"
        readonly
      />
      <span v-else />
    </template>
  </UIGrid>
  <UIRemoveDialog
    v-model="removeDialog"
    v-bind="{
      title: removeDialogTitle,
      message: 'Are you sure you want to unlink this document?',
      label: 'Unlink',
    }"
    @remove="handleUnlink"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { AssetClass } from '..'
import { ModalEvent, ReadonlyMode, Sort } from '@types'
import { Document } from '@/entities/documents/utils/types'
import { EntityEvent } from '@/entities/utils/enums'

import { ASSET_FIELD } from '../utils/const'
import { READONLY_MODE } from '@/const/common'

import { getDocumentTagName, isPathClickable } from '@/helpers/documents'
import { handleCatchedError } from '@/helpers/common'

import { useNotifications } from '@/plugins/notification'
import { useDocumentService } from '@/plugins/services'

import useGridSort from '@/components/hooks/gridSort'

import { useDocumentsBunchStore } from '@/store/documents/bunch'
import { useDocumentsStore } from '@/store/documents/index'
import { useModalsStore } from '@/store/modals'

import {
  ArrowTopRightOnSquareIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/vue/24/outline'
import {
  UIButton,
  UIGrid,
  UIGridSkeleton,
  UIRemoveDialog,
  UIServiceIcon,
  UIServicesDropdown,
  UITagsList,
} from '@ui'

type Props = {
  instance: AssetClass
}

const props = defineProps<Props>()

defineOptions({ inheritAttrs: false })

const documentsBunchStore = useDocumentsBunchStore()
const documentsStore = useDocumentsStore()
const modalsStore = useModalsStore()

const { error } = useNotifications()

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const removeDialog = ref(false)
const loading = ref(false)

const sort = ref<Sort[]>()

const removedDocument = ref<Document>()

const docsField = props.instance.field<string[]>(ASSET_FIELD.DOCS)

const isLoading = computed(
  () =>
    loading.value || !documentsStore.initFlag || documentsStore.loadingAction,
)

const isButtonDisabled = computed(() => isLoading.value)

const assetId = computed(() => props.instance.id)

const removeDialogTitle = computed(
  () => `Unlink asset document '${removedDocument.value?.name}'`,
)

const collapseWidth = 280
const sm = '1fr'
const columns = computed(() => [
  {
    name: 'name',
    caption: 'Document',
  },
  {
    name: 'tags',
    caption: '',
    unsortable: true,
    unresizeable: true,
    tooltip: null,
    formatter: (value?: string[]) =>
      value?.map(id => ({ id, name: getDocumentTagName(id) })),
  },
])

const inputItems = computed(
  () =>
    docsField.value
      .map(id => {
        const instance = documentsBunchStore.getElementById(id)
        return instance?.get()
      })
      .filter(item => item !== undefined) as Document[],
)
const items = useGridSort(sort, columns, inputItems)

const handleClickEdit = (value: Document) => {
  const instance = documentsBunchStore.getElementById(value.id)
  if (!instance) return
  instance.isDialog = true
  const removeDialogFlag = () => {
    instance.isDialog = false
  }
  instance?.addEventListener(EntityEvent.STORED, removeDialogFlag)
  const modalInstance = modalsStore.init(instance.id, instance)
  modalInstance?.open(modalsStore.getZIndex())
  modalInstance?.addEventListener(ModalEvent.CLOSE, removeDialogFlag)
}

const handleClickUnlink = (item: Document) => {
  removedDocument.value = item
  removeDialog.value = true
}

const handleUnlink = async () => {
  const id = removedDocument.value?.id
  removedDocument.value = undefined
  if (!id) return
  loading.value = true
  try {
    const document = documentsBunchStore.getElementById(id)
    document?.set({
      asset_id: null,
    })
    document?.update()
    docsField.value = docsField.value.filter(item => item !== id)
  } catch (e) {
    await error({
      message: 'There was a problem with the document',
    })
    handleCatchedError(e as string)
  } finally {
    loading.value = false
  }
}

const handleClickExternalLink = (value: Document) => {
  const a = document.createElement('a')
  a.href = value.path || ''
  a.target = '_blank'
  document.body.append(a)
  a.click()
  a.remove()
}

const handleCreate = (data: { name: string; path: string }) => {
  const instance = documentsBunchStore.createElement()
  instance.set({
    ...data,
    asset_id: assetId.value,
  })
  instance.isDialog = true
  instance.addEventListener(EntityEvent.STORED, () => {
    instance.isDialog = false
  })
  const modalInstance = modalsStore.init(instance.id, instance)
  modalInstance?.open(modalsStore.getZIndex(), { size: 'sm' })
  modalInstance?.addEventListener(ModalEvent.CLOSE, () => {
    instance.remove()
  })
}

const { service } = useDocumentService(handleCreate)
</script>

<style>
.asset-drawer-documents {
  &__link {
    @apply flex items-center gap-1;
  }

  &__link-icon {
    @apply size-6;
    @apply flex items-center justify-center;
    @apply bg-body-gray;
    @apply rounded-full;

    svg {
      @apply size-3;
    }
  }
}
</style>
