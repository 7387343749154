<template>
  <UIPreloader
    v-if="isRepositoriesInit"
    class="main-loader"
    message="Allposit loading your repositories..."
  />
  <component :is="mainComponent" v-else />
  <AppInvites />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useResponsive } from '@/plugins/responsiveUI'

import { useRepositoriesStore } from '@/store/repositories'

import { AppInvites } from '@app'
import { UIPreloader } from '@ui'

import MainDesktop from './MainDesktop.vue'
import MainMobile from './MainMobile.vue'

defineOptions({ name: 'MainLayout' })

const repositoriesStore = useRepositoriesStore()

const { isMobile, isTablet } = useResponsive()

const mainComponent = computed(() =>
  isMobile.value || isTablet.value ? MainMobile : MainDesktop,
)

const isRepositoriesInit = computed(() => !repositoriesStore.initFlag)
</script>

<style>
.main-loader {
  @apply fixed inset-0;
  @apply bg-white dark:bg-gray-900;
  @apply bg-opacity-75;
  @apply z-30;
}
</style>
