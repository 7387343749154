<template>
  <div class="asset-drawer-values-chart">
    <UISkeletonChartCombo
      v-if="isLoading"
      message="Loading values..."
      class="asset-drawer-values-chart__chart"
    />
    <UIComboChart
      v-else-if="series?.length"
      v-bind="{ data, series, valueFormatter }"
      class="asset-drawer-values-chart__chart"
    />
    <UILayoutNoData v-else hide-question-icon inline />
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed, inject, ref, watch } from 'vue'
import { orderBy } from 'lodash'

import { AnalyticsPosition } from '@types'

import { stringToLocalDateString } from '@/helpers/dates'
import { numberFormat } from '@/helpers/numbers'

import useAnalyticsStore from '@/store/analytics'

import { UIComboChart, UILayoutNoData, UISkeletonChartCombo } from '@ui'
import { useDark } from '@vueuse/core'

type Props = {
  assets?: string[]
  holders?: string[]
}

const props = defineProps<Props>()

const analyticsStore = useAnalyticsStore()

const isDark = useDark()

const currency = inject<ComputedRef<string>>('currency')

const valueFormatter = computed(
  () => (data: number, fractionDigits?: number) =>
    numberFormat(data, { currency: currency?.value, fractionDigits }),
)

const isLoading = ref(true)
const positions = ref<AnalyticsPosition[]>([])

const data = computed(() => {
  const mappedData: Record<string, [string, number, number]> = {}
  const orderedPositions = orderBy(positions.value, 'date', 'asc')

  for (let i = 0; i < orderedPositions.length; i++) {
    const key = orderedPositions[i].date
    const date = stringToLocalDateString(orderedPositions[i].date) as string
    const value = orderedPositions[i].position_close
    const cost = value - orderedPositions[i].position_gain
    if (!mappedData[key]) {
      mappedData[key] = [date, cost, value]
      continue
    }
    mappedData[key][1] += cost
    mappedData[key][2] += value
  }
  return Object.values(mappedData)
})

const series = computed(() => {
  return [
    {
      name: 'Cost',
      type: 'bar',
      itemStyle: {
        color: isDark.value ? '#F24242' : '#EF4444',
      },
    },
    {
      name: 'Value',
      type: 'bar',
      itemStyle: {
        color: isDark.value ? '#416DE6' : '#1C4ED8',
      },
    },
  ]
})

const fetchPositions = async () => {
  const params: Record<string, string> = { data: 'position' }
  if (props.assets?.length) {
    params.assets = JSON.stringify(props.assets)
  }
  if (props.holders?.length) {
    params.accounts = JSON.stringify(props.holders)
  }
  const result = await analyticsStore.fetch(params)
  positions.value = result.position_view
  isLoading.value = false
}

watch(() => props.holders, fetchPositions, { immediate: true })
</script>

<style>
.asset-drawer-values-chart {
  @apply h-1/2;
  @apply relative;
  @apply mx-2;

  &__chart {
    @apply absolute inset-0;
  }
}
</style>
