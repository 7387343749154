<template>
  <UIModal v-bind="{ isOpen }" :type="ModalType.DIALOG" @hide="handleHide">
    <div class="account-akoya__content">
      <div class="account-akoya__logo">
        <img :src="logoSrc" />
      </div>
      <div class="account-akoya__text">
        You are connecting to {{ institutionName }} account using Akoya
      </div>
    </div>
    <UICheckbox
      v-model="agreement"
      label="I have read and agreed to the following terms of the account information
        sharing. Account information is retrieved via Akoya Data Access Network.
        The information includes account balances and positions. The information
        is retrieve only. The information will not be shared, rented, exchanged
        or sold without your consent."
      class="account-akoya__agreement"
    />
    <template #buttons>
      <div class="account-akoya__buttons">
        <UIButton
          label="Cancel"
          variant="gray"
          fill="outlined"
          @click="handleCancel"
        />
        <UIButton v-bind="{ disabled }" label="Next" @click="handleClickNext" />
      </div>
    </template>
  </UIModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { ModalType } from '@types'
import { INSTITUTION_LOGO_PLACEHOLDER } from '../../utils/const'

import { UIButton, UICheckbox, UIModal } from '@ui'

type Emits = {
  cancel: []
}

const emit = defineEmits<Emits>()

defineExpose({
  show(name: string, url: string, logo?: string | null) {
    institutionName.value = name
    institutionUrl.value = url
    institutionLogo.value = logo
    isOpen.value = true
  },
})

const isOpen = ref(false)

const institutionName = ref<string>()
const institutionUrl = ref<string>()
const institutionLogo = ref<string | null>()
const agreement = ref(false)

const disabled = computed(() => !agreement.value || !institutionUrl.value)

const logoSrc = computed<string>(
  () => institutionLogo.value || INSTITUTION_LOGO_PLACEHOLDER,
)

const handleHide = () => {
  isOpen.value = false
  agreement.value = false
}

const handleCancel = () => {
  handleHide()
  emit('cancel')
}

const handleClickNext = () => {
  if (!institutionUrl.value) return
  location.href = institutionUrl.value
}

defineOptions({
  name: 'ConnectorsAddAccountAkoya',
})
</script>

<style lang="postcss" scoped>
.account-akoya {
  &__content {
    @apply flex flex-col items-center;
    @apply mb-8;
  }

  &__text {
    @apply text-stone-950 dark:text-gray-300;
    @apply text-center;
  }

  &__logo {
    @apply h-24;
    @apply mb-4;
    @apply bg-white;

    img {
      @apply w-full h-full;
      @apply object-contain;
    }
  }

  &__agreement {
    @apply mb-1;

    .ui-checkbox__label {
      @apply font-medium text-xs;
    }
  }

  &__buttons {
    @apply flex justify-end;
    @apply gap-x-2;
  }
}
</style>
