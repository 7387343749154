<template>
  <div class="timeline-freq">
    <UIInputDropdown
      ref="inputRef"
      v-model="modelValue"
      v-bind="{ ...props, data }"
      id-key="key"
      simple
      disable-recovery-value
      class="timeline-freq__field"
      @blur="handleUpdate"
      @select="handleUpdate"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'

import { DataFieldSizes, RepositoryTimeline } from '@types'

import { FREQUENCY_VALUES } from './utils/const'

import { UIInputDropdown } from '@ui'

type Props = {
  disabled?: boolean
  size?: DataFieldSizes
}

type Emits = {
  update: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<RepositoryTimeline['freq']>()

defineOptions({
  name: 'TimelineFreq',
})

defineExpose({
  focus() {
    inputRef.value?.focus().toggle(true)
  },
})

const inputRef = useTemplateRef('inputRef')

const data = computed(() => FREQUENCY_VALUES)

const handleUpdate = () => {
  emit('update')
}
</script>

<style>
.timeline-freq {
  @apply flex-auto w-32;

  .ui-timeline--short & {
    @apply w-[6.75rem];
  }

  &__field {
    @apply text-gray-500;
  }
}
</style>
