<template>
  <div class="ui-filter__group group">
    <div
      v-for="(param, index) in params"
      :key="param.key"
      class="ui-filter__group__container"
    >
      <Logic v-model="logic" v-bind="{ index, paramsLength }" />
      <div class="ui-filter__group__param">
        <GroupsItemParam
          ref="paramRef"
          v-bind="{ fields, param, groupIndex, index }"
          @remove:param="handleRemoveParam"
          @update:param="handleUpdateParam"
        />
      </div>
    </div>
    <div v-if="areButtonsVisible" class="ui-filter__group__buttons">
      <UIButton
        v-bind="{ size }"
        :label="t('Add nested filter')"
        :leading="PlusIcon"
        variant="secondary"
        fill="light"
        size="sm"
        @click="handleAdd"
      />
      <UIButton
        v-if="isClearButtonVisible"
        v-bind="{ size }"
        :label="t('Clear all')"
        variant="gray"
        fill="light"
        size="sm"
        class="ui-filter__group__clear"
        @click="handleClear"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, nextTick, useTemplateRef } from 'vue'

import { ButtonSize, I18nTranslate } from '@types'
import { FilterField, FilterGroup, FilterParam } from '../utils/types'

import { generateRandomKey } from './utils/helpers'

import { UIButton } from '@ui'
import { PlusIcon } from '@heroicons/vue/24/outline'
import Logic from './Logic.vue'
import GroupsItemParam from './GroupsItemParam.vue'

type Props = {
  fields?: FilterField[]
  flat?: boolean
  group: FilterGroup
  groupIndex: number
}

type Emits = {
  'remove:group': [key: string]
  'update:group': [key: string, data: Partial<FilterGroup>]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineExpose({
  start,
})

const size = inject<ButtonSize>('size')
const t = inject<I18nTranslate>('t', (data: string) => data)

const paramRef = useTemplateRef<(typeof GroupsItemParam)[]>('paramRef')

const logic = computed({
  get() {
    return props.group.logic
  },
  set(logic) {
    updateGroup({ logic })
  },
})

const params = computed(() => props.group.params)
const paramsLength = computed(() => params.value.length)

const areButtonsVisible = computed(() => !props.flat)
const isClearButtonVisible = computed(() => paramsLength.value > 1)

const updateGroup = (data: Partial<FilterGroup>) => {
  if (!props.group.key) return
  emit('update:group', props.group.key, data)
}

const handleAdd = async () => {
  const key = generateRandomKey()
  const params = [...props.group.params, { key }]
  updateGroup({ params })
  await nextTick()
  start()
}

const handleClear = () => {
  const key = generateRandomKey()
  updateGroup({ params: [{ key }] })
}

const handleRemoveParam = (key: string) => {
  if (paramsLength.value === 1) {
    if (!props.group.key) return
    emit('remove:group', props.group.key)
    return
  }
  const paramsData = params.value.filter(param => param.key !== key)
  updateGroup({ params: paramsData })
}

const handleUpdateParam = (key: string, data: Partial<FilterParam>) => {
  const paramsData = params.value.map(param =>
    param.key === key ? { ...param, ...data } : param,
  )
  updateGroup({ params: paramsData })
}

function start() {
  paramRef.value?.[paramsLength.value - 1]?.start()
}
</script>

<style scoped>
.ui-filter__group {
  @apply max-w-[38rem];
  @apply flex flex-col;
  @apply gap-2;
  @apply p-2;
  @apply bg-gray-50 dark:bg-gray-700;
  @apply border border-gray-200 dark:border-gray-700;
  @apply rounded-md;

  &__container {
    @apply flex items-center;
    @apply gap-2;
    @apply bg-gray-100 dark:bg-gray-700;
    @apply md:bg-transparent;
    @apply p-2 md:p-0;
    @apply rounded-md;
  }

  &__param {
    @apply flex-auto;
    @apply grid grid-cols-[1fr_min-content] md:grid-cols-[0.8fr_0.8fr_1.4fr_min-content];
    @apply gap-0 md:gap-2;
  }

  &__buttons {
    @apply flex items-center justify-between;
    @apply gap-2;
    @apply mt-1;
  }

  &__clear {
    @apply opacity-0 group-hover:opacity-100;
    @apply transition-opacity;
  }
}
</style>
