<template>
  <Values
    ref="valuesRef"
    v-model="items"
    :placeholder="`${placeholder} in ${currency}`"
  />
</template>

<script setup lang="ts">
import { ComputedRef, inject, useTemplateRef } from 'vue'
import { AssetPriceForm } from '@types'

import Values from '../components/Values.vue'

const items = defineModel<AssetPriceForm[]>('prices', { required: true })

defineOptions({ inheritAttrs: false })

defineExpose({
  nextStepDisallowed: async () => {
    return await valuesRef.value?.checkEmptyValue?.()
  },
})

const valuesRef = useTemplateRef('valuesRef')

const currency = inject<ComputedRef<string>>('currency')
const placeholder = inject<string>('valuePlaceholder', 'Investment value')
</script>
