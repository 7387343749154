<template>
  <div class="ui-json-tree">
    <Tree
      v-bind="{ data, size }"
      :meta-fields="[VALUE_FIELD]"
      root="root"
      placeholder="Search value..."
      collapsed
      search-in-meta
    >
      <template #root><slot /></template>
      <template #meta="slotProps">
        <span v-if="slotProps.isRoot" class="ui-json-tree__copy">
          <span class="ui-json-tree__copy-text" :class="copiedTextClasses"
            >Copied</span
          >
          <component
            :is="copyIcon"
            v-tooltip.top="copyTooltip"
            class="ui-json-tree__copy-icon"
            aria-hidden="true"
            @click="handleCopy"
          />
        </span>
        <TreeJsonValue
          v-else
          :value="slotProps[VALUE_FIELD]"
          :search="slotProps.search"
        />
      </template>
    </Tree>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { InputSize } from '../Input/utils/types'

import Tree from './Tree.vue'
import TreeJsonValue from './components/TreeJsonValue.vue'
import {
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
} from '@heroicons/vue/24/outline'

const VALUE_FIELD = '#__value'

type Props = {
  json: any
  size?: InputSize
}

const props = defineProps<Props>()

defineOptions({
  name: 'UITreeJson',
})

const isCopied = ref(false)
const copyTimeout = ref<NodeJS.Timeout>()

const data = computed(() => {
  if (!props.json) return
  const root = convertJsonToTree(props.json)
  return { root }
})

const copyIcon = computed(() =>
  isCopied.value ? ClipboardDocumentCheckIcon : ClipboardDocumentIcon,
)

const copyTooltip = computed(() =>
  isCopied.value ? undefined : 'Copy to clipboard',
)

const copiedTextClasses = computed(() => ({
  'ui-json-tree__copy-text--visible': isCopied.value,
}))

const handleCopy = () => {
  const data = JSON.stringify(props.json)
  const blob = new Blob([data], { type: 'text/plain' })
  navigator.clipboard.write([
    new ClipboardItem({
      [blob.type]: blob,
    }),
  ])
  isCopied.value = true
  copyTimeout.value && clearTimeout(copyTimeout.value)
  copyTimeout.value = setTimeout(() => {
    isCopied.value = false
  }, 2000)
}

const convertJsonToTree = (data: any) => {
  const result: any = {}
  if (Array.isArray(data)) {
    result[VALUE_FIELD] = `[${data.length}]`
    data.forEach((item, index) => {
      const nodes = convertJsonToTree(item)
      result[index] = {
        [VALUE_FIELD]: '...',
        ...nodes,
      }
    })
  } else if (typeof data === 'object' && data !== null) {
    Object.keys(data).forEach(key => {
      const nodes = convertJsonToTree(data[key])
      result[key] = {
        [VALUE_FIELD]: '...',
        ...nodes,
      }
    })
  } else if (data === '') {
    result[VALUE_FIELD] = `''`
  } else {
    result[VALUE_FIELD] = `${data}`
  }
  return result
}
</script>

<style>
.ui-json-tree {
  @apply flex flex-auto;

  .slide-panel-body__content & {
    @apply h-full;
  }

  &__copy {
    @apply flex;
    @apply gap-1 ml-auto;

    &-icon {
      @apply w-4 h-4;
      @apply cursor-pointer;
      @apply text-gray-500 hover:text-indigo-500;
    }

    &-text {
      @apply text-xs;
      @apply text-gray-500;
      @apply opacity-0;
      @apply transition-opacity duration-200;

      &--visible {
        @apply opacity-100;
      }
    }
  }

  .ui-tree__item-area--root .ui-tree__value {
    @apply flex flex-auto items-center justify-end;
    @apply overflow-visible;
  }
}
</style>
