<template>
  <div class="app-breadcrumb">
    <UIDropdown
      :model-value="currentModalValue"
      :items="listItems"
      id-key="id"
      value-key="value"
      placement="bottom-start"
      popper-class="app-breadcrumb__dropdown"
      @click:item="handleClickItem"
    >
      <UIButtonIcon
        v-tooltip.top="'Filter analytics'"
        :leading="FunnelIcon"
        variant="secondary"
        size="xxs"
      />
    </UIDropdown>
    <div class="app-breadcrumb__current">{{ currentValue }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { orderBy, sortedUniqBy } from 'lodash'

import { BreadcrumbItem } from './utils/types'

import { ANALYTICS_REPO_ROOT, ASSET_FIELD, UNKNOWN_VALUE } from '@/const'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useRepositoriesStore } from '@/store/repositories'

import { UIButtonIcon, UIDropdown } from '@ui'
import { FunnelIcon } from '@heroicons/vue/24/outline'

type Props = {
  items: string[][]
  path: string[]
}

type Emits = {
  'click:item': [data: string[]]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({
  name: 'AppBreadcrumb',
})

const assetsBunchStore = useAssetsBunchStore()
const repositoriesStore = useRepositoriesStore()

const inputItems = computed<BreadcrumbItem[]>(
  () =>
    props.items?.map(item => ({
      id: JSON.stringify(item),
      value: getAssetName(item.at(-1)),
    })) || [],
)

const listItems = computed(() =>
  sortedUniqBy(
    orderBy(
      inputItems.value,
      item =>
        item.value === repositoriesStore.getCurrentRepositoryName || item.value,
    ),
    item => item.value.at(-1),
  ),
)

const currentValue = computed(() => getAssetName(props.path.at(-1)))

const currentModalValue = computed(() => {
  const currentNode = props.path.at(-1)
  if (!currentNode) return
  return inputItems.value.find(
    item => JSON.parse(item.id).at(-1) === currentNode,
  )?.id
})

const getAssetName = (id?: string) => {
  if (!id) return UNKNOWN_VALUE
  if (id === ANALYTICS_REPO_ROOT)
    return repositoriesStore.getCurrentRepositoryName || UNKNOWN_VALUE
  const asset = assetsBunchStore.getElementById(id)
  return asset?.field<string>(ASSET_FIELD.NAME).value || UNKNOWN_VALUE
}

const handleClickItem = ({ id }: BreadcrumbItem) => {
  if (id === JSON.stringify(props.path)) return
  emit('click:item', JSON.parse(id))
}
</script>

<style>
.app-breadcrumb {
  @apply flex flex-auto items-center;
  @apply gap-2;

  &__dropdown .v-popper__arrow-container {
    @apply block;
  }

  &__current {
    @apply flex-auto;
    @apply text-sm;
    @apply truncate;
  }
}
</style>
