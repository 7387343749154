<template>
  <div class="asset-drawer-security-master">
    <div
      v-if="isSecurityInfoAssigned"
      class="asset-drawer-security-master__info"
    >
      <span>
        Symbol:
        <span class="font-medium">{{ assetSecurityInfoTicker }}</span>
      </span>
      <UIButton
        fill="light"
        size="xs"
        label="Reset"
        @click="handleClickReset"
      />
    </div>
    <form v-else class="asset-drawer-security-master__form" @submit="onSubmit">
      <EODSearch
        ref="eodInputRef"
        v-model="securityValue"
        v-bind="{ readonly }"
        :disabled="isLoading"
        label="Symbol"
        placeholder="Security master ticker"
        float
        short
        class="asset-drawer-security-master__input"
      />
      <UIButton
        v-if="isSaveButtonShown"
        :disabled="isLoading"
        :loading="isLoading"
        data-refid="assetSecurityMasterFormSave"
        label="Save"
      />
      <UIButton
        v-if="isCancelButtonShown"
        variant="gray"
        fill="outlined"
        data-refid="assetSecurityMasterFormCancel"
        label="Cancel"
        @click="onCancel"
      />
    </form>
    <div
      v-if="isSecurityInfoAssigned"
      class="asset-drawer-security-master__json"
    >
      <UIJsonTree :json size="small">JSON data</UIJsonTree>
    </div>
    <UIModal
      :type="ModalType.ACTION_DIALOG"
      :is-open="saveDialogFlag"
      title="Warning of changes"
      size="md"
      :actions="saveDialogActions"
      @hide="saveDialogFlag = false"
    >
      <UICheckbox
        v-if="assetType !== formValues.asset_type"
        v-model="change.type"
        ><span class="font-normal text-gray-400">Change type from</span>
        {{ assetType }}
        <span class="font-normal text-gray-400">to</span>
        {{ formValues.asset_type }}</UICheckbox
      >
      <UICheckbox
        v-if="assetName !== formValues.asset_name"
        v-model="change.name"
        ><span class="font-normal text-gray-400">Change name from</span>
        {{ assetName }}
        <span class="font-normal text-gray-400">to</span>
        {{ formValues.asset_name }}</UICheckbox
      >
      <UICheckbox
        v-if="assetTicker !== formValues.asset_ticker"
        v-model="change.ticker"
        ><span class="font-normal text-gray-400">Change ticker from</span>
        {{ assetTicker }}
        <span class="font-normal text-gray-400">to</span>
        {{ formValues.asset_ticker }}</UICheckbox
      >
    </UIModal>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  inject,
  nextTick,
  reactive,
  ref,
  useTemplateRef,
  watch,
} from 'vue'
import { useField, useForm } from 'vee-validate'

import { AssetClass } from '..'
import { AssetSecurityInfo } from '@/entities/assets/utils/types'
import {
  AssetSecurityMaster,
  DialogActionButton,
  ModalType,
  ReadonlyMode,
} from '@types'

import { ASSET_FIELD } from '../utils/const'
import { READONLY_MODE } from '@/const/common'

import { rules } from '@/helpers/validate'

import { useAssetsStore } from '@/store/assets'

import EODSearch from '@/modules/EODSearch/EODSearch.vue'

import { UIButton, UICheckbox, UIJsonTree, UIModal } from '@ui'

type Props = {
  instance: AssetClass
}

const props = defineProps<Props>()

const assetsStore = useAssetsStore()

const eodInputRef = useTemplateRef('eodInputRef')

const securityValue = ref<AssetSecurityMaster>()

const saveDialogFlag = ref(false)

const change = reactive({
  type: true,
  name: true,
  ticker: true,
})

const readonly = inject<ReadonlyMode>(READONLY_MODE)

const isLoading = computed(() => assetsStore.loadingAction)

const assetType = props.instance.field<string>(ASSET_FIELD.TYPE)
const assetName = props.instance.field<string>(ASSET_FIELD.NAME)
const assetTicker = props.instance.field<string>(ASSET_FIELD.TICKER)

const assetSecurityInfo = props.instance.field<AssetSecurityInfo>(
  ASSET_FIELD.SECURITY_INFO,
)
const assetSecurityInfoTicker = props.instance.field<string>(
  ASSET_FIELD.SECURITY_INFO_TICKER,
)

const assetSecurityInfoData = computed(
  () => assetSecurityInfo.value?.data || {},
)

const isSecurityInfoAssigned = computed(
  () => assetSecurityInfoTicker.value && assetSecurityInfoData.value,
)

const isSaveButtonShown = computed(
  () => meta.value.dirty && meta.value.valid && !readonly?.value,
)

const isCancelButtonShown = computed(
  () => isSaveButtonShown.value && !isLoading.value,
)

const json = computed(() =>
  Object.fromEntries(
    Object.entries(assetSecurityInfoData.value).filter(
      ([, value]) => typeof value !== 'object',
    ),
  ),
)

const {
  handleSubmit,
  meta,
  resetForm,
  values: formValues,
} = useForm<{
  security_info_ticker: string
  asset_type: string
  asset_name: string
  asset_ticker: string
}>({
  initialValues: {
    security_info_ticker: assetSecurityInfo.value?.symbol || '',
    asset_type: assetType.value,
    asset_name: assetName.value,
    asset_ticker: assetTicker.value,
  },
  validationSchema: {
    security_info_ticker: rules.required,
    asset_type: () => true,
    asset_name: () => true,
    asset_ticker: () => true,
  },
})

const { value: securityInfoTicker } = useField<string>(
  ASSET_FIELD.SECURITY_INFO_TICKER,
)
const { value: assetTypeValue } = useField<string>('asset_type')
const { value: assetNameValue } = useField<string>('asset_name')
const { value: assetTickerValue } = useField<string>('asset_ticker')

const onSubmit = (e: Event) => {
  e.preventDefault()
  if (
    (assetType.value === assetTypeValue.value &&
      assetName.value === assetNameValue.value &&
      assetTicker.value === assetTickerValue.value) ||
    !securityInfoTicker.value
  ) {
    handleSave(false)()
  } else {
    saveDialogFlag.value = true
  }
}

const handleSave = (update = true) =>
  handleSubmit(values => {
    saveDialogFlag.value = false
    assetSecurityInfoTicker.value = values.security_info_ticker
    if (change.type && update) {
      assetType.value = values.asset_type
    }
    if (change.name && update) {
      assetName.value = values.asset_name
    }
    if (change.ticker && update) {
      assetTicker.value = values.asset_ticker
    }
    props.instance.update()
    resetForm()
  })

const onCancel = async () => {
  if (assetSecurityInfo.value) {
    securityValue.value = {
      code: assetSecurityInfo.value.ticker,
      name: assetSecurityInfo.value.symbol,
      type: assetSecurityInfo.value.type,
      currency: '',
      exchange: '',
    }
  }
  if (readonly?.value) return
  eodInputRef.value?.cancel(
    assetSecurityInfo.value?.symbol
      ? assetSecurityInfo.value.symbol
      : undefined,
  )
  await nextTick()
  resetForm()
}

const handleClickReset = async () => {
  props.instance.field<AssetSecurityInfo>(ASSET_FIELD.SECURITY_INFO).value =
    null
  props.instance.field<string>(ASSET_FIELD.SECURITY_INFO_TICKER).value = ''
  props.instance.update()
  resetForm()
  await nextTick()
  eodInputRef.value?.focus()
}

const saveDialogActions = computed<DialogActionButton[]>(() => [
  {
    label: 'Save',
    onClick: handleSave(),
    buttonVariant: 'primary',
  },
])

watch(securityValue, value => {
  if (!value) return
  securityInfoTicker.value = `${value.code}.${value.exchange}`
  assetTypeValue.value = value.type
  assetNameValue.value = value.name
  assetTickerValue.value = value.code
})

watch(eodInputRef, onCancel)
</script>

<style>
.asset-drawer-security-master {
  @apply flex flex-col flex-auto;

  &__info {
    @apply flex items-center justify-between;
    @apply gap-2 py-4 px-default;
    @apply text-sm;
  }

  &__form {
    @apply flex flex-col sm:flex-row sm:items-end;
    @apply gap-2 py-4 px-default;
  }

  &__input {
    @apply flex-auto;
  }

  &__json {
    @apply flex flex-col flex-auto;
    @apply px-default;

    .ui-tree__search {
      @apply mb-4;
    }

    .ui-tree__container {
      @apply pb-4;
    }
  }
}
</style>
