<template>
  <div class="main-desktop-header">
    <div class="main-desktop-header__aside">
      <span class="main-desktop-header__toggler">
        <UIButtonPanel
          v-model="isNavbarOpened"
          v-tooltip.right="tooltip"
          variant="secondary"
        />
      </span>
      <RouterLink
        :to="{ name: ROUTE_NAME.DASHBOARDS }"
        class="main-desktop-header__logo"
      >
        <AppLogo />
        <span class="main-desktop-header__logo-text">Allposit</span>
      </RouterLink>
    </div>
    <div v-if="repositoryName" class="main-desktop-header__repository">
      <ChevronDoubleRightIcon
        v-if="!isNavbarOpened"
        class="main-desktop-header__repository-chevron"
      />
      <span class="main-desktop-header__repository-text">{{
        repositoryName
      }}</span>
    </div>
    <div class="main-desktop-header__actions">
      <MainDesktopHeaderNotifications />
      <MainDesktopHeaderSearch v-if="!disabled" />
      <MainDesktopHeaderUser />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, watch } from 'vue'

import { I18nTranslate, OpenedFlag } from '@types'

import {
  NAVBAR_OPENED,
  REPOSITORIES_OPENED,
  ROUTE_NAME,
  TREE_OPENED,
} from '@/const'

import { useRepositoriesStore } from '@/store/repositories'

import { AppLogo } from '@app'

import MainDesktopHeaderNotifications from './MainDesktopHeaderNotifications.vue'
import MainDesktopHeaderSearch from './MainDesktopHeaderSearch.vue'
import MainDesktopHeaderUser from './MainDesktopHeaderUser.vue'
import { UIButtonPanel } from '@ui'
import { ChevronDoubleRightIcon } from '@heroicons/vue/24/outline'

type Props = {
  disabled?: boolean
}

defineProps<Props>()

const repositoriesStore = useRepositoriesStore()

const t = inject<I18nTranslate>('t', (data: string) => data)

const isNavbarOpened = inject<OpenedFlag>(NAVBAR_OPENED)
const isTreeOpened = inject<OpenedFlag>(TREE_OPENED)
const isRepositoriesOpened = inject<OpenedFlag>(REPOSITORIES_OPENED)

const currentRepository = computed(() => repositoriesStore.getCurrentRepository)
const repositoryName = computed(() => currentRepository.value?.name)

const tooltip = computed(() =>
  isNavbarOpened?.value ? t('Close navbar') : t('Open navbar'),
)

watch(
  () => isNavbarOpened?.value,
  value => {
    if (!value) return
    isTreeOpened!.value = false
    isRepositoriesOpened!.value = false
  },
)
</script>

<style scoped>
.main-desktop-header {
  @apply w-full h-10;
  @apply flex shrink-0 justify-between;
  @apply bg-primary;
  @apply z-20;

  &__aside {
    @apply flex shrink-0;
    @apply gap-x-7;
    @apply transition-all;

    .main-desktop--opened-navbar & {
      @apply w-40;
      @apply bg-primary-dark;
      @apply gap-x-0;
    }

    .main-desktop--opened-container & {
      @apply gap-x-4;
    }
  }

  &__toggler {
    @apply w-[4.2rem] h-full;
    @apply flex items-center justify-center;
    @apply bg-primary-dark;

    .main-desktop--opened-navbar & {
      @apply w-auto;
      @apply pl-2;
    }

    :deep() .ui-button {
      @apply text-primary-selected hover:text-primary-light;
      @apply dark:text-indigo-400 dark:hover:text-indigo-300;
      @apply hover:!bg-transparent;
      @apply !ring-0 !ring-offset-0;
    }
  }

  &__logo {
    @apply flex items-center;
    @apply gap-1;
  }

  &__logo-text {
    @apply text-primary-selected;
    @apply text-lg font-medium;
  }

  :deep() .logo__text {
    @apply text-white;
  }

  &__repository {
    @apply h-full;
    @apply flex items-center;
    @apply text-white;
    @apply text-sm font-medium;
    @apply overflow-hidden;

    .main-desktop--opened-navbar & {
      @apply pl-default;
    }
  }

  &__repository-chevron {
    @apply shrink-0;
    @apply size-3;
    @apply mx-2;
  }

  &__repository-text {
    @apply truncate;
  }

  &__actions {
    @apply flex flex-auto items-center justify-end;
    @apply pr-6;
    @apply gap-x-2;
  }
}
</style>

<style>
.main-desktop-header-button {
  @apply w-8;
  @apply aspect-square;
  @apply flex items-center justify-center;
  @apply rounded-full;
  @apply hover:bg-primary-active;
  @apply text-indigo-200;
  @apply cursor-pointer;
}

.main-desktop-header-icon {
  @apply w-5 aspect-square;
}
</style>
