<template>
  <MenuItem
    v-bind="{ item }"
    :show-triggers="['hover']"
    :hide-triggers="['hover']"
    :popper-show-triggers="['hover']"
    :popper-hide-triggers="['hover']"
  >
    <RouterLink
      :to="{ name: item.route, params: item.routeParams }"
      :data-refid="dataRefId"
    >
      <component
        :is="isNavbarOpened ? UIButton : UIButtonIcon"
        :label="item.label"
        :leading="item.icon"
        :variant="item.active ? 'secondary' : 'gray'"
        :fill="item.active ? undefined : 'outlined'"
        size="sm"
        class="navbar-item"
      />
    </RouterLink>
    <template v-if="!wide" #before>
      <div class="navbar-item__caption">{{ item.label }}</div>
    </template>
  </MenuItem>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'
import { upperFirst } from 'lodash'

import { NavigationItem, OpenedFlag } from '@types'

import { NAVBAR_OPENED } from '@/const'

import MenuItem from './MenuItem.vue'
import { UIButton, UIButtonIcon } from '@ui'

type Props = {
  item: NavigationItem
  wide?: boolean
}

const props = defineProps<Props>()

const isNavbarOpened = inject<OpenedFlag>(NAVBAR_OPENED)

const dataRefId = computed(
  () =>
    `panelMenuItem${props.item.label
      ?.split(' ')
      .map(item => upperFirst(item))
      .join('')}`,
)
</script>

<style>
.navbar-item {
  @apply relative;

  .ui-button-icon__label {
    @apply max-w-none;
  }

  &.ui-button {
    @apply w-full;
    @apply justify-start;
  }

  &__caption {
    @apply py-2 px-4;
    @apply text-xs font-medium uppercase;
    @apply text-gray-400;
  }

  &--active {
    @apply ml-0.5 -mr-[1px] !pr-[calc(0.25rem+1px)];
    @apply bg-body-dark;
    @apply !border-common-border;

    .ui-button {
      @apply bg-transparent hover:bg-body-gray;
      @apply border-common-border;
    }
  }

  &__status {
    @apply size-2.5;
    @apply absolute;
    @apply top-[0.25rem] right-[1.05rem];
  }

  &--active &__status {
    @apply mr-[1px];
  }
}

.navbar-item.ui-button--gray-outlined .ui-button__icon:last-child,
.navbar-item__status {
  @apply text-indigo-400;
}

.main-desktop:not(.main-desktop--opened-navbar) .navbar-item {
  @apply py-1 px-0.5;
  @apply rounded-l-md;
  @apply border border-r-0 border-transparent;
}
</style>
