<template>
  <div class="ui-values-guide" :class="mainClasses">
    <UIButtonIcon
      :disabled="isLeftButtonDisabled"
      :leading="ArrowLeftIcon"
      :variant
      :size
      :fill
      @click="handleClickLeft"
    />
    <UIPreloader v-if="loading" />
    <div v-else class="ui-values-guide__value">{{ displayValue }}</div>
    <UIButtonIcon
      :disabled="isRightButtonDisabled"
      :leading="ArrowRightIcon"
      :variant
      :size
      :fill
      @click="handleClickRight"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { ButtonFill, ButtonSize, ButtonVariant } from '@types'

import { UIButtonIcon, UIPreloader } from '@ui'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/20/solid'

type Props = {
  values: string[]
  disabled?: boolean
  loading?: boolean
  variant?: ButtonVariant
  size?: ButtonSize
  fill?: ButtonFill
  formatter?: (value: string) => string
  getterFn?: (item: string, value: string) => boolean
}

const props = defineProps<Props>()

const modelValue = defineModel<string>()

defineOptions({
  name: 'UIValuesGuide',
})

const selectedIndex = computed({
  get() {
    return getIndex(modelValue.value) + 1
  },
  set(value) {
    updateAnalyticsDate(value - 1)
  },
})

const displayValue = computed(() => {
  return (
    modelValue.value &&
    (props.formatter?.(modelValue.value) || modelValue.value)
  )
})

const mainClasses = computed(() => ({
  'ui-values-guide--disabled': props.disabled,
}))

const isLeftButtonDisabled = computed(
  () => props.disabled || selectedIndex.value === 1,
)
const isRightButtonDisabled = computed(
  () => props.disabled || selectedIndex.value === props.values.length,
)

const getIndex = (value?: string) => {
  return (
    (value &&
      props.values?.findIndex(
        item => props.getterFn?.(item, value) || item === value,
      )) ||
    0
  )
}

const updateAnalyticsDate = (index: number) => {
  modelValue.value = props.values?.[index]
}

const handleClickLeft = () => {
  selectedIndex.value--
}

const handleClickRight = () => {
  selectedIndex.value++
}
</script>

<style>
.ui-values-guide {
  @apply flex items-center justify-between;

  &--disabled {
    @apply opacity-50;
  }

  &__value {
    @apply w-24;
    @apply text-sm;
    @apply text-center;
  }

  .ui-button__icon--xs {
    @apply size-5;
  }
}
</style>
