<template>
  <div class="ui-drawer-title">
    <div class="ui-drawer-title__content">
      <slot>
        <span class="ui-drawer-title__value">{{ title }}</span>
      </slot>
    </div>
    <div class="ui-drawer-title__actions">
      <FoldButton
        v-if="!hideMinimiseButton"
        data-refid="slidePanelFold"
        tabindex="-1"
        class="slide-panel-body__fold"
        @click="handleMinimize"
      />
      <CloseButton
        data-refid="slidePanelClose"
        tabindex="-1"
        @click="handleClose"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import FoldButton from './components/FoldButton.vue'
import CloseButton from './components/CloseButton.vue'

type Props = {
  title?: string
  hideMinimiseButton?: boolean
}

type Emits = {
  'click:close': []
  'click:minimize': []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const handleMinimize = () => {
  emit('click:minimize')
}

const handleClose = () => {
  emit('click:close')
}
</script>

<style scoped>
.ui-drawer-title {
  @apply min-h-14;
  @apply flex items-center justify-between;
  @apply shrink-0;
  @apply gap-2 py-1 pl-default pr-2.5;
  @apply bg-body;
  @apply border-b border-light-border;

  &__content {
    @apply flex-auto;
  }

  &__value {
    @apply text-lg leading-none font-medium;
    @apply text-common-text;
  }

  &__actions {
    @apply flex items-center gap-2;
  }
}

/* .ui-slide-panel__container--horizontal &__header {
  @apply pb-4 pt-safe-offset-3;
} */

/* &__actions {
  @apply flex;
  @apply shrink-0;
  @apply gap-2 -mr-2;
  @apply text-gray-400 hover:text-gray-500;
  @apply dark:text-gray-500 dark:hover:text-gray-400;
}

&__content {
  @apply flex flex-col flex-auto;
  @apply pb-safe px-4 sm:px-6;
  @apply overflow-x-hidden overflow-y-auto;
}

&__fold {
  .ui-slide-panel__container--vertical & {
    @apply rotate-90;
  }
} */
</style>
