<template>
  <component
    :is="controllerComponent"
    ref="fieldRef"
    v-model="modelValue"
    v-bind="{
      ...$attrs,
      silentError,
      readonly,
    }"
    frozen
    id-key="key"
    placeholder="Find or create new"
    @create="handleCreate"
  >
    <template #item="{ item: { value, ticker } }">
      <div class="asset-dropdown-item">
        <span data-refid="inputDropdownItem">{{ value }}</span>
        <span v-if="value !== ticker" class="asset-dropdown-item__ticker">
          {{ ticker }}
        </span>
      </div>
    </template>
  </component>
  <UIButtonMenu
    v-if="isSummaryButtonVisible"
    type="button"
    variant="gray"
    fill="outlined"
    size="default"
    class="transaction-asset-field__summary-button"
    @click="handleClickMenu"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref, useTemplateRef } from 'vue'

import { TransactionSettings } from '@types'

import { useAssetsBunchStore } from '@/store/assets/bunch'

import { UIButtonMenu, UIInputDropdown, UIInputEditableDropdown } from '@ui'

type Props = {
  showSummaryButton?: boolean
  settings?: TransactionSettings['entries'][number]['account']
  readonly?: boolean
  disallowCreate?: boolean
}

type Emits = {
  create: [data: string]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<string>()

defineExpose({
  focus() {
    fieldRef.value?.focus()
  },
})

const fieldRef = useTemplateRef('fieldRef')

const assetsBunchStore = useAssetsBunchStore()

const isSavedTransaction = inject('isSavedTransaction', ref(false))

const controllerComponent = computed(() =>
  props.disallowCreate ? UIInputDropdown : UIInputEditableDropdown,
)

const readonly = computed(() => !!props.settings?.value || props.readonly)
const silentError = computed(() => !isSavedTransaction.value)

const isSummaryButtonVisible = computed(
  () => props.showSummaryButton && !!modelValue.value,
)

const handleClickMenu = () => {
  if (!modelValue.value) return
  const asset = assetsBunchStore.getList.get(modelValue.value)
  asset?.openDrawer()
}

const handleCreate = (data: string) => {
  emit('create', data)
}
</script>

<style>
.asset-dropdown-item {
  @apply w-full;
  @apply flex items-center gap-2 justify-between;

  &__ticker {
    @apply text-gray-400;
  }
}
.transaction-asset-field {
  &__summary-button {
    @apply px-0;

    .ui-button__icon {
      @apply !w-4;
    }
  }
}
</style>
