<template>
  <UIDropdown
    v-bind="{ items }"
    :disabled="dropdownDisabled"
    class="ui-button-dropdown"
    @click:item="handleClickItem"
  >
    <template #default="{ isShown }">
      <UIButton
        v-bind="{ ...$attrs, disabled, size, variant }"
        class="ui-button-dropdown__button"
        @click="handleClick"
      />
      <UIButtonIcon
        v-bind="{ size, variant }"
        :disabled="dropdownDisabled"
        :leading="getChevronComponent(isShown)"
        class="ui-button-dropdown__toggler"
        @click.stop
      />
    </template>
  </UIDropdown>
</template>

<script setup lang="ts" generic="T">
import { computed } from 'vue'

import { DropdownItem } from '@types'

import { ButtonSize, ButtonVariant } from './utils/types'

import { UIButton, UIButtonIcon, UIDropdown } from '@ui'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline'

type Props = {
  items?: DropdownItem<T>[]

  size?: ButtonSize
  variant?: ButtonVariant

  disabled?: boolean
}

type Emits = {
  'click:main': [event?: MouseEvent]
  'click:item': [data: DropdownItem<T>, event?: MouseEvent]
}
const emit = defineEmits<Emits>()

const { size = 'default', variant = 'primary', ...props } = defineProps<Props>()

const dropdownDisabled = computed(() => !props.items?.length)

const getChevronComponent = (isShown?: boolean) => {
  return isShown ? ChevronUpIcon : ChevronDownIcon
}

const handleClick = (event?: MouseEvent) => {
  emit('click:main', event)
}

const handleClickItem = (item: DropdownItem<T>, event: MouseEvent) => {
  emit('click:item', item, event)
}
</script>

<style scoped>
.ui-button-dropdown {
  @apply flex;

  &__button {
    @apply rounded-r-none;

    &.ui-button--xxs,
    &.ui-button--xs {
      @apply pr-1.5;
    }
    &.ui-button--sm {
      @apply pr-1;
    }
    &.ui-button--default,
    &.ui-button--lg {
      @apply pr-2;
    }
    &.ui-button--xlg {
      @apply pr-3;
    }
  }

  &__toggler {
    :deep() .ui-button {
      @apply aspect-auto;
      @apply px-0.5;
      @apply rounded-l-none;

      &__icon {
        @apply w-4 h-4;
      }
    }
  }
}
</style>
