<template>
  <AssetDrawerValuesHolders v-model:selected="holders" :assets />
  <AssetDrawerValuesChart :assets :holders />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { AssetClass } from '..'

import AssetDrawerValuesChart from './AssetDrawerValuesChart.vue'
import AssetDrawerValuesHolders from './AssetDrawerValuesHolders.vue'

type Props = {
  instance: AssetClass
}

const props = defineProps<Props>()

defineOptions({ inheritAttrs: false })

const holders = ref<string[]>([])

const assets = computed(() => [props.instance.id])
</script>
