<template>
  <div class="wizzard__summary">
    <WizzardSummaryItem
      title="Investing or Holding Entity"
      :value="accountName"
    />
    <WizzardSummaryItem title="Investment" :value="assetName" />
    <WizzardSummaryItem title="Date of Investment" :value="dateOfPurchase" />
    <WizzardSummaryItem title="Cost of Investment" :value="propertyCost" />
    <WizzardSummaryItem title="Description" :value="transactionNotes" />
    <WizzardSummaryItem title="Valuation" :value="valuation" />
    <WizzardSummaryItem
      v-for="(tag, index) in displayTags"
      :key="index"
      :title="tag[0]"
      :value="tag[1]"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject } from 'vue'

import { AssetClass, AssetPriceForm, TransactionClass } from '@types'

import {
  ASSET_FIELD,
  TRANSACTION_ENTRY_FIELD,
  TRANSACTION_FIELD,
} from '@/const'

import { numberFormat } from '@/helpers/numbers'

import { useAssetsBunchStore } from '@/store/assets/bunch'

import WizzardSummaryItem from '../components/WizzardSummaryItem.vue'

type Props = {
  useExistingAccount: boolean
  transaction: TransactionClass
  account: AssetClass
  asset: AssetClass
  prices: AssetPriceForm[]
  tags: Record<string, string>
  assignTags?: boolean
}

const props = defineProps<Props>()
const currency = inject<ComputedRef<string>>('currency')

const assetsBunchStore = useAssetsBunchStore()

const dateOfPurchase = computed(
  () => props.transaction.field<string>(TRANSACTION_FIELD.DATE).value,
)

const accountName = computed(() => {
  let account
  if (props.useExistingAccount) {
    const accountId =
      props.transaction.entries?.[0]?.[TRANSACTION_ENTRY_FIELD.ACCOUNT_ID]
    account = assetsBunchStore.getElementById(accountId)
  } else {
    account = props.account
  }
  const result = [account?.field<string>(ASSET_FIELD.NAME)?.value]
  if (account?.field<string>(ASSET_FIELD.TYPE)?.value) {
    result.push(` (${account.field<string>(ASSET_FIELD.TYPE)?.value})`)
  }
  return result.join('')
})

const assetName = computed(() => {
  const result = [props.asset.field<string>(ASSET_FIELD.NAME)?.value]
  if (props.asset.field<string>(ASSET_FIELD.TYPE)?.value) {
    result.push(` (${props.asset.field<string>(ASSET_FIELD.TYPE)?.value})`)
  }
  return result.join('')
})

const propertyCost = computed(() => {
  const amount = props.transaction.entries?.[1][TRANSACTION_ENTRY_FIELD.AMOUNT]
  return amount
    ? numberFormat(Math.abs(amount), { currency: currency?.value })
    : undefined
})

const transactionNotes = computed(
  () => props.transaction.field<string>(TRANSACTION_FIELD.DESCRIPTION)?.value,
)

const valuation = computed(() => {
  const count = props.prices.length
  return count ? `${count} ${count === 1 ? 'item' : 'items'}` : undefined
})

const displayTags = computed(
  () => (props.assignTags && Object.entries(props.tags)) || [],
)
</script>
