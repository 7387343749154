<template>
  <template v-if="breadcrumbs">
    <UIButtonIcon
      v-if="isButtonsVisible"
      :disabled="!toPrev"
      :leading="ArrowLeftIcon"
      size="xs"
      @click="handleClickPrev"
    />
    <div class="main-page-name__container">
      <div
        v-for="(item, index) in breadcrumbs"
        :key="item"
        :class="`main-page-name__item main-page-name__item--level${index + 1}`"
      >
        {{ item }}
      </div>
    </div>
    <UIButtonIcon
      v-if="isButtonsVisible"
      :disabled="!toNext"
      :leading="ArrowRightIcon"
      size="xs"
      @click="handleClickNext"
    />
  </template>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { DASHBOARD_NAME, ROUTE_NAME } from '@/const'

import { useDashboardsSettingsStore } from '@/store/settings/dashboards'
import { useNavigationStore } from '@/store/navigation'
import { UIButtonIcon } from '@ui'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline'

const route = useRoute()
const router = useRouter()

const dashboardSettingsStore = useDashboardsSettingsStore()
const navigationStore = useNavigationStore()

const breadcrumbs = computed(() => navigationStore.getBreadcrumbs)

const list = computed(() => dashboardSettingsStore.getDashboards)

const isButtonsVisible = computed(() => {
  return (
    breadcrumbs.value[0] === DASHBOARD_NAME &&
    list.value &&
    list.value.length > 1
  )
})

const currentSlug = computed(() => route.params?.slug)

const currentDashboardIndex = computed(() => {
  return list.value?.findIndex(item => item.slug === currentSlug.value)
})

const prevSlug = computed(
  () =>
    currentDashboardIndex.value !== undefined &&
    currentDashboardIndex.value > 0 &&
    list.value?.[currentDashboardIndex.value - 1]?.slug,
)
const nextSlug = computed(
  () =>
    list.value &&
    currentDashboardIndex.value !== undefined &&
    currentDashboardIndex.value < list.value?.length - 1 &&
    list.value?.[currentDashboardIndex.value + 1]?.slug,
)

const toPrev = computed(() =>
  prevSlug.value
    ? {
        name: ROUTE_NAME.DASHBOARDS_DASHBOARD,
        params: { slug: prevSlug.value },
      }
    : undefined,
)

const toNext = computed(() =>
  nextSlug.value
    ? {
        name: ROUTE_NAME.DASHBOARDS_DASHBOARD,
        params: { slug: nextSlug.value },
      }
    : undefined,
)

const handleClickPrev = () => {
  toPrev.value && router.push(toPrev.value)
}

const handleClickNext = () => {
  toNext.value && router.push(toNext.value)
}
</script>

<style scoped>
.main-page-name {
  &__container {
    @apply flex-auto;
    @apply overflow-hidden;
  }

  &__item {
    @apply text-xs;
    @apply tracking-normal;
    @apply text-center;
    @apply truncate;

    &--level1 {
      @apply font-medium;
      @apply uppercase;
    }
  }

  :deep() .ui-button {
    @apply !bg-transparent;
  }
}
</style>
