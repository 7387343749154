<template>
  <AssetDrawer :modal :instance :account-id :amount :path currency />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { AssetClass, ModalClass } from '@types'

import AssetDrawer from './AssetDrawer.vue'

type Props = {
  modal: ModalClass<AssetClass>
  instance?: AssetClass
  accountId?: string
  amount?: number
  path?: string[]
}

const props = defineProps<Props>()

const instance = computed(() => props.instance || props.modal.entity)
</script>
