<template>
  <button
    v-bind="{ disabled }"
    ref="mainRef"
    class="ui-button"
    :class="mainClasses"
    @click="handleClick"
    @keydown:enter="handleClick"
  >
    <LoaderIcon v-if="loading" class="ui-button__icon" />
    <slot v-else name="leading">
      <component
        :is="leading"
        v-if="leading"
        class="ui-button__icon"
        :class="iconClasses"
        aria-hidden="true"
      />
    </slot>
    <slot>
      <div v-if="label" class="ui-button__label" :class="labelClasses">
        {{ label }}
      </div>
    </slot>
    <slot name="trailing">
      <component
        :is="trailing"
        v-if="trailing"
        class="ui-button__icon"
        :class="iconClasses"
        aria-hidden="true"
      />
    </slot>
  </button>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'
import type { Component } from 'vue'

import { ButtonFill, ButtonSize, ButtonVariant } from './utils/types'

import LoaderIcon from '@/components/UI/Preloader/PreloaderIcon.vue'

type Props = {
  label?: string

  variant?: ButtonVariant
  size?: ButtonSize
  fill?: ButtonFill

  full?: boolean

  disabled?: boolean

  leading?: Component
  trailing?: Component

  loading?: boolean

  hideLabelToSm?: boolean
  hideLabelToMd?: boolean
}

type Emits = {
  click: [event?: MouseEvent]
}

const {
  size = 'default',
  variant = 'primary',
  fill = 'filled',
  ...props
} = defineProps<Props>()

const emit = defineEmits<Emits>()

defineOptions({ name: 'UIButton' })

const mainRef = useTemplateRef<HTMLElement>('mainRef')

defineExpose({
  focus() {
    mainRef.value?.focus()
  },
})

const mainClasses = computed(() => ({
  [`ui-button--${size} ui-button--${variant}-${fill}`]: true,
  'ui-button--full': props.full,
  'ui-button--loading': props.loading,
}))

const labelClasses = computed(() => ({
  'ui-button__label--hide-sm': props.hideLabelToSm,
  'ui-button__label--hide-md': props.hideLabelToMd,
}))

const iconClasses = computed(() => [
  `ui-button__icon--${size} ui-button__icon--${variant}`,
])

const handleClick = (event?: MouseEvent) => {
  if (props.disabled || props.loading) return
  emit('click', event)
}
</script>

<style>
@import './assets/styles/colors.css';

.ui-button {
  @apply inline-flex items-center justify-center;
  @apply gap-x-1.5;
  @apply border border-transparent;
  @apply focus:outline-none;
  @apply focus:ring-2 focus:ring-offset-2;
  @apply !leading-tight;
  @apply font-medium;
  @apply cursor-pointer;

  @apply ring-offset-body;

  &[disabled] {
    @apply opacity-50;
    @apply cursor-default;
  }

  &--full {
    @apply w-full;
  }

  &--xxs {
    @apply h-6;
    @apply px-2;
    @apply text-xs;
    @apply rounded;
  }

  &--xxs &__icon {
    @apply w-3;
  }

  &--xs {
    @apply h-[1.875rem];
    @apply px-2;
    @apply text-xs;
    @apply rounded;
  }

  &--xs &__icon {
    @apply w-3;
  }

  &--sm {
    @apply h-[2.125rem];
    @apply px-2.5;
    @apply text-xs;
    @apply rounded-md;
  }

  &--sm &__icon {
    @apply w-3;
  }

  &--default {
    @apply h-[2.375rem];
    @apply px-4;
    @apply text-sm;
    @apply rounded-md;
  }

  &--default &__icon {
    @apply w-4;
  }

  &--lg {
    @apply h-[2.625rem];
    @apply px-4;
    @apply text-base;
    @apply rounded-md;
  }

  &--lg &__icon {
    @apply w-5;
  }

  &--xl {
    @apply h-[3.125rem];
    @apply px-6;
    @apply text-base;
    @apply rounded-md;
  }

  &--xl &__icon {
    @apply w-6;
  }

  &--2xl {
    @apply h-[4.375rem];
    @apply px-7;
    @apply text-lg;
    @apply rounded-xl;
  }

  &--2xl &__icon {
    @apply w-7;
  }

  &--primary-filled {
    @apply bg-[var(--button-primary)];
    @apply text-[var(--button-primary-contrast)];

    &:not([disabled]) {
      @apply hover:bg-[var(--button-primary-hover)];
      @apply focus:ring-[var(--button-primary-focus)];
    }
  }

  &--primary-light,
  &--primary-outlined {
    @apply text-[var(--button-primary-light)];

    &:not([disabled]) {
      @apply hover:text-[var(--button-primary-light-hover)];
      @apply hover:bg-[var(--button-primary-light-bg)];
      @apply focus:ring-[var(--button-primary-light-focus)];
    }
  }

  &--primary-outlined {
    @apply border-[var(--button-primary-light)];

    &:not([disabled]) {
      @apply hover:border-[var(--button-primary-light-hover)];
    }
  }

  &--secondary-filled {
    @apply bg-[var(--button-secondary)];
    @apply text-[var(--button-secondary-contrast)];

    &:not([disabled]) {
      @apply hover:bg-[var(--button-secondary-hover)];
      @apply focus:ring-[var(--button-secondary-focus)];
    }
  }

  &--secondary-light,
  &--secondary-outlined {
    @apply text-[var(--button-secondary-light)];

    &:not([disabled]) {
      @apply hover:text-[var(--button-secondary-light-hover)];
      @apply hover:bg-[var(--button-secondary-light-bg)];
      @apply focus:ring-[var(--button-secondary-light-focus)];
    }
  }

  &--secondary-outlined {
    @apply border-[var(--button-secondary-light)];

    &:not([disabled]) {
      @apply hover:border-[var(--button-secondary-light-hover)];
    }
  }

  &--danger-filled {
    @apply bg-[var(--button-danger)];
    @apply text-[var(--button-danger-contrast)];

    &:not([disabled]) {
      @apply hover:bg-[var(--button-danger-hover)];
      @apply focus:ring-[var(--button-danger-focus)];
    }
  }

  &--danger-light,
  &--danger-outlined {
    @apply text-[var(--button-danger-light)];

    &:not([disabled]) {
      @apply hover:text-[var(--button-danger-light-hover)];
      @apply hover:bg-[var(--button-danger-light-bg)];
      @apply focus:ring-[var(--button-danger-light-focus)];
    }
  }

  &--danger-outlined {
    @apply border-[var(--button-danger-light)];

    &:not([disabled]) {
      @apply hover:border-[var(--button-danger-light-hover)];
    }
  }

  &--gray-filled {
    @apply bg-[var(--button-gray)];
    @apply text-[var(--button-gray-contrast)];

    &:not([disabled]) {
      @apply hover:bg-[var(--button-gray-hover)];
      @apply focus:ring-[var(--button-gray-focus)];
    }
  }

  &--gray-light,
  &--gray-outlined {
    @apply text-[var(--button-gray-light)];

    &:not([disabled]) {
      @apply hover:text-[var(--button-gray-light-hover)];
      @apply hover:bg-[var(--button-gray-light-bg)];
      @apply focus:ring-[var(--button-gray-light-focus)];
    }
  }

  &--gray-outlined {
    @apply border-[var(--button-gray-light-border)];
  }

  &--primary-light,
  &--secondary-light,
  &--danger-light,
  &--gray-light {
    &:not(:hover) {
      @apply focus:ring-offset-0;
    }
  }

  &__icon {
    @apply shrink-0;
    @apply aspect-square;
    @apply text-inherit;
  }

  &__label {
    @apply line-clamp-2;
    @apply text-ellipsis;

    &--hide-sm {
      @apply hidden sm:block;
    }

    &--hide-md {
      @apply sm:hidden md:block;
    }
  }
}
</style>
