<template>
  <Dropdown
    v-bind="{ disabled, shown, placement }"
    :triggers="[]"
    :auto-hide="false"
    auto-boundary-max-size
  >
    <ToggleButton
      v-bind="{ ...$attrs, count, disabled }"
      @click="handleToggle"
    />
    <template #popper>
      <div ref="containerRef" class="ui-filter" :data-ref="containerRefId">
        <div class="ui-filter__header">
          <div class="ui-filter__header-title">{{ t('Active filters') }}</div>
          <UIButtonIcon
            size="xs"
            variant="gray"
            fill="light"
            data-refid="filter__close-popup_button"
            :leading="XMarkIcon"
            class="ui-filter__header-button"
            @click="handleToggle"
          />
        </div>
        <Groups v-model="storage" v-bind="{ fields, flat }" />
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import {
  computed,
  onMounted,
  onUnmounted,
  provide,
  ref,
  useTemplateRef,
  watch,
} from 'vue'
import { isEqual } from 'lodash'
import { onClickOutside } from '@vueuse/core'
import { Dropdown, Placement } from 'floating-vue'

import { Filter, FilterField, FilterGroup, FilterParam } from './utils/types'
import { FilterLogic } from './utils/enums'

import { useLocale } from '@/plugins/i18n'

import { getActiveFilters, normalizeOldData } from './utils/helpers'
import { generateRandomKey } from './components/utils/helpers'

import { XMarkIcon } from '@heroicons/vue/24/outline'
import { UIButtonIcon } from '@ui'
import Groups from './components/Groups.vue'
import ToggleButton from './components/ToggleButton.vue'

type Props = {
  disabled?: boolean
  fields?: FilterField[]
  flat?: boolean
  placement?: Placement
}

const { flat, placement = 'bottom-end' } = defineProps<Props>()

const modelValue = defineModel<Filter | FilterGroup>()
const modelStorage = defineModel<Filter>('storage')

const { t } = useLocale('components.UI.Filter')
provide('t', t)

const containerRef = useTemplateRef('containerRef')

const shown = ref(false)

const containerRefId = `filter_${generateRandomKey()}`
provide('container', `[data-ref="${containerRefId}"]`)

const storage = computed<Filter>({
  get() {
    return normalizeOldData(modelStorage.value)
  },
  set(value) {
    modelStorage.value = value
  },
})

const activeParams = computed(() => getActiveFilters(storage.value))

const count = computed(() =>
  activeParams.value?.params.reduce(
    (acc, group) => acc + group.params.length,
    0,
  ),
)

const handleToggle = () => {
  shown.value = !shown.value
}

const handleKeyUp = (event: KeyboardEvent) => {
  if (shown.value && event.key == 'Escape') {
    shown.value = false
  }
}

const applyModel = (value?: Filter) => {
  if (flat) {
    const params = value?.params.reduce((acc, item) => {
      return [...acc, ...item.params]
    }, [] as FilterParam[])
    modelValue.value = {
      logic: value?.logic || FilterLogic.AND,
      params,
    } as FilterGroup
  } else {
    modelValue.value = value
  }
}

watch(
  activeParams,
  (value, prev) => {
    if (isEqual(value, prev)) return
    applyModel(value)
  },
  { immediate: true },
)

onClickOutside(containerRef, () => {
  if (!shown.value) return
  shown.value = false
})

onMounted(() => {
  document.addEventListener('keyup', handleKeyUp)
})

onUnmounted(() => {
  document.removeEventListener('keyup', handleKeyUp)
})
</script>

<script lang="ts">
export default {
  name: 'UIFilter',
  inheritAttrs: false,
}
</script>

<style scoped>
.ui-filter {
  @apply p-4 pt-2;

  &__header {
    @apply flex justify-between;
    @apply mb-2;
  }

  &__header-title {
    @apply text-sm;
    @apply text-gray-500 dark:text-gray-300;
  }

  &__header-button {
    @apply -mt-1 -mr-1.5;
  }
}
</style>
