<script lang="ts">
import { FunctionalComponent, h, inject } from 'vue'

import { ReadonlyMode, TreeBranch } from '@types'

import { READONLY_MODE } from '@/const/common'

import { EyeIcon, RectangleStackIcon } from '@heroicons/vue/24/outline'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'

const TreeItem: FunctionalComponent<
  {
    branches?: TreeBranch[]
    currentBranch?: TreeBranch
    isRoot?: boolean
    isOpened?: boolean
    hasChildren?: boolean
  },
  { 'click:branch': [] }
> = (props, context) => {
  const { branches, currentBranch, isRoot, isOpened, hasChildren } = props
  const { emit } = context

  const wide = inject<boolean>('wide')

  const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

  const nodes = []

  branches?.forEach(branch =>
    nodes.push(
      h('div', {
        class: [
          'ui-tree__branch',
          `ui-tree__branch--${branch}`,
          ...(wide ? [`ui-tree__branch--${branch}-wide`] : []),
        ],
      }),
    ),
  )

  if (!isRoot) {
    nodes.push(
      h('div', {
        class: [
          'ui-tree__branch',
          `ui-tree__branch--${currentBranch}`,
          ...(wide ? [`ui-tree__branch--${currentBranch}-wide`] : []),
        ],
      }),
    )
  }

  if (hasChildren) {
    let iconComponent
    if (!isRoot) {
      iconComponent = ChevronRightIcon
    } else if (isReadonly?.value) {
      iconComponent = EyeIcon
    } else {
      iconComponent = RectangleStackIcon
    }

    const handleClickBranch = () => {
      emit('click:branch')
    }

    nodes.push(
      h(
        'div',
        {
          class: {
            'ui-tree__icon': true,
            'ui-tree__icon--branch': hasChildren && isOpened,
            'ui-tree__icon--branch-wide': wide,
          },
        },
        h(
          'div',
          {
            class: {
              'ui-tree__icon-circle': true,
              'ui-tree__icon-circle--wide': wide,
              'ui-tree__icon-circle--root': isRoot,
              'ui-tree__icon-circle--open': isOpened && !isRoot,
            },
            onClick: handleClickBranch,
          },
          h(iconComponent, { ariaHidden: true }),
        ),
      ),
    )
  } else {
    nodes.push(
      h('div', {
        class: {
          'ui-tree__dot': true,
          'ui-tree__dot--wide': wide,
        },
      }),
    )
  }

  return nodes
}

export default TreeItem
</script>

<style>
.ui-tree {
  &__branch {
    @apply w-5;
  }

  &__branch--i,
  &__branch--l,
  &__branch--x,
  &__icon--branch {
    @apply before:absolute;
    @apply after:absolute;
    @apply before:content-[''];
    @apply after:content-[''];
    @apply before:border-gray-300 dark:border-gray-600;
    @apply after:border-gray-300 dark:border-gray-600;
    @apply before:border-dotted;
    @apply after:border-dotted;
  }

  &__branch {
    @apply relative;
    @apply shrink-0;

    &--i {
      @apply before:top-[1px] before:-bottom-0 before:left-1/2;
      @apply before:border-r;
    }

    &--l {
      @apply before:top-[1px] before:bottom-1/2 before:left-1/2;
      @apply before:border-r;
      @apply after:left-1/2 after:-right-[1px] after:top-[calc(50%+1px)];
      @apply after:border-t;

      &-wide {
        @apply before:mb-[.5rem];
        @apply after:-mt-[.5rem];
      }
    }

    &--x {
      @apply before:top-[1px] before:bottom-0 before:left-1/2;
      @apply before:border-r;
      @apply after:right-[1px] after:left-[calc(50%+2px)] after:top-1/2;
      @apply after:border-t;

      &-wide {
        @apply after:-mt-[.5rem];
      }
    }
  }

  &__icon {
    @apply flex items-center;
    @apply relative;

    &--branch {
      @apply before:border-r;
      @apply before:left-1/2 before:top-1/2 before:bottom-0;

      &-wide {
        @apply before:-mt-[.5rem];
      }
    }

    &-circle {
      @apply w-5 h-5;
      @apply relative;
      @apply flex justify-center items-center;
      @apply bg-gray-50 hover:bg-gray-100;
      @apply dark:bg-gray-800 dark:hover:bg-gray-900;
      @apply text-indigo-700 dark:text-indigo-300;
      @apply rounded-full;
      @apply cursor-pointer;

      &--wide {
        @apply -mt-[1rem];
      }

      &--root {
        @apply mt-0;
        @apply !text-gray-400;
      }

      &--open {
        @apply bg-indigo-700 hover:bg-indigo-600;
        @apply dark:bg-indigo-300 dark:hover:bg-indigo-400;
        @apply text-white dark:text-gray-900;
        @apply rotate-90;
      }

      svg {
        @apply w-5 h-5;
      }
    }
  }

  &__dot {
    @apply flex items-center;
    @apply before:content-[''];
    @apply before:w-[3px] before:h-[3px];
    @apply before:block;
    @apply before:bg-gray-300;
    @apply before:rounded-full;
    @apply -mb-[1px];

    &--wide {
      @apply -mb-[calc(1px_-_1rem)];
    }
  }

  &__branch--l + &__dot {
    @apply -mb-[2px];

    &--wide {
      @apply -mb-[calc(2px_-_1rem)];
    }
  }
}
</style>
