export enum TransactionRuleType {
  DESCRIPTION = 'description',
  ACCOUNT_NAME = 'account',
  ACCOUNT_NUMBER = 'account number',
  AMOUNT = 'amount',
  TRANSACTION_TYPE = 'transaction type',
  TRANSACTION_DATE = 'transaction date',
  // CUSTOM_FIELD = 'custom field',
  CATEGORY = 'category',
  STATUS = 'status',
}

export enum TransactionRuleActionRuleType {
  ADD_TAG = 'add tag',
  SET_CATEGORY = 'set category',
  SET_SUB_CATEGORY = 'set subcategory',
  // SET_ACCOUNT = 'set account',
  SET_COUNTERPARTY = 'set counterparty',
  SET_SIGN = 'set sign',
}

export enum LinkedDataManualLoader {
  OFX = 'ofx',
}
