<template>
  <div class="main-desktop-navbar">
    <div class="main-desktop-navbar__menu">
      <template v-for="(item, index) in items" :key="item.label">
        <NavbarItem v-bind="{ item }" :wide="isNavbarOpened" />
        <MainDesktopNavbarTree v-if="index === 0" />
      </template>
      <MainDesktopNavbarAddAsset />
      <MainDesktopNavbarRepository />
    </div>
  </div>
  <NavbarContainer v-if="isTreeOpened" :tooltip="t('Close Asset tree')">
    <MainTree />
  </NavbarContainer>
  <NavbarContainer v-if="isRepositoryOpened" :tooltip="t('Close Repositories')">
    <NavbarRepositories />
  </NavbarContainer>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { OpenedFlag } from './utils/type'

import { NAVBAR_OPENED, REPOSITORIES_OPENED, TREE_OPENED } from './utils/const'

import { useNavigationStore } from '@/store/navigation'

import { useLocale } from '@/plugins/i18n'

import MainDesktopNavbarAddAsset from './MainDesktopNavbarAddAsset.vue'
import MainDesktopNavbarRepository from './MainDesktopNavbarRepository.vue'
import MainDesktopNavbarTree from './MainDesktopNavbarTree.vue'
import MainTree from './components/Tree.vue'
import NavbarContainer from './components/NavbarContainer.vue'
import NavbarItem from './components/NavbarItem.vue'
import NavbarRepositories from './components/NavbarRepositories.vue'

const { t } = useLocale('layouts.Main')

const navigationStore = useNavigationStore()

const isNavbarOpened = inject<OpenedFlag>(NAVBAR_OPENED)
const isRepositoryOpened = inject<OpenedFlag>(REPOSITORIES_OPENED)
const isTreeOpened = inject<OpenedFlag>(TREE_OPENED)

const items = computed(() => navigationStore.getParentNavigation)
</script>

<style>
.main-desktop-navbar {
  @apply w-[4.2rem];
  @apply flex flex-col items-center;
  @apply shrink-0;
  @apply py-2 gap-2;
  @apply bg-body-gray;
  @apply border-r border-light-border;
  @apply z-10;
  @apply transition-all;

  .main-desktop--opened-navbar & {
    @apply w-40;
    @apply px-2;
    @apply items-start;
  }

  .main-desktop--opened-container & {
    @apply gap-4;
    @apply border-common-border;
  }

  &__menu {
    @apply w-full;
    @apply flex flex-col;
    @apply flex-auto;
    @apply gap-2;

    .main-desktop--opened-navbar & {
      @apply w-full;
    }
  }
}
</style>
