<template>
  <UIPricesChart
    v-model:range="range"
    v-bind="{ prices, nameFormatter, valueFormatter }"
  >
    <slot />
  </UIPricesChart>
</template>

<script setup lang="ts">
import { computed, provide, ref } from 'vue'

import { AssetClass } from '..'
import { AssetPrice, AssetPriceCloseField } from '../utils/types'
import { AssetPriceSource } from './utils/enums'
import { ChartPricesRange } from '@/components/UI/EChart/utils/enums'
import { numberFormat } from '@/helpers/numbers'
import { stringToLocalDateString } from '@/helpers/dates'

import { UIPricesChart } from '@ui'

type Props = {
  instance: AssetClass
  items: AssetPrice[]
  priceSource: AssetPriceSource
}

const props = defineProps<Props>()

provide('grid-no-data-text', '')

const range = ref<ChartPricesRange>()

const currency = computed(() => props.instance.currencySymbol)

const valueFormatter = computed(
  () => (data: number, fractionDigits?: number) =>
    numberFormat(data, { currency: currency?.value, fractionDigits }),
)

const closeField = computed<AssetPriceCloseField>(() =>
  props.priceSource === AssetPriceSource.SECURITY_MASTER_ADJ
    ? 'adjusted_close'
    : 'close',
)

const dataAkoya = computed(() =>
  props.items.filter(item => item.source === 'akoya').map(mapDataFn),
)

const dataManual = computed(() =>
  props.items.filter(item => item.source === 'manual').map(mapDataFn),
)

const dataSecurityMaster = computed(() =>
  props.items.filter(item => item.source === 'security master').map(mapDataFn),
)

const dataYodlee = computed(() =>
  props.items.filter(item => item.source === 'yodlee').map(mapDataFn),
)

const dataPlaid = computed(() =>
  props.items.filter(item => item.source === 'plaid').map(mapDataFn),
)

const prices = computed(() => [
  {
    name: 'Akoya',
    itemStyle: {
      color: 'rgb(23, 138, 172)',
    },
    data: dataAkoya.value,
  },
  {
    name: 'Manual',
    itemStyle: {
      color: 'rgb(21,128,60)',
    },
    data: dataManual.value,
  },
  {
    name: 'Security master',
    itemStyle: {
      color: 'rgb(28,78,216)',
    },
    data: dataSecurityMaster.value,
  },
  {
    name: 'Yodlee',
    itemStyle: {
      color: 'rgb(9,74,140)',
    },
    data: dataYodlee.value,
  },
  {
    name: 'Plaid',
    itemStyle: {
      color: 'rgb(78,108,170)',
    },
    data: dataPlaid.value,
  },
])

const mapDataFn = (item: AssetPrice) =>
  ({
    name: stringToLocalDateString(item.date),
    value: [item.date, item[closeField.value]],
  }) as any

const nameFormatter = (data: string) => {
  const postfix =
    props.priceSource === AssetPriceSource.SECURITY_MASTER_ADJ ? ' adj' : ''
  return `${data}${postfix} price`
}
</script>
