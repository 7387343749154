<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog
      v-bind="{ zIndex }"
      size="lg"
      :title="`Deleted ${props.plural}`"
      @hide="handleClose"
    >
      <div class="flex flex-col h-96 -mt-3 lg:px-2">
        <UIPanel
          v-model="clearedSearch"
          v-model:storage="searchStorage"
          :placeholder="`Search ${props.plural}...`"
        >
          <template #default="{ ButtonExport, size }">
            <UIFilter
              v-model="clearedFilter"
              v-model:storage="filterStorage"
              v-bind="{ size }"
              :fields="filterFields"
              flat
            />
            <UISort
              v-model="clearedSort"
              v-model:storage="sortStorage"
              v-bind="{ size }"
              :fields="sortFields"
            />
            <component
              :is="ButtonExport"
              v-if="selectedItems.length"
              @click="handleExport"
            />
          </template>
        </UIPanel>
        <UIGridSkeleton
          v-if="loading"
          v-bind="{ columns, sm }"
          selectable
          wrapped
          message="Loading assets..."
        />
        <UIGrid
          v-else
          ref="gridRef"
          v-model:selected="selectedItems"
          v-model:sort="sortStorage"
          v-bind="{ columns, sm }"
          :items="outputItems"
          selectable
          wrapped
          scrollable
        />
      </div>
      <template v-if="!!outputItems?.length" #buttons>
        <div class="flex justify-end mt-4 gap-4">
          <UIButton
            v-if="selectedItems.length > 0"
            :leading="ArrowPathIcon"
            label="Restore"
            fill="light"
            @click="onRestoreSelectedClick"
          />
          <UIButton
            :label="emptyButtonLabel"
            :leading="TrashIcon"
            variant="danger"
            fill="light"
            @click="onEmptyTrashClick"
          />
        </div>
      </template>
    </UIDialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { computed, ref, useTemplateRef } from 'vue'
import { upperFirst } from 'lodash'
import { TransitionRoot } from '@headlessui/vue'

import { AssetClass } from '@/entities/assets'
import {
  Asset,
  Filter,
  FilterGroup,
  GridColumn,
  ModalClass,
  ModalState,
  Sort,
} from '@types'

import { NOTIFICATION_DELAY } from '@/const'

import { useNotifications } from '@/plugins/notification'

import { handleCatchedError } from '@/helpers/common'

import useGridFilter from '@/components/hooks/gridFilter'
import useGridSort from '@/components/hooks/gridSort'
import { useGridCheckedExport } from '@/components/hooks/gridExport'

import useAnalyticsStore from '@/store/analytics'
import { useAssetsStore } from '@/store/assets'
import { useAssetsStoreV2 } from '@/store/assets/index'
import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useTransactionsStore } from '@/store/transactions'
import { useTransactionsDeletedStore } from '@/store/transactions/deleted'

import {
  UIDialog,
  UIButton,
  UIFilter,
  UIGrid,
  UIGridSkeleton,
  UIPanel,
  UISort,
} from '@ui'
import { TrashIcon, ArrowPathIcon } from '@heroicons/vue/24/outline'

type Props = {
  modal: ModalClass<any>
  columns: GridColumn[]
  items: Asset[]
  plural: string
  loading?: boolean
}

const props = defineProps<Props>()

const analyticsStore = useAnalyticsStore()
const assetsStore = useAssetsStore()
const assetsStoreV2 = useAssetsStoreV2()
const assetsBunchStore = useAssetsBunchStore()
const transactionsStore = useTransactionsStore()
const transactionsDeletedStore = useTransactionsDeletedStore()

const { warn, progress, update } = useNotifications()

const gridRef = useTemplateRef('gridRef')

const selectedItems = ref<string[]>([])

const searchStorage = ref<string>()
const clearedSearch = ref<string>()
const clearedFilter = ref<FilterGroup>()
const filterStorage = ref<Filter>()
const sortStorage = ref<Sort[]>()
const clearedSort = ref<Sort[]>()

const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)
const zIndex = computed(() => props.modal.zIndex)

const capitalizedPlural = computed(() => upperFirst(props.plural))

const emptyButtonLabel = computed(() =>
  selectedItems.value.length > 0 ? 'Delete forever' : 'Empty trash',
)

const inputItems = computed(() => props.items)

const sm = '1.4fr 1fr 1.4fr'
const columns = computed(() => props.columns)

const filteredItems = useGridFilter(
  columns,
  inputItems,
  clearedSearch,
  clearedFilter,
)
const outputItems = useGridSort(clearedSort, columns, filteredItems)

const filterFields = computed(() =>
  columns.value.map(column => ({
    name: column.name,
    caption: column.caption,
    ...column.filter,
  })),
)

const sortFields = computed(() =>
  columns.value.map(column => ({
    key: column.name,
    value: column.caption,
  })),
)

const onEmptyTrashClick = async () => {
  await warn({
    message: 'Warning',
    description: `This action will permanently delete the ${props.plural} and all transactions referencing the ${props.plural}. You will not be able to restore them`,
    actionRequired: true,
    actions: [
      {
        label: 'Cancel',
        buttonVariant: 'gray',
        buttonFill: 'outlined',
        onClick: remove => {
          remove && remove()
        },
      },
      {
        label:
          selectedItems.value.length > 0 ? 'Delete selected' : 'Delete all',
        buttonVariant: 'danger',
        onClick: async selfRemove => {
          selfRemove && selfRemove()
          try {
            const nid = await progress({
              message: `Hard delete ${props.plural}`,
            })
            const itemsToDelete =
              selectedItems.value.length > 0
                ? inputItems.value.filter(item =>
                    selectedItems.value.includes(item.id),
                  )
                : inputItems.value
            const idsToDelete = itemsToDelete.map(item => item.id)
            const promises: Promise<void>[] = []
            idsToDelete.forEach(id => {
              promises.push(assetsStoreV2.permanentDelete(id))
            })
            await Promise.all(promises)
            assetsStoreV2.setDeleted(
              assetsStoreV2.getDeleted.filter(
                item => !idsToDelete.includes(item.id),
              ),
            )
            transactionsDeletedStore.clear()
            await update(
              nid,
              {
                type: 'success',
                message: `${capitalizedPlural.value} deleted`,
              },
              NOTIFICATION_DELAY,
            )
          } catch (e) {
            handleCatchedError(e as string)
          }
        },
      },
    ],
  })
}

const onRestoreSelectedClick = async () => {
  try {
    const nid = await progress({
      message: `Restoring ${props.plural}`,
    })
    const promises: Promise<void>[] = []
    selectedItems.value.forEach(id => {
      promises.push(
        new Promise((resolve, reject) => {
          assetsStoreV2.restore(id).then((asset: Asset | undefined) => {
            if (!asset) {
              reject()
              return
            }
            const instance = new AssetClass(asset)
            assetsBunchStore.unshiftElement(instance)
            resolve()
          })
        }),
      )
    })
    await Promise.all(promises)
    selectedItems.value = []
    analyticsStore.markAsDeprecated()
    await assetsStore.fetchAssets()
    transactionsStore.clear()
    transactionsDeletedStore.clear()
    await update(
      nid,
      {
        type: 'success',
        message: `${capitalizedPlural.value} restored`,
      },
      NOTIFICATION_DELAY,
    )
  } catch (e) {
    handleCatchedError(e as string)
  }
}

const handleExport = () => {
  if (!gridRef.value) return
  useGridCheckedExport(gridRef.value, 'deletedTransactions')
}

const handleClose = () => {
  props.modal.close()
}
</script>
