<template>
  <UIButtonIcon v-bind="{ leading, size }" variant="danger" fill="light" />
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { ButtonSize } from '@types'

import { UIButtonIcon } from '@ui'
import { TrashIcon as leading } from '@heroicons/vue/24/outline'

const size = inject<ButtonSize>('size')
</script>
