<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog
      v-bind="{ zIndex }"
      title="Add Arch Account"
      size="sm"
      @hide="handleClose"
    >
      <div class="arch-account">
        <UILabeledField
          v-model="idValue"
          :error="idError"
          :disabled="loading"
          label="Client ID"
        />
        <UILabeledField
          v-model="secretValue"
          :error="secretError"
          :disabled="loading"
          label="Client Secret"
        />
      </div>
      <template #buttons>
        <UIButton
          label="Add"
          v-bind="{ disabled, loading }"
          @click="handleClickAdd"
        />
        <UIButton
          label="Cancel"
          variant="gray"
          fill="outlined"
          :disabled="loading"
          @click="handleClose"
        />
      </template>
    </UIDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useField, useForm } from 'vee-validate'

import { ModalClass, ModalState } from '@types'

import { NOTIFICATION_DELAY } from '@/const'

import { rules } from '@/helpers/validate'
import { handleCatchedError } from '@/helpers/common'

import { useLinkedDataStore } from '@/store/linkedData'
import { useNotifications } from '@/plugins/notification'

import { TransitionRoot } from '@headlessui/vue'
import { UIButton, UIDialog, UILabeledField } from '@ui'

type Props = {
  modal: ModalClass<any>
}

const props = defineProps<Props>()

const linkedDataStore = useLinkedDataStore()
const { error, warn } = useNotifications()

const { handleSubmit, meta, resetForm } = useForm<{
  client_id: string
  client_secret: string
}>({
  initialValues: {
    client_id: '',
    client_secret: '',
  },
  validationSchema: {
    client_id: rules.required,
    client_secret: rules.required,
  },
})

const { value: idValue, errorMessage: idError } = useField<string>('client_id')
const { value: secretValue, errorMessage: secretError } =
  useField<string>('client_secret')

const loading = ref(false)

const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const disabled = computed(() => !meta.value.valid || loading.value)

const handleClose = () => {
  props.modal.close()
}

const handleClickAdd = handleSubmit(async values => {
  loading.value = true
  try {
    await linkedDataStore.arch(values.client_id, values.client_secret)
    await warn(
      {
        message: 'Syncing Arch Account',
        description: 'Please wait while we sync your Arch account',
      },
      NOTIFICATION_DELAY,
    )
    handleClose()
  } catch (e: any) {
    resetForm()
    error({ message: e.message || 'Something went wrong' })
    handleCatchedError(e)
  } finally {
    loading.value = false
  }
})

watch(isOpen, value => {
  if (value) return
  resetForm()
})
</script>

<style>
.arch-account {
  @apply flex flex-col gap-4;
}
</style>
