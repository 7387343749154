import { AssetPriceSource, AssetTab } from './enums'

export const ASSET_MODAL_TITLE = 'Asset'
export const ASSET_MODAL_TITLE_NEW = 'New asset'

export const CURRENCY_MODAL_TITLE = 'Currency'
export const CURRENCY_MODAL_TITLE_NEW = 'New currency'

export const INCOME_ACCOUNT_MODAL_TITLE = 'Income account'
export const INCOME_ACCOUNT_MODAL_TITLE_NEW = 'New income account'

export const EXPENSE_ACCOUNT_MODAL_TITLE = 'Expense account'
export const EXPENSE_ACCOUNT_MODAL_TITLE_NEW = 'New expense account'

export const ASSET_TABS = {
  [AssetTab.SECURITY_MASTER]: 'Security',
  [AssetTab.PRICES]: 'Prices',
  [AssetTab.TAGS]: 'Tags',
  [AssetTab.DOCUMENTS]: 'Documents',
  [AssetTab.CONTACTS]: 'Contacts',
}

export const CURRENCY_TABS = {
  [AssetTab.SECURITY_MASTER]: 'Security',
  [AssetTab.PRICES]: 'Prices',
  [AssetTab.TAGS]: 'Tags',
}

export const ASSET_PRICES_LIST = [
  { key: AssetPriceSource.ALL, value: 'All' },
  { key: AssetPriceSource.MANUAL, value: 'Manual' },
  { key: AssetPriceSource.CONNECTOR, value: 'Connector' },
  { key: AssetPriceSource.SECURITY_MASTER, value: 'Security Master' },
  {
    key: AssetPriceSource.SECURITY_MASTER_ADJ,
    value: 'Security Master Adjusted',
  },
]

export const ASSET_PRICES_SECURITY = [
  AssetPriceSource.SECURITY_MASTER,
  AssetPriceSource.SECURITY_MASTER_ADJ,
]

const PRIVATE_TYPES = [
  'subscribe and fund',
  'subscribe and commit',
  'capital call',
  'capital contribution',
  'capital return',
  'recallable capital return',
  'release of commitment',
  'fund fees',
  'cash dividend',
  'cash interest',
]

export const INVESTMENT_TYPES = ['buy', 'sell']

export const TRANSACTION_TYPES = {
  ['investment account']: INVESTMENT_TYPES,
  ['private fund']: PRIVATE_TYPES,
  ['private security']: PRIVATE_TYPES,
}
