<template>
  <UIDatePickerField
    ref="inputRef"
    v-model="modelValue"
    v-bind="{ container, size }"
    inline-popover
  />
</template>

<script setup lang="ts">
import { inject, useTemplateRef } from 'vue'

import { ButtonSize } from '@types'
import { FilterComparison } from '../utils/enums'
import { FilterField } from '../utils/types'

import { UIDatePickerField } from '@ui'

type Props = {
  fieldSettings?: FilterField
  comparison?: FilterComparison
}

defineProps<Props>()

const modelValue = defineModel<any>()

defineExpose({
  start() {
    inputRef.value?.select()
  },
})

const container = inject<string>('container')
const size = inject<ButtonSize>('size')

const inputRef = useTemplateRef('inputRef')
</script>

<script lang="ts">
export default {
  name: 'FilterGroupsItemParamValueDate',
}
</script>

<style scoped lang="postcss"></style>
