<template>
  <AddAccountOFX ref="ofxRef" />
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue'

import { LinkedDataManualLoader } from '@types'

import AddAccountOFX from './AddAccountOFX.vue'

defineExpose({
  processing(data: string) {
    switch (data as LinkedDataManualLoader) {
      case LinkedDataManualLoader.OFX:
        ofxRef.value?.show(true)
        break
    }
  },
})

const ofxRef = useTemplateRef('ofxRef')

defineOptions({
  name: 'ConnectorsImportAccount',
})
</script>
