<template>
  <div class="transaction__fields">
    <component
      :is="instance.getFormType()"
      v-if="isTypeVisible"
      ref="typeFieldRef"
      v-bind="{
        dataRefid: 'transactionFormType',
        instance,
        label: TRANSACTION_FIELD_LABEL[TRANSACTION_FIELD.TYPE],
        restrictions,
      }"
      :readonly="isReadonly"
    />
    <component
      :is="instance.getFormDate()"
      v-if="isDateVisible"
      ref="dateFieldRef"
      v-bind="{
        dataRefid: 'transactionFormDate',
        instance,
        label: TRANSACTION_FIELD_LABEL[TRANSACTION_FIELD.DATE],
      }"
      :readonly="isReadonly"
    />
    <TransactionFormLinkPanel v-if="isLinkView" v-bind="{ instance }" />
    <template v-else-if="isTypeFilled">
      <TransactionFormEntry
        v-for="index in entriesCount"
        v-bind="{ instance, index }"
        ref="entryFieldRef"
        :key="index"
        :readonly="isReadonly"
      />
    </template>
    <component
      :is="instance.getFormDescription()"
      v-if="isDescriptionVisible"
      v-bind="{
        dataRefid: 'transactionFormDescription',
        instance,
        label: TRANSACTION_FIELD_LABEL[TRANSACTION_FIELD.DESCRIPTION],
      }"
      :readonly="isReadonly"
    />
    <div class="transaction__buttons">
      <UIButtonClose @click="handleClose" />
      <UIButtonSave
        v-if="!isReadonly"
        data-refid="transactionFormSave"
        v-bind="{
          disabled,
          loading,
        }"
        :label="saveButtonLabel"
        @click="handleSave"
      />
    </div>
    <UIButton
      v-if="isToggleButtonVisible"
      :label="toggleButtonLabel"
      type="button"
      variant="secondary"
      fill="light"
      size="sm"
      tabindex="-2"
      full
      @click="handleClickToggle"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, provide, ref, useTemplateRef } from 'vue'

import { ReadonlyMode } from '@types'
import { TransactionClass } from '..'
import { EntityState } from '@/entities/utils/enums'

import {
  TRANSACTION_ENTRY_FIELD,
  TRANSACTION_FIELD,
  TRANSACTION_FIELD_LABEL,
  TRANSACTION_ID_PREFIX,
} from '../utils/const'
import { ROUTE_NAME } from '@/const'
import {
  NOTIFICATION_DELAY,
  OVERLAY_DURATION,
  READONLY_MODE,
} from '@/const/common'

import { useNotifications } from '@/plugins/notification'

import { useTransactionsBunchStore } from '@/store/transactions/bunch'

import { UIButton, UIButtonClose, UIButtonSave } from '@ui'
import TransactionFormEntry from './TransactionFormEntry.vue'
import TransactionFormLinkPanel from './TransactionFormLinkPanel.vue'
import TransactionFormDate from './TransactionFormDate.vue'

type Props = {
  instance: TransactionClass
  shortened?: boolean
  restrictions?: string[]
  link?: boolean
}

type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { progress, remove, update } = useNotifications()

const transactionsBunchStore = useTransactionsBunchStore()

const loading = ref(false)

const showHidden = ref(false)
const isLinkView = ref(false)

const typeFieldRef = useTemplateRef('typeFieldRef')
const dateFieldRef = useTemplateRef<typeof TransactionFormDate>('dateFieldRef')
const entryFieldRef =
  useTemplateRef<(typeof TransactionFormEntry)[]>('entryFieldRef')

const readonly = inject<ReadonlyMode>(READONLY_MODE)

const isReadonly = computed(
  () => readonly?.value || props.instance.settings?.readonly,
)

const disabled = computed(
  () =>
    ![EntityState.CHANGED, EntityState.NEW].includes(props.instance.state) &&
    (!isLinkView.value || !isTypeFilled.value),
)

const isSavedTransaction = computed(() =>
  props.instance.id.startsWith(TRANSACTION_ID_PREFIX),
)
provide('isSavedTransaction', isSavedTransaction)

const hidden = computed<string[]>(() => {
  if (!props.shortened) return []
  const result: string[] = [TRANSACTION_FIELD.TYPE]
  if (!props.instance.emptyAccountId1) {
    result.push(`${TRANSACTION_ENTRY_FIELD.ACCOUNT_ID}1`)
  }
  if (!props.instance.emptyAccountId2) {
    result.push(`${TRANSACTION_ENTRY_FIELD.ACCOUNT_ID}2`)
  }
  if (!props.instance.emptyAmount1) {
    result.push(`${TRANSACTION_ENTRY_FIELD.AMOUNT}1`)
  }
  if (!props.instance.emptyAssetId1) {
    result.push(`${TRANSACTION_ENTRY_FIELD.ASSET_ID}1`)
  }
  if (!props.instance.emptyAssetId2) {
    result.push(`${TRANSACTION_ENTRY_FIELD.ASSET_ID}2`)
  }
  return result
})
provide('hidden', hidden)
provide('showHidden', showHidden)

const saveButtonLabel = computed(() =>
  isLinkView.value ? 'Create' : undefined,
)

const toggleButtonLabel = computed(
  () => `${showHidden.value ? 'Hide' : 'Show'} pre-filled fields`,
)
const isToggleButtonVisible = computed(() => !!hidden.value?.length)

const isTypeVisible = computed(
  () => showHidden.value || !hidden.value.includes(TRANSACTION_FIELD.TYPE),
)
const isDateVisible = computed(
  () => showHidden.value || !hidden.value.includes(TRANSACTION_FIELD.DATE),
)
const isTypeFilled = computed(
  () => props.instance.field(TRANSACTION_FIELD.TYPE).value,
)
const isDescriptionVisible = computed(
  () =>
    isTypeFilled.value &&
    (showHidden.value || !hidden.value.includes(TRANSACTION_FIELD.DESCRIPTION)),
)

const entriesCount = computed(() => {
  const count = props.instance.settings?.entries.length
  return count && count > 4 ? 4 : count
})

const handleClickToggle = () => {
  showHidden.value = !showHidden.value
}

const handleClose = () => {
  emit('close')
}

const handleSave = async () => {
  if (isLinkView.value) {
    isLinkView.value = false
    return
  }
  loading.value = true
  const isNew = props.instance.isNew
  const nid = await progress({
    message: `${isNew ? 'Creating' : 'Updating'} transaction`,
  })
  try {
    if (isNew) {
      await props.instance.store()
    } else {
      await props.instance.update()
    }
    await update(
      nid,
      {
        type: 'success',
        message: `Transaction ${
          props.instance.field(TRANSACTION_FIELD.DATE).value
        } ${isNew ? 'created' : 'updated'}`,
        ...(transactionsBunchStore.getList.has(props.instance.id)
          ? {
              link: {
                text: 'Open',
                to: {
                  name: ROUTE_NAME.TRANSACTIONS_ITEM,
                  params: { id: props.instance.id },
                },
              },
            }
          : {}),
      },
      NOTIFICATION_DELAY,
    )
    emit('close')
  } catch {
    await remove(nid)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  isLinkView.value = props.link
  setTimeout(() => {
    if (!isTypeFilled.value) {
      typeFieldRef.value?.focus()
      return
    }

    if (!props.instance.field(TRANSACTION_FIELD.DATE).value) {
      dateFieldRef.value?.select()
      return
    }

    for (let i = 0; i < props.instance.entries.length && i < 2; i++) {
      if (
        !props.instance.entries[i]?.account_id ||
        !props.instance.entries[i]?.amount ||
        !props.instance.entries[i]?.asset_id
      ) {
        entryFieldRef.value?.[i]?.focus()
        break
      }
    }
  }, OVERLAY_DURATION)
})
</script>

<script lang="ts">
export default {
  name: 'TransactionForm',
}
</script>

<style scoped>
@import url('./styles/transaction.css');
</style>
