<template>
  <SelectRepository>
    <template
      #default="{ items, isActionsAllowed, handleClickItem, repositoryId }"
    >
      <div class="navbar-repositories">
        <div class="navbar-repositories__header">
          <div class="navbar-repositories__title">Repositories</div>
          <SelectRepositoryActions
            :disabled="!isActionsAllowed"
            size="xs"
            @click="handleClose"
          />
        </div>
        <div class="navbar-repositories__items">
          <SelectRepositoryItem
            v-for="item in items"
            :key="item.id"
            :item
            :active="item.id === repositoryId"
            @delete="handleDelete"
            @click="handleClickItem(item)"
          />
        </div>
        <SelectRepositoryLimit v-if="!isActionsAllowed" />
        <SelectRepositoryDelete
          v-if="deleteRepository"
          :repository="deleteRepository"
          @close="handleCancelDelete"
        />
      </div>
    </template>
  </SelectRepository>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { Repository } from '@types'

import SelectRepository from './SelectRepository.vue'
import SelectRepositoryActions from './SelectRepositoryActions.vue'
import SelectRepositoryDelete from './SelectRepositoryDelete.vue'
import SelectRepositoryItem from './SelectRepositoryItem.vue'
import SelectRepositoryLimit from './SelectRepositoryLimit.vue'

const deleteRepository = ref<Repository>()

const handleDelete = (item: Repository) => {
  deleteRepository.value = item
}

const handleCancelDelete = () => {
  deleteRepository.value = undefined
}

const handleClose = () => {}
</script>

<style>
.navbar-repositories {
  @apply flex flex-col gap-2;

  &__header {
    @apply h-12;
    @apply flex items-center justify-between;
    @apply gap-4 pr-2.5 pl-4;
  }

  &__title {
    @apply text-common-text;
    @apply text-gray-400 dark:text-gray-500;
    @apply text-xs font-medium;
    @apply tracking-wider;
    @apply uppercase;
  }

  &__items {
    @apply flex flex-col gap-1;
    @apply px-1;
  }
}
</style>
