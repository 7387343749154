<template>
  <div class="skeleton-chart-combo">
    <div class="skeleton-chart-combo__col" :class="colClasses">
      <div class="skeleton-chart-combo__col__value" />
    </div>
    <div class="skeleton-chart-combo__col" :class="colClasses">
      <div class="skeleton-chart-combo__col__value" />
    </div>
    <div class="skeleton-chart-combo__col" :class="colClasses">
      <div class="skeleton-chart-combo__col__value" />
    </div>
    <div class="skeleton-chart-combo__col" :class="colClasses">
      <div class="skeleton-chart-combo__col__value" />
    </div>
    <div class="skeleton-chart-combo__col" :class="colClasses">
      <div class="skeleton-chart-combo__col__value" />
      <div class="skeleton-chart-combo__col__value" />
    </div>
    <div class="skeleton-chart-combo__line" />
    <div v-if="!static" class="skeleton-chart-combo__message">
      {{ message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Props = {
  message?: string
  static?: boolean
}

const { message = 'Chart is loading...', static: isStatic } =
  defineProps<Props>()

const colClasses = computed(() => ({
  'skeleton-chart-combo__col--static': isStatic,
}))
</script>

<script lang="ts">
export default {
  name: 'UISkeletonChartCombo',
}
</script>

<style lang="postcss">
.skeleton-chart-combo {
  @apply relative;
  @apply flex justify-between;
  @apply w-[80%] h-[16.25rem];
  @apply ml-auto;
  @apply mb-10 px-3;

  &__col {
    @apply w-[18%] max-w-[3rem];
    @apply relative;
    @apply animate-pulse;
    @apply second:animation-delay-200;
    @apply third:animation-delay-400;
    @apply fourth:animation-delay-600;
    @apply fifth:animation-delay-800;

    &--static {
      @apply animate-none;
    }

    &__value {
      @apply absolute inset-0 bottom-[40%];
      @apply bg-gray-300 dark:bg-gray-700;
    }

    &:nth-child(2) &__value {
      @apply top-10;
    }

    &:nth-child(3) &__value {
      @apply top-5;
    }

    &:nth-child(4) &__value {
      @apply top-[60%] bottom-10;
    }

    &:nth-child(5) &__value {
      @apply second:bg-gray-200 dark:second:bg-gray-800;
      @apply first:bottom-[40%] first:top-[45%];
      @apply second:top-[60%] second:bottom-5;
    }
  }

  &__line {
    @apply h-[1px];
    @apply absolute inset-x-0 top-[60%];
    @apply bg-gray-400 dark:bg-gray-600;
  }

  &__message {
    @apply absolute top-1/2 left-1/2;
    @apply -translate-x-1/2 -translate-y-1/2;
    @apply text-xs;
    @apply text-gray-400 dark:text-gray-500;
    @apply text-center;
  }
}
</style>
