<template>
  <div class="more-section">
    <div class="more-section__caption">
      <span>{{ caption }}</span>
      <slot name="caption" v-bind="{ caption }" />
    </div>
    <slot>
      <div class="more-section__items">
        <MoreSectionLink
          v-for="item in items"
          :key="item.label"
          v-bind="{ item }"
          @click="handleClick"
        />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { NavigationItem } from '@types'

import MoreSectionLink from './MoreSectionLink.vue'

type Props = {
  caption?: string
  items?: NavigationItem[]
}

type Emits = {
  click: []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const handleClick = () => {
  emit('click')
}
</script>

<style>
.more-section {
  @apply flex flex-col;
  @apply pt-0;

  &__caption {
    @apply h-10;
    @apply sticky top-0;
    @apply flex items-center justify-between;
    @apply px-3 -mx-2.5;
    @apply rounded;
    @apply bg-gray-50 dark:bg-gray-850;
    @apply text-gray-500 dark:text-gray-400;
    @apply text-xs uppercase font-medium;
  }

  &__items {
    @apply !grid grid-cols-3;
    @apply !gap-4 !py-5 !px-0;
  }

  &__item {
    @apply flex flex-col items-center;
    @apply gap-1;
    @apply cursor-pointer;
  }

  &__item-icon,
  &__item-symbol {
    @apply h-9 w-9;
    @apply shrink-0;
    @apply text-gray-400 group-hover:text-gray-500;
    @apply dark:text-gray-500;

    &--disabled {
      @apply opacity-50;
    }

    &--active {
      @apply !text-indigo-500 dark:!text-indigo-300;
      @apply !opacity-90;
    }

    &--active:not(&--disabled) {
      @apply !opacity-100;
    }
  }

  &__item-symbol {
    @apply flex items-center justify-center;
    @apply border-2;
    @apply rounded-full;
    @apply border-gray-400 group-hover:border-gray-500;
    @apply dark:border-gray-500 group-hover:dark:border-gray-400;
    @apply font-medium;
    @apply uppercase;

    &--disabled {
      @apply opacity-50;
    }

    &--active {
      @apply !border-indigo-500 dark:!border-indigo-300;
      @apply !opacity-90;
    }

    &--active:not(&--disabled) {
      @apply !opacity-100;
    }
  }

  &__item-text {
    @apply line-clamp-3;
    @apply text-gray-900 dark:text-gray-300;
    @apply text-xs font-medium;
    @apply text-center;

    &--disabled {
      @apply opacity-50;
    }

    &--active {
      @apply text-primary-active dark:text-indigo-300;
      @apply !opacity-90;
    }

    &--active:not(&--disabled) {
      @apply !opacity-100;
    }
  }
}
</style>
