<template>
  <AssetsDeleted v-bind="{ columns, items, modal, plural: 'assets' }" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { FilterComparison, ModalClass } from '@types'

import { ASSET_FIELD, ASSET_FIELD_LABEL } from '@/entities/assets/utils/const'

import { orderedList } from '@/helpers/common'

import useFiltersService from '@/services/filters'

import { useAssetsStore } from '@/store/assets'
import { useAssetsStoreV2 } from '@/store/assets/index'
import { useRepositoriesStore } from '@/store/repositories'

import AssetsDeleted from '@/modules/modals/AssetsDeleted.vue'

type Props = {
  modal: ModalClass<any>
}
defineProps<Props>()

const assetsStore = useAssetsStore()
const assetsStoreV2 = useAssetsStoreV2()
const repositoriesStore = useRepositoriesStore()

const { fetchOptions } = useFiltersService()

const assetsTypes = computed(() => orderedList(assetsStore.getAssetsTypes))
const currenciesList = computed(() =>
  orderedList(repositoriesStore.currenciesList, 'ticker', 'ticker'),
)

const columns = computed(() => [
  {
    name: ASSET_FIELD.TYPE,
    caption: ASSET_FIELD_LABEL[ASSET_FIELD.TYPE],
    filter: {
      isSimple: true,
      defaultComparison: FilterComparison.EQ,
      list: assetsTypes.value,
    },
  },
  {
    name: ASSET_FIELD.NAME,
    caption: ASSET_FIELD_LABEL[ASSET_FIELD.NAME],
    filter: {
      getter: () => fetchOptions('Assets', ASSET_FIELD.NAME),
    },
  },
  {
    name: ASSET_FIELD.TICKER,
    caption: ASSET_FIELD_LABEL[ASSET_FIELD.TICKER],
    filter: {
      getter: () => fetchOptions('Assets', ASSET_FIELD.TICKER),
    },
  },
  {
    name: ASSET_FIELD.CURRENCY,
    caption: ASSET_FIELD_LABEL[ASSET_FIELD.CURRENCY],
    filter: {
      isSimple: true,
      defaultComparison: FilterComparison.EQ,
      list: currenciesList.value,
    },
  },
  {
    name: ASSET_FIELD.DESCRIPTION,
    caption: ASSET_FIELD_LABEL[ASSET_FIELD.DESCRIPTION],
  },
])

const items = computed(() => assetsStoreV2.getCommonDeleted)
</script>

<script lang="ts">
export default {
  name: 'AssetsTrashPopup',
}
</script>
