import { get, omit, set } from 'lodash'
import { AnalyticsTree, AnalyticsPreparedTree } from './types'

export const prepareTree = (data?: AnalyticsTree[]) => {
  if (!data?.length) return
  const result: AnalyticsPreparedTree = {}
  for (let i = 0; i < data.length; i++) {
    const { path } = data[i]
    set(result, path, { ...data[i] })
  }
  return result
}

export const getTreeFilterableNodes = (
  data?: AnalyticsTree[],
  tree?: AnalyticsPreparedTree,
) => {
  if (!data?.length) return []
  const result = data.reduce((acc, item) => {
    const node = get(tree, item.path)
    const children = omit(node, 'path', 'amount', 'balance')
    const isLeaf = !Object.keys(children).length
    if (!isLeaf) {
      acc.push(item.path)
    }
    return acc
  }, [] as string[][])
  return result
}
