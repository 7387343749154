import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { AnalyticsTree } from './utils/types'

import { getTreeFilterableNodes, prepareTree } from './utils/helpers'

import useAnalyticsStore from '../index'

export default defineStore('analytics-tree', () => {
  // INIT
  const analyticsStore = useAnalyticsStore()

  const list = ref<AnalyticsTree[]>([])

  const loading = ref(false)
  const deprecated = ref<boolean>()

  // GETTERS

  const isInit = computed(() => deprecated.value !== undefined)

  const getList = computed(() => {
    if (analyticsStore.getError) return
    return list.value
  })

  const getPreparedData = computed(() => prepareTree(getList.value))

  const getFilterableNodes = computed(() =>
    getTreeFilterableNodes(getList.value, getPreparedData.value),
  )

  // SETTERS

  const setList = (data: { tree_view: AnalyticsTree[] }) => {
    list.value = data.tree_view
    deprecated.value = false
  }

  // ACTIONS

  const clear = () => {
    list.value = []
    loading.value = false
    deprecated.value = undefined
  }

  return {
    isLoading: loading,
    isDeprecated: deprecated,

    isInit,
    getList,
    getFilterableNodes,
    getPreparedData,

    setList,

    clear,
  }
})
