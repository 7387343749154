<template>
  <div class="ui-grid__skeleton-value" :class="mainClasses" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

const SIZES = ['sm', 'md', 'lg']

type Props = {
  dark?: boolean
  square?: boolean
  static?: boolean
}

const props = defineProps<Props>()

defineOptions({
  name: 'GridSkeletonValue',
})

const index = Math.floor(Math.random() * SIZES.length)

const mainClasses = computed(() => {
  return {
    [`ui-grid__skeleton-value--${SIZES[index]}`]: !props.square,
    'ui-grid__skeleton-value--dark': props.dark,
    'ui-grid__skeleton-value--square': props.square,
    'ui-grid__skeleton-value--static': props.static,
  }
})
</script>

<style>
.ui-grid__skeleton-value {
  @apply h-4;
  @apply inline-block align-middle;
  @apply my-1;
  @apply bg-body-dark;
  @apply rounded;
  @apply animate-pulse;
  @apply dark:!bg-opacity-40;

  .ui-grid--collapsed & {
    @apply -my-1;
  }

  &--dark {
    @apply bg-common-border;
  }

  &--square {
    @apply w-4;
  }

  &--static {
    @apply animate-none;
  }

  &--sm {
    @apply w-1/2;
  }

  &--md {
    @apply w-2/3;
  }

  &--lg {
    @apply w-full;
  }
}
</style>
