import { Ref } from 'vue'

const pushItem = (array: number[], index: number) => {
  const itemIndex = array.findIndex(item => item === index)
  if (itemIndex !== -1) return
  array.push(index)
}

const pullItem = (array: number[], index: number) => {
  const itemIndex = array.findIndex(item => item === index)
  if (itemIndex === -1) return
  array.splice(itemIndex, 1)
}

export const toggleItem = (
  value: boolean | undefined,
  index: number,
  completed?: Ref<number[]>,
  skipped?: Ref<number[]>,
) => {
  if (value === false) {
    skipped && pushItem(skipped.value, index)
    completed && pullItem(completed.value, index)
  } else if (value) {
    completed && pushItem(completed.value, index)
    skipped && pullItem(skipped.value, index)
  }
}
