<template>
  <UILayoutNoData v-bind="mainProps" @click:help="handleClickHelp">
    <template #text-description>{{ caption }}</template>
    <template v-if="!isReadonly" #default>
      <UIButtonSync
        :leading="PlusIcon"
        v-bind="{ disabled }"
        label="Connect"
        :loading="isSyncing"
        full
        @click="handleClickAdd"
      />
    </template>
    <template #text-question>What is a connectors?</template>
  </UILayoutNoData>
</template>

<script setup lang="ts">
import { computed, inject, markRaw } from 'vue'

import { ReadonlyMode } from '@types'
import { READONLY_MODE } from '@/const'

import { useIntercom } from '@/plugins/intercom'
import { useLinkedDataConnector } from '@/views/LinkedData/hooks/useLinkedDataConnector'

import { useModalsStore } from '@/store/modals'

import { useTasksService } from '@/services/tasks'

import { UIButtonSync, UILayoutNoData } from '@ui'
import { PlusIcon } from '@heroicons/vue/24/outline'
import AddAccount from '@/views/LinkedData/Connectors/components/AddAccount.vue'

const tasksService = useTasksService()

const modalsStore = useModalsStore()

const { loading, syncing } = useLinkedDataConnector()
const intercom = useIntercom()

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const disabled = computed(() => syncing.value || loading.value)

const mainProps = computed(() =>
  isReadonly?.value ? { inline: true, hideQuestionIcon: true } : undefined,
)

const caption = computed(() =>
  isReadonly?.value
    ? 'No accounts connected with current repository'
    : 'Get started by connecting accounts',
)

const isSyncing = computed(
  () => syncing.value || tasksService.isLinkedDataSyncing,
)

const handleClickAdd = () => {
  const modalInstance = modalsStore.init('add-account', markRaw(AddAccount))
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClickHelp = () => {
  intercom.help(5480491)
}
</script>
