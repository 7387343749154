<template>
  <SelectRepository>
    <template #default="{ postfixIcon, repositoryName }">
      <component
        :is="isNavbarOpened ? UIButton : UIButtonIcon"
        v-tooltip.right="
          isRepositoryOpened || isNavbarOpened ? undefined : repositoryName
        "
        data-refid="navbarRepository"
        label="Repos"
        :leading="RectangleStackIcon"
        :trailing="postfixIcon"
        :variant="isRepositoryOpened ? 'secondary' : 'gray'"
        :fill="isRepositoryOpened ? undefined : 'outlined'"
        size="sm"
        class="navbar-item"
        :class="{ 'navbar-item--active': isRepositoryOpened }"
        @click="handleToggle"
      >
        <template v-if="postfixIcon && !isNavbarOpened" #trailing>
          <component
            :is="postfixIcon"
            class="navbar-item__status"
            aria-hidden="true"
          />
        </template>
      </component>
    </template>
  </SelectRepository>
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { OpenedFlag } from './utils/type'

import { NAVBAR_OPENED, REPOSITORIES_OPENED, TREE_OPENED } from './utils/const'

import SelectRepository from './components/SelectRepository.vue'
import { RectangleStackIcon } from '@heroicons/vue/24/outline'
import { UIButton, UIButtonIcon } from '@ui'

const isNavbarOpened = inject<OpenedFlag>(NAVBAR_OPENED)
const isRepositoryOpened = inject<OpenedFlag>(REPOSITORIES_OPENED)
const isTreeOpened = inject<OpenedFlag>(TREE_OPENED)

const handleToggle = () => {
  isNavbarOpened && (isNavbarOpened.value = false)
  isTreeOpened && (isTreeOpened.value = false)
  isRepositoryOpened && (isRepositoryOpened.value = !isRepositoryOpened.value)
}
</script>
