import { Router } from 'vue-router'
import { h } from 'vue'
import { sortedIndexBy } from 'lodash'

import { useNotifications } from '@/plugins/notification'

import { EntityState } from '@/entities/utils/enums'

import { UNKNOWN_VALUE } from '@/const/common'

const findItemIndex = <T extends Record<string, any>>(
  storeList: T[],
  value: string | undefined,
  keyField = 'id' as keyof T,
) => storeList.findIndex(item => item[keyField] === value)

export const addStoreListItem = <T extends Record<string, any>>(
  storeList: T[],
  processingItem: T,
  sortedField?: keyof T,
  formatter?: (value: any) => any,
) => {
  if (sortedField) {
    const insertItemToIndex = sortedIndexBy(
      storeList,
      { [sortedField]: processingItem[sortedField] } as any,
      item => (formatter ? formatter(item[sortedField]) : item[sortedField]),
    )
    storeList.splice(insertItemToIndex, 0, processingItem)
  } else {
    storeList.push(processingItem)
  }
}

export const updateStoreListItem = <T extends Record<string, any>>(
  storeList: T[],
  processingItem: Partial<T>,
  keyField = 'id' as keyof T,
) => {
  const currentItemIndex = findItemIndex<T>(
    storeList,
    processingItem[keyField],
    keyField,
  )
  if (currentItemIndex === -1) return
  const keys = Object.keys(processingItem).filter(key => key !== keyField)
  keys.forEach(key => {
    // @ts-ignore
    storeList[currentItemIndex][key] = processingItem[key]
  })
}

export const removeStoreListItem = <T extends Record<string, any>>(
  storeList: T[],
  value: string,
  keyField = 'id' as keyof T,
) => {
  const currentItemIndex = findItemIndex<T>(storeList, value, keyField)
  storeList.splice(currentItemIndex, 1)
}

export const prepareResponseError = (e: any) =>
  e.message ? e.message : String(e)

const isDirtyState = (data: EntityState) => {
  return [
    EntityState.CHANGED,
    EntityState.DELETED,
    EntityState.NEW,
    EntityState.INVALID,
  ].includes(data)
}

export const isDirtyList = <T extends { state: EntityState }>(
  data: Map<string, T>,
) => {
  for (const value of data.values()) {
    if (isDirtyState(value.state)) return true
  }
  return false
}

export const createLink = (
  id: string,
  name: string,
  router: Router,
  routeName: string,
) => {
  return h(
    'span',
    {
      class: 'text-indigo-500 hover:text-indigo-600 cursor-pointer',
      onClick: () => {
        router.push({
          name: routeName,
          params: { id },
        })
      },
    },
    name || UNKNOWN_VALUE,
  )
}

export const unsavedGridBlocker =
  (gridName: string, stayCallback: () => void) => () =>
    new Promise<boolean>((resolve, reject) => {
      const { warn } = useNotifications()
      warn({
        message: 'Warning',
        description: `You have unsaved changes in the ${gridName} grid, do you want to continue editing?`,
        actionRequired: true,
        actions: [
          {
            label: 'Continue editing',
            buttonVariant: 'primary',
            onClick: remove => {
              remove && remove()
              stayCallback()
              reject()
            },
          },
          {
            label: 'Discard changes',
            buttonVariant: 'secondary',
            onClick: remove => {
              remove && remove()
              resolve(true)
            },
          },
        ],
      })
    })
