<template>
  <UIDropdown
    v-bind="{ items, disabled }"
    v-model:show="isShown"
    :model-value="currentChild"
    :show-delay="300"
    id-key="key"
    value-key="label"
    placement="left-start"
    popper-class="main-menu-item__dropdown"
    @click:item="handleClick"
  >
    <slot />
    <template v-if="$slots.before" #before>
      <slot name="before" />
    </template>
    <template #item="{ item: child }">
      <MenuItemChild v-bind="{ child }" />
    </template>
  </UIDropdown>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { NavigationItem } from '@types'

import { useNavigationStore } from '@/store/navigation'

import MenuItemChild from './MenuItemChild.vue'
import { UIDropdown } from '@ui'

type Props = {
  item: NavigationItem
  disabled?: boolean
}

const props = defineProps<Props>()

const route = useRoute()
const router = useRouter()

const navigationStore = useNavigationStore()

const isShown = ref(false)

const items = computed(() =>
  props.item.label
    ? navigationStore.getChildNavigation(props.item.label)
    : undefined,
)

const currentChild = computed(() => {
  const current = items.value?.find(
    item =>
      route.name &&
      (item.route === route.name ||
        item.additional?.includes(route.name?.toString())),
  )
  return (
    current && `${current.route}${JSON.stringify(current.routeParams || {})}`
  )
})

const handleClick = (data: NavigationItem) => {
  router.push({ name: data.route, params: data.routeParams })
}
</script>

<style>
.main-menu-item {
  &__dropdown .v-popper__arrow-container {
    @apply block;
  }

  &__dropdown .ui-dropdown__items {
    @apply !max-h-full;
  }

  &__dropdown .ui-dropdown__item {
    @apply items-center;
  }

  &__dropdown .ui-dropdown__item-text {
    @apply flex-auto;
  }
}
</style>
