<template>
  <div class="ui-preloader" :class="mainClasses">
    <PreoaderIcon />
    <span v-if="message">{{ message }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import PreoaderIcon from './PreloaderIcon.vue'

type Props = {
  message?: string
  overlay?: boolean
}

const props = defineProps<Props>()

defineOptions({
  name: 'UIPreloader',
})

const mainClasses = computed(() => ({
  'ui-preloader--overlay': props.overlay,
}))
</script>

<style>
.ui-preloader {
  @apply flex justify-center items-center;
  @apply gap-x-3;
  @apply text-gray-400 dark:text-gray-600;

  &--overlay {
    @apply absolute inset-0;
    @apply bg-white dark:bg-gray-900;
    @apply bg-opacity-75;
    @apply z-30;
  }
}
</style>
