<template>
  <div
    class="ui-grid__footer"
    :data-cell="disabled ? undefined : 'true'"
    :data-footer-column="columnName"
    :class="mainClasses"
  >
    <div class="ui-grid__footer-value" :class="valueClasses">
      <slot
        v-bind="{
          active: false,
          columnName,
          displayValue,
          editCell: false,
          editRow: false,
          item: {} as T,
        }"
      >
        {{ displayValue }}
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends GridFooter">
import { computed, inject } from 'vue'

import { GridColumn, GridFooter } from '../utils/types'

type Props = {
  column?: GridColumn
  footer?: T

  isFirst?: boolean
  isLast?: boolean
}

const props = defineProps<Props>()

defineOptions({
  name: 'GridFooter',
})

const disabled = inject('disabled', false)

const columnName = computed(() => props.column?.name as keyof T)
const displayValue = computed(() => props.footer?.[columnName.value])

const mainClasses = computed(() => ({
  'ui-grid__footer--first': props.isFirst,
  'ui-grid__footer--last': props.isLast,
  [`${props.column?.footerClasses}`]: props.column?.footerClasses,
}))

const valueClasses = computed(() => ({
  [`${props.column?.footerValueClasses}`]: props.column?.footerValueClasses,
}))
</script>

<style>
.ui-grid__footer {
  @apply min-h-[2.45rem];
  @apply sticky;
  @apply bottom-0;
  @apply flex items-center;
  @apply px-2;
  @apply bg-body-dark;
  @apply z-20;

  &--first {
    @apply pl-4;
  }

  &--last {
    @apply pr-4;
  }

  &[data-selected] {
    @apply before:content-[''];
    @apply before:absolute before:inset-[1px];
    @apply before:border;
    @apply before:border-primary-selected dark:before:border-opacity-40;

    &[data-copy] {
      @apply before:border-dashed;
    }
  }

  &-value {
    @apply relative;
    @apply flex-auto;
    @apply text-gray-400 dark:text-gray-500;
    @apply font-medium uppercase;
    @apply tracking-wider;
    @apply text-xs;
    @apply text-ellipsis overflow-x-hidden;
  }
}

.ui-grid:not(.ui-grid--collapsed) {
  .ui-grid__footer {
    &--first {
      @apply pl-6 lg:pl-8;
    }

    &--last {
      @apply pr-6 lg:pr-8;
    }

    &-value--right {
      @apply text-right;
    }
  }
}
</style>
