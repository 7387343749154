<template>
  <AppTimeline :disabled :hide-slider>
    <UIPreloader v-if="isLoading" message="Loading analytics..." />
    <AppBreadcrumb
      v-else-if="!hideBreadcrumb"
      v-bind="{ path, items }"
      @click:item="setPath"
    />
  </AppTimeline>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useAnalyticsStore from '@/store/analytics'

import { UIPreloader } from '@ui'
import { AppBreadcrumb, AppTimeline } from '@app'

type Props = {
  disabled?: boolean
  hideSlider?: boolean
  hideBreadcrumb?: boolean
}

defineProps<Props>()

defineOptions({
  name: 'DashboardTimeline',
})

const analyticsStore = useAnalyticsStore()

const isInit = computed(() => !analyticsStore.module.tree.isInit)

const isLoading = computed(
  () =>
    (analyticsStore.module.tree.isLoading || isInit.value) &&
    !analyticsStore.getError,
)

const items = computed(() => analyticsStore.module.tree.getFilterableNodes)
const path = computed(() => analyticsStore.getPath)

const setPath = (value: string[]) => {
  analyticsStore.setPath(value)
}
</script>
