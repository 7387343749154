<template>
  <template v-if="lockedMode">
    <div class="ui-input-dropdown__edit-item-text">
      <slot>{{ displayValue }}</slot>
    </div>
    <LockClosedIcon
      class="ui-input-dropdown__edit-item-button ui-input-dropdown__edit-item-button--lock"
    />
  </template>
  <template v-else-if="editMode">
    <InputField
      ref="inputRef"
      v-model="modelValue"
      size="small"
      class="ui-input-dropdown__edit-item-input"
      @click.stop
      @click:enter="handleApply"
    />
    <CheckIcon
      class="ui-input-dropdown__edit-item-button ui-input-dropdown__edit-item-button--apply"
      @click.stop="handleApply"
    />
    <XMarkIcon
      class="ui-input-dropdown__edit-item-button ui-input-dropdown__edit-item-button--cancel"
      @click.stop="handleCancel"
    />
  </template>
  <template v-else-if="confirmDeleteMode">
    <div class="ui-input-dropdown__edit-item-delete" @click.stop>
      <template v-if="deleting">
        {{ t('Deleting') }} "{{ displayValue }}"...
      </template>
      <template v-else>
        {{ t('Are you sure you want to delete the') }} "{{ displayValue }}"?
      </template>
    </div>
    <template v-if="!deleting">
      <UIButton
        :label="t('Yes')"
        variant="danger"
        size="xs"
        class="ui-input-dropdown__edit-item-confirm"
        @click.stop="handleConfirmDelete"
      />
      <UIButton
        :label="t('No')"
        variant="secondary"
        size="xs"
        class="ui-input-dropdown__edit-item-confirm"
        @click.stop="handleCancel"
      />
    </template>
  </template>
  <template v-else>
    <div class="ui-input-dropdown__edit-item-text">
      <slot>{{ displayValue }}</slot>
    </div>
    <PencilIcon
      class="ui-input-dropdown__edit-item-button ui-input-dropdown__edit-item-button--edit"
      @click.stop="handleEdit"
    />
    <TrashIcon
      v-if="!disallowDelete"
      class="ui-input-dropdown__edit-item-button ui-input-dropdown__edit-item-button--delete"
      @click.stop="handleDelete"
    />
  </template>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch, useTemplateRef } from 'vue'

import { InputDropdownItem } from '../utils/types'

import { useLocale } from '@/plugins/i18n'

import {
  CheckIcon,
  LockClosedIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import InputField from '../../components/InputField.vue'
import { UIButton } from '@/components/UI'

type Props = {
  item: InputDropdownItem
  valueKey: string
  lockedKey?: string
  disallowDelete?: boolean
}

type Emits = {
  update: [data: InputDropdownItem, value: string]
  delete: [data: InputDropdownItem, callback: () => void]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useLocale('components.UI.Input')

const inputRef = useTemplateRef('inputRef')

const editMode = ref(false)
const confirmDeleteMode = ref(false)

const deleting = ref(false)

const modelValue = ref('')

const displayValue = computed(() => props.item[props.valueKey])

const lockedMode = computed(
  () => props.lockedKey && props.item[props.lockedKey],
)

const handleApply = () => {
  editMode.value = false
  emit('update', props.item, modelValue.value)
}

const handleCancel = () => {
  editMode.value = false
  confirmDeleteMode.value = false
}

const handleEdit = () => {
  editMode.value = true
}

const handleDelete = () => {
  confirmDeleteMode.value = true
}

const handleConfirmDelete = () => {
  deleting.value = true
  emit('delete', props.item, () => {
    deleting.value = false
  })
}

watch(editMode, async value => {
  if (!value) return
  modelValue.value = displayValue.value
  await nextTick()
  inputRef.value?.focus()
})
</script>

<script lang="ts">
export default {
  name: 'InputDropdownEditItem',
}
</script>

<style lang="postcss">
.ui-input-dropdown {
  &__edit-item {
    @apply flex items-center;

    &-text {
      @apply flex-auto;
      @apply text-ellipsis overflow-hidden;
    }

    &-delete {
      @apply flex-auto;
      @apply -m-1.5 -ml-4;
      @apply py-1.5 pl-4 pr-1.5;
      @apply text-xs;
      @apply text-gray-500 dark:text-gray-400;
    }

    &-input {
      @apply flex-auto;
      @apply -my-1.5 -ml-1;
    }

    &-button {
      @apply w-4 h-4;
      @apply shrink-0;

      &--apply {
        @apply text-green-500 hover:text-green-600;
      }

      &--edit {
        @apply text-indigo-500 hover:text-indigo-600;
      }

      &--delete,
      &--cancel {
        @apply text-red-500 hover:text-red-600;
      }

      &--edit,
      &--delete {
        @apply hidden group-hover:block;
      }

      &--lock {
        @apply text-gray-500 dark:text-gray-400;
      }
    }

    &-confirm {
      @apply -my-1.5;
    }
  }
}
</style>
