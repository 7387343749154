<template>
  <UIButton
    ref="buttonRef"
    class="min-w-28"
    label="Close"
    type="button"
    variant="gray"
    fill="outlined"
    tabindex="-1"
  />
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue'

import { UIButton } from '@ui'

const buttonRef = useTemplateRef('buttonRef')

defineExpose({
  focus() {
    buttonRef.value?.focus()
  },
})
</script>
