export const STEPS_LIST = [
  {
    name: 'Investing or Holding Entity',
  },
  {
    name: 'Investment',
  },
  {
    name: 'Valuation',
  },
  {
    name: 'Asset tags',
    description: 'optional',
  },
  {
    name: 'Summary',
  },
]

export const TRANSACTION_TYPE = 'subscribe and fund'

export const ASSET_CLASS_LIST = ['Alternative Investments']

export const INVESTMENT_STRATEGY_LIST = ['Growth & Venture', 'Hedge Funds']
