<template>
  <div class="ui-grid__menu" :class="mainClasses">
    <UIButtonMenu @click="handleClickMenu" @mouseup.stop @mousedown.stop />
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, inject } from 'vue'

import { UIButtonMenu } from '@ui'

type Emits = {
  click: []
}

const emit = defineEmits<Emits>()

const isHovered = inject<Ref<boolean>>('isHovered')
const editable = inject<boolean>('editable')

const mainClasses = computed(() => ({
  'ui-grid__menu--invisible': editable,
  'ui-grid__menu--hovered': isHovered?.value,
}))

const handleClickMenu = (event: MouseEvent) => {
  event.stopImmediatePropagation()
  emit('click')
}
</script>

<style scoped>
.ui-grid__menu {
  @apply absolute right-0.5 -bottom-1.5;
}

.ui-grid:not(.ui-grid--collapsed) {
  .ui-grid__menu {
    @apply flex items-center;
    @apply right-auto left-0 top-1/2 -translate-y-1/2;

    &--invisible {
      @apply invisible;
    }
    @apply cursor-pointer;

    &--hovered {
      @apply visible;
    }
  }
}
</style>
