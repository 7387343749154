<template>
  <component
    :is="isNavbarOpened ? UIButton : UIButtonIcon"
    :label="t('Asset tree')"
    :leading="TreeIcon"
    :variant="isTreeOpened ? 'secondary' : 'gray'"
    :fill="isTreeOpened ? undefined : 'outlined'"
    size="sm"
    class="navbar-item"
    :class="{ 'navbar-item--active': isTreeOpened }"
    @click="handleToggle"
  />
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { OpenedFlag } from './utils/type'

import { NAVBAR_OPENED, REPOSITORIES_OPENED, TREE_OPENED } from './utils/const'

import { useLocale } from '@/plugins/i18n'

import TreeIcon from '@/assets/images/icons/tree.svg'
import { UIButton, UIButtonIcon } from '@ui'

const { t } = useLocale('layouts.Main')

const isNavbarOpened = inject<OpenedFlag>(NAVBAR_OPENED)
const isRepositoryOpened = inject<OpenedFlag>(REPOSITORIES_OPENED)
const isTreeOpened = inject<OpenedFlag>(TREE_OPENED)

const handleToggle = () => {
  isNavbarOpened && (isNavbarOpened.value = false)
  isRepositoryOpened && (isRepositoryOpened.value = false)
  isTreeOpened && (isTreeOpened.value = !isTreeOpened.value)
}
</script>
