<template>
  <div class="notification">
    <div
      v-circular-tab="{ autofocus: 1 }"
      class="notification__item"
      :class="`notification__item--${design.color}`"
    >
      <div class="relative w-0 flex-1 p-4">
        <div class="flex items-start">
          <div class="notification__icon" :class="notificationIconClasses">
            <component :is="design.icon" aria-hidden="true" />
          </div>
          <div class="notification__content">
            <p class="text-sm">
              <span class="notification__title">
                <template v-if="typeof notify.message === 'string'">{{
                  notify.message
                }}</template>
                <component :is="notify.message" v-else />
              </span>
              <router-link
                v-if="notify.link"
                :to="notify.link.to"
                class="notification__link"
                @click="remove(notify.instance)"
              >
                <ArrowUturnRightIcon class="h-3 w-3 mr-1" aria-hidden="true" />
                <span>{{ notify.link.text }}</span>
              </router-link>
            </p>
            <div class="notification__description">
              <template v-if="!notify.errors">{{
                notify.description
              }}</template>
              <template v-else-if="!preloading">
                <component :is="prepareErrors(notify.errors, assetsBunch)" />
              </template>
              <UIPreloader
                v-else
                message="Loading data..."
                class="notification__loader"
              />
            </div>
            <div v-if="hasActions" class="notification__actions">
              <div v-for="action in notify.actions" :key="action.label">
                <UIButton
                  :label="action.label"
                  :variant="action.buttonVariant"
                  :fill="action.buttonFill"
                  :tabindex="0"
                  @click="action.onClick(() => remove(notify.instance))"
                />
              </div>
            </div>
          </div>
          <div v-if="!hasActions">
            <button
              class="notification__close-button"
              data-refid="notificationCloseButton"
              @click="remove(notify.instance)"
            >
              <span class="sr-only">Close</span>
              <XMarkIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
        <transition enter-active-class="animate-bounce">
          <div
            v-if="notify.duplicates_count > 1"
            :key="notify.duplicates_count"
            class="notification__duplicates"
            :class="`notification__duplicates--${design.color}`"
          >
            <div class="notification__duplicates-count">
              {{ notify.duplicates_count }}
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useAssetsStore } from '@/store/assets'
import { useAssetsBunchStore } from '@/store/assets/bunch'

import { MainNotification, useNotifications } from './'

import { prepareErrors } from './business-logic'

import LoaderIcon from '@/components/UI/Preloader/PreloaderIcon.vue'

import { UIButton, UIPreloader } from '@ui'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/vue/24/outline'
import {
  ExclamationCircleIcon as SolidExclamationCircleIcon,
  XMarkIcon,
  ArrowUturnRightIcon,
} from '@heroicons/vue/20/solid'

type Props = {
  notification: MainNotification
}

const props = defineProps<Props>()

const { remove } = useNotifications()
const assetsStore = useAssetsStore()
const assetsBunchStore = useAssetsBunchStore()

const assetsBunch = computed(() => assetsBunchStore.getList)
const preloading = computed(() => !assetsStore.list.length)
const design = computed(() => {
  switch (props.notification.type) {
    case 'error':
      return { color: 'red', icon: SolidExclamationCircleIcon }
    case 'success':
      return { color: 'green', icon: CheckCircleIcon }
    case 'warn':
    case 'timeout':
      return { color: 'yellow', icon: ExclamationCircleIcon }
    case 'progress':
      return { color: 'indigo', icon: LoaderIcon, animated: true }
    default:
      return { color: 'blue', icon: InformationCircleIcon }
  }
})
const notify = computed(() => props.notification)
const hasActions = computed(() => !!notify.value.actions?.length)
const notificationIconClasses = computed(() => [
  `notification__icon--${design.value.color}`,
  design.value.animated === true ? 'notification__icon--animated' : '',
])
</script>

<script lang="ts">
export default {
  name: 'NotificationItem',
}
</script>

<style>
.notification {
  @apply w-full sm:max-w-sm mb-4;

  &__item {
    @apply max-w-md w-full flex;
    @apply shadow-lg pointer-events-auto rounded-lg;
    @apply bg-gray-50 dark:bg-gray-800;
    @apply ring-1 ring-black ring-opacity-5 border-l-4;

    &--red {
      @apply bg-red-50;
      @apply border-red-400 dark:border-red-500;
      .notification__title {
        @apply dark:text-red-500;
      }
    }
    &--green {
      @apply bg-green-50;
      @apply border-green-400 dark:border-green-500;
      .notification__title {
        @apply dark:text-green-500;
      }
    }
    &--yellow {
      @apply bg-yellow-50;
      @apply border-yellow-400 dark:border-yellow-500;
      .notification__title {
        @apply dark:text-yellow-500;
      }
    }
    &--blue {
      @apply bg-blue-50;
      @apply border-blue-400 dark:border-blue-500;
      .notification__title {
        @apply dark:text-blue-500;
      }
    }
    &--indigo {
      @apply bg-indigo-50;
      @apply border-indigo-400 dark:border-indigo-500;
      .notification__title {
        @apply dark:text-indigo-500;
      }
    }
  }

  &__loader {
    @apply justify-start;
  }

  &__icon {
    @apply shrink-0;

    svg {
      @apply h-6 w-6;
    }

    &--red {
      @apply text-red-400 dark:text-red-500;
    }
    &--green {
      @apply text-green-400 dark:text-green-500;
    }
    &--yellow {
      @apply text-yellow-400 dark:text-yellow-500;
    }
    &--blue {
      @apply text-blue-400 dark:text-blue-500;
    }
    &--indigo {
      @apply text-indigo-400 dark:text-indigo-500;
    }

    &--animated {
      @apply animate-spin;
    }
  }

  &__content {
    @apply ml-3 w-full flex-1 pt-0.5;
  }
  &__title {
    @apply font-medium text-gray-900 dark:text-gray-100;
  }
  &__link {
    @apply inline-flex items-center ml-1 leading-none;
    @apply text-indigo-600 decoration-indigo-600;
    @apply dark:text-indigo-400 dark:decoration-indigo-400;
    @apply decoration-1 underline decoration-dashed hover:no-underline;
  }
  &__description {
    @apply mt-1 text-sm;
    @apply text-gray-500 dark:text-gray-200;
  }
  &__actions {
    @apply flex flex-row-reverse gap-x-4;
    @apply mt-4 -mr-4 -mb-4 -ml-13 py-3 pr-6;
    @apply rounded-b-lg;
    @apply bg-gray-50 dark:bg-gray-850;
  }

  &__close-button {
    @apply bg-transparent rounded-md inline-flex;
    @apply text-gray-400 hover:text-gray-500;
    @apply dark:text-gray-300 dark:hover:text-gray-400;
    @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
    @apply focus:ring-indigo-500;
  }

  &__duplicates {
    @apply absolute -right-2 -top-2 p-1 h-6 w-6;
    @apply rounded-2xl text-xs;
    @apply text-white dark:text-gray-900;

    &--red {
      @apply bg-red-400 dark:bg-red-500;
    }
    &--green {
      @apply bg-green-400 dark:bg-green-500;
    }
    &--yellow {
      @apply bg-yellow-400 dark:bg-yellow-500;
    }
    &--blue {
      @apply bg-blue-400 dark:bg-blue-500;
    }
    &--indigo {
      @apply bg-indigo-400 dark:bg-indigo-500;
    }
  }
  &__duplicates-count {
    @apply w-full h-full flex items-center justify-center;
  }
}
</style>
