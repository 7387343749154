<template>
  <div class="wizzard__form">
    <Flows
      :flows="contributions"
      :restrictions="CONTRIBUTION_TYPES"
      @submit="handleSubmit"
      @remove="handleRemove"
    />
  </div>
</template>

<script setup lang="ts">
import { TransactionClass } from '@types'

import { CONTRIBUTION_TYPES } from './utils/const'

import Flows from './components/Flows.vue'

const contributions = defineModel<TransactionClass[]>('contributions', {
  required: true,
})

defineOptions({ inheritAttrs: false })

const handleSubmit = (data: TransactionClass) => {
  contributions.value?.push(data)
}

const handleRemove = (index: number) => {
  contributions.value?.splice(index, 1)
}
</script>
