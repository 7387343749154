<template>
  <div class="select-repository-item-menu" :class="mainClasses" @click.stop>
    <UIButtonMenu
      class="select-repository-item-menu__button"
      :open="isOpen"
      @click="handleClick"
    />
    <div v-if="isOpen" class="select-repository-item-menu__area">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { UIButtonMenu } from '@ui'

defineExpose({
  hide() {
    isOpen.value = false
  },
})

const isOpen = ref(false)

const mainClasses = computed(() => ({
  'ui-grid__actions--opened': isOpen.value,
}))

const handleClick = () => {
  isOpen.value = !isOpen.value
}
</script>

<style scoped>
.select-repository-item-menu {
  @apply relative;
  @apply flex items-center;
  @apply -my-1 gap-2;
  @apply bg-transparent;

  .ui-button-icon {
    @apply pr-0;
  }

  &__area {
    @apply absolute -inset-y-1 right-full;
    @apply flex items-center;
    @apply gap-2;
    @apply bg-body-dark group-hover:bg-body-gray;

    @apply after:content-[''];
    @apply after:w-10;
    @apply after:absolute after:inset-y-0 after:right-full;
    @apply after:bg-gradient-to-l;
    @apply after:from-body-dark;

    @apply group-hover:after:from-body-gray;

    .select-repository-item--active & {
      @apply bg-body-gray;
      @apply after:from-body-gray;
    }

    :deep() .ui-button {
      @apply whitespace-nowrap;
    }
  }
}
</style>
