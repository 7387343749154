<template>
  <div class="asset-drawer-values-holders">
    <div class="asset-drawer__form">
      <UIPanel
        v-model="clearedSearch"
        v-model:storage="searchStorage"
        placeholder="Search holders..."
      />
    </div>
    <UIGridSkeleton
      v-if="isLoading"
      v-bind="{ collapseWidth, columns, footer, sm }"
      message="Loading holders..."
    />
    <UIGrid
      v-else
      v-bind="{ collapseWidth, columns, items, footer, sm }"
      v-model:selected="selected"
      v-model:sort="sort"
      id-field="account_id"
      :selectable="isSelectable"
      scrollable
      display-menu
      @click:menu="handleClickMenu"
    />
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed, inject, markRaw, onMounted, ref } from 'vue'
import { sumBy } from 'lodash'

import { FilterComparison, FilterGroup, FilterLogic, Sort } from '@types'
import { AnalyticsPosition } from '@/store/analytics/position/utils/types'

import { ANALYTICS_REPO_ROOT } from '@/store/analytics/utils/const'

import { amountFormatter, numberFormat } from '@/helpers/numbers'
import { getAssetName, getAssetTicker } from '@/views/Analytics/utils/helpers'

import useGridSort from '@/components/hooks/gridSort'
import useGridFilter from '@/components/hooks/gridFilter'

import useAnalyticsStore from '@/store/analytics'
import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useModalsStore } from '@/store/modals'
import { useRepositoriesStore } from '@/store/repositories'

import { UIGrid, UIGridSkeleton, UIPanel } from '@ui'
import RepositorySlideover from '@/views/Repositories/RepositorySlideover.vue'

type Props = {
  assets: string[]
  static?: boolean
}

const props = defineProps<Props>()

const analyticsStore = useAnalyticsStore()
const assetsBunchStore = useAssetsBunchStore()
const modalsStore = useModalsStore()
const repositoriesStore = useRepositoriesStore()

const currency = inject<ComputedRef<string>>('currency')

const isLoading = ref(true)
const positions = ref<AnalyticsPosition[]>([])

const selected = defineModel<string[]>('selected')
const searchStorage = ref<string>()
const clearedSearch = ref<string>()
const sort = ref<Sort[]>()

const isSelectable = computed(() => positions.value.length > 1 && !props.static)

const assetsBunch = computed(() => assetsBunchStore.getList)

const endOfDate = computed(() => repositoriesStore.getTimelineEnd)

const collapseWidth = 340
const sm = '1fr 1fr'
const columns = computed(() => [
  {
    name: 'account_id',
    caption: 'Account',
    formatter: getAssetName(
      assetsBunch.value,
      repositoriesStore.getCurrentRepositoryName,
    ),
    cellClasses: 'ui-grid__cell--span-2',
  },
  {
    name: 'amount',
    caption: 'Amount',
    formatter: amountFormatter,
    headerValueClasses: 'ui-grid__header-value--right',
    cellValueClasses: 'ui-grid__cell--right blurable-number',
    footerValueClasses: 'ui-grid__footer-value--right blurable-number',
  },
  {
    name: 'asset_id',
    caption: 'Asset',
    formatter: (assetId: string) => getAssetTicker(assetsBunch.value)(assetId),
    tooltip: (field: string, item?: AnalyticsPosition) => {
      const key = field as keyof AnalyticsPosition
      return item?.[key]
        ? getAssetName(assetsBunch.value, '')(item[key].toString())
        : undefined
    },
  },
  {
    name: 'position_close',
    caption: 'Value',
    formatter: (value: number) =>
      numberFormat(value, { currency: currency?.value }),
    headerValueClasses: 'ui-grid__header-value--right',
    cellValueClasses: 'ui-grid__cell--right blurable-number',
    footerValueClasses: 'ui-grid__footer-value--right blurable-number',
  },
])

const footer = computed(() => ({
  account_id: 'Total',
  amount: amountFormatter(sumBy(inputItems.value, 'amount')),
  asset_id: '',
  position_close: numberFormat(sumBy(inputItems.value, 'position_close'), {
    currency: currency?.value,
  }),
}))

const amountFilter = ref<FilterGroup>({
  logic: FilterLogic.AND,
  params: [
    { comparison: FilterComparison.GREATER, field: 'amount', value: '0' },
  ],
})

const inputItems = computed(() => positions.value)
const filteredItems = useGridFilter(
  columns,
  inputItems,
  clearedSearch,
  amountFilter,
)
const items = useGridSort(sort, columns, filteredItems)

const fetchHolders = async () => {
  const { position_view } = await analyticsStore.fetch({
    date: endOfDate.value,
    assets: JSON.stringify(props.assets),
    data: 'position',
  })
  positions.value = position_view
  isLoading.value = false
}

const handleClickMenu = (item: any) => {
  if (item.account_id === ANALYTICS_REPO_ROOT) {
    const repository = repositoriesStore.getCurrentRepository
    if (!repository) return
    const instance = modalsStore.init(
      repository.id,
      markRaw(RepositorySlideover),
    )
    instance?.open(modalsStore.getZIndex(), {
      repository,
      wrapped: true,
    })
  } else {
    const assetInstance = assetsBunch.value.get(item.account_id)
    if (!assetInstance) return
    const modalInstance = modalsStore.init(assetInstance.id, assetInstance)
    modalInstance?.open(modalsStore.getZIndex())
  }
}

onMounted(() => {
  if (!props.assets.length) return
  fetchHolders()
})
</script>

<style>
.asset-drawer-values-holders {
  @apply flex flex-col;
  @apply h-1/2;
  @apply pb-4;
}
</style>
