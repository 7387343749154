<template>
  <UIModal
    v-bind="{ isOpen, zIndex }"
    title="Set Price"
    size="md"
    :type="ModalType.DIALOG"
    @hide="handleClose"
  >
    <div class="assets-set-price__title">{{ assetName }}</div>
    <AssetPricesForm
      v-bind="{ defaultDate }"
      label="Apply"
      :disabled="loading"
      @submit="handleSubmit"
    />
  </UIModal>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { AssetPriceForm, ModalClass, ModalState, ModalType } from '@types'

import { ASSET_FIELD, NOTIFICATION_DELAY } from '@/const'

import { handleCatchedError } from '@/helpers/common'

import { useNotifications } from '@/plugins/notification'

import useAnalyticsStore from '@/store/analytics'
import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useAssetsStore } from '@/store/assets'

import AssetPricesForm from '@/entities/assets/components/AssetPricesForm.vue'
import { UIModal } from '@ui'

type Props = {
  ids: string[]
  modal: ModalClass<any>
}

type Emits = {
  complete: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const analyticsStore = useAnalyticsStore()
const assetsBunchStore = useAssetsBunchStore()
const assetsStore = useAssetsStore()

const { progress, remove, update } = useNotifications()

const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const defaultDate = computed(() => analyticsStore.getDate)

const assetName = computed(() => {
  const asset = assetsBunchStore.getElementById(props.ids[0])
  return asset?.field(ASSET_FIELD.NAME).value
})

const loading = computed(() => assetsStore.loadingAction)

const handleClose = () => {
  props.modal.close()
}

const handleSubmit = async (value: AssetPriceForm) => {
  const nid = await progress({
    message: 'Setting price to selected assets',
  })
  try {
    await Promise.all(
      props.ids.map(asset_id =>
        assetsStore.addAssetPrice(
          {
            ...value,
            asset_id,
          },
          false,
        ),
      ),
    )
    await update(
      nid,
      {
        type: 'success',
        message: `Price set`,
      },
      NOTIFICATION_DELAY,
    )
    emit('complete')
    handleClose()
  } catch (e) {
    await remove(nid)
    handleCatchedError(e as string)
  }
}
</script>

<style>
.assets-set-price__title {
  @apply mb-4 text-sm;
}
</style>
