<template>
  <User>
    <template #default="{ items }">
      <UIDropdown
        :model-value="currentRouteName"
        v-bind="{ items }"
        reverse
        placement="bottom-end"
        id-key="key"
        value-key="label"
        data-refid="headerUser"
      >
        <div class="main-desktop-header-user" :class="mainClasses">
          <div v-if="userEmail" class="main-desktop-header-user__email">
            {{ userEmail }}
          </div>
          <UserCircleIcon
            v-else
            aria-hidden="true"
            class="main-desktop-header-icon"
          />
          <ChevronDownIcon class="main-desktop-header-user__chevron" />
        </div>
        <template #before="{ hide }">
          <AppUserInfo v-bind="{ hide }" />
        </template>
        <template #after>
          <div class="main-desktop-header-user__version">
            {{ appVersion }}
          </div>
        </template>
      </UIDropdown>
    </template>
  </User>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import { useUserStore } from '@/store/user'

import User from './components/User.vue'
import { ChevronDownIcon, UserCircleIcon } from '@heroicons/vue/24/outline'
import { UIDropdown } from '@ui'
import { AppUserInfo } from '@app'

const appVersion = `${__APP_VERSION__ || ''}`

const route = useRoute()

const userStore = useUserStore()

const userEmail = computed(() => userStore.getEmail)

const mainClasses = computed(() => ({
  'main-desktop-header-user--shrink': !userEmail.value,
}))

const currentRouteName = computed(
  () =>
    (route.matched[0]?.name ||
      route.matched[0]?.path?.replace('/', '')) as string,
)
</script>

<style scoped>
.main-desktop-header-user {
  @apply h-8;
  @apply flex items-center;
  @apply shrink-0;
  @apply gap-0.5 pr-1.5 pl-2.5;
  @apply rounded-full;
  @apply bg-primary-dark;
  @apply border border-transparent hover:border-primary-active;
  @apply cursor-pointer;

  &--shrink {
    @apply pl-1;
  }

  &__email {
    @apply text-xs;
    @apply text-indigo-200;
  }

  &__chevron {
    @apply w-3 h-3;
    @apply shrink-0;
    @apply text-indigo-100;
  }

  &__version {
    @apply my-2 px-4;
    @apply text-xs;
    @apply text-light-text;
    @apply text-right;
  }
}
</style>
