<template>
  <div v-bind="$attrs" @click="handleOpen">
    <Bars3Icon class="main-mobile-footer-icon" aria-hidden="true" />
    <span class="main-mobile-footer-caption">{{ t('More') }}</span>
  </div>
  <Teleport to="body">
    <UIModal
      v-bind="{ isOpen }"
      :type="ModalType.SLIDEPANEL"
      :title="t('More')"
      direction="to-top"
      header-class="main-mobile-footer-more__header"
      wrapper-class="main-mobile-footer-more"
      size="md"
      hide-minimise-button
      with-back
      @hide="handleClose"
    >
      <template #title>
        <MainMobileFooterMoreSearch />
      </template>
      <MoreSection
        v-for="section in navSections"
        :key="section.label"
        :caption="section.label"
      >
        <template #caption="{ caption }">
          <AddViewButton
            v-if="caption === DASHBOARD_NAME"
            :hide-label-to-sm="false"
            variant="secondary"
            @click="handleClose"
          />
        </template>
        <MoreSectionNavigation
          :disabled="section.label === DASHBOARD_NAME && isEditMode"
          :parent="section.label"
          @click="handleClose"
        />
      </MoreSection>
      <MoreSection
        caption="Profile & settings"
        :items="settingsItems"
        @click="handleClose"
      />
    </UIModal>
  </Teleport>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { ModalType } from '@types'

import { DASHBOARD_NAME, ROUTE_NAME } from '@/const'
import { SETTINGS_LINKS } from '@/views/Settings/utils/const'

import { useLocale } from '@/plugins/i18n'

import { useDashboardsSettingsStore } from '@/store/settings/dashboards'
import { useNavigationStore } from '@/store/navigation'

import AddViewButton from '@/views/Dashboards/components/AddViewButton.vue'
import MainMobileFooterMoreSearch from './MainMobileFooterMoreSearch.vue'
import MoreSection from './components/MoreSection.vue'
import MoreSectionNavigation from './components/MoreSectionNavigation.vue'
import { Bars3Icon, TicketIcon } from '@heroicons/vue/24/outline'
import { UIModal } from '@ui'

const { t } = useLocale('layouts.Main')

const dashboardSettingsStore = useDashboardsSettingsStore()
const navigationStore = useNavigationStore()

const isOpen = ref(false)

const navSections = computed(() => navigationStore.getParentNavigation)

const settingsItems = computed(() => [
  ...SETTINGS_LINKS,
  {
    icon: TicketIcon,
    label: 'Subscription',
    route: ROUTE_NAME.SUBSCRIPTION,
  },
])

const isEditMode = computed(() => dashboardSettingsStore.getEditMode)

const handleOpen = () => {
  isOpen.value = true
}

const handleClose = () => {
  isOpen.value = false
}

watch(isEditMode, value => {
  if (!value) return
  handleClose()
})
</script>

<style>
.main-mobile-footer-more {
  @apply pb-safe-offset-4;

  &__header {
    @apply gap-2;
  }
}
</style>
