<template>
  <div class="navbar-container">
    <slot />
    <div class="navbar-container__toggler">
      <UIButtonPanel
        v-tooltip.top="tooltip"
        :model-value="true"
        variant="gray"
        @click="handleTogglerClick"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { OpenedFlag } from '../utils/type'

import { REPOSITORIES_OPENED, TREE_OPENED } from '../utils/const'
import { UIButtonPanel } from '@ui'

type Props = {
  tooltip?: string
}

defineProps<Props>()

const isTreeOpened = inject<OpenedFlag>(TREE_OPENED)
const isRepositoriesOpened = inject<OpenedFlag>(REPOSITORIES_OPENED)

const handleTogglerClick = () => {
  isTreeOpened!.value = false
  isRepositoriesOpened!.value = false
}
</script>

<style>
.navbar-container {
  @apply w-[22rem];
  @apply relative;
  @apply flex flex-col shrink-0;
  @apply bg-body-dark;
  @apply border-r border-common-border;

  &__toggler {
    @apply absolute top-2 left-full;
    @apply bg-body-dark;
    @apply border border-l-0 border-common-border;
    @apply rounded-r;
    @apply z-10;

    .ui-button {
      @apply hover:bg-transparent;
    }
  }
}
</style>
