<template>
  <div class="ui-slide-panel" :style="{ zIndex }">
    <TransitionChild
      v-if="withBack"
      as="template"
      enter="ui-slide-panel__back-transition"
      leave="ui-slide-panel__back-transition"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave-from="opacity-100"
      leave-to="opacity-0"
    >
      <div
        class="ui-slide-panel__back"
        :style="{ zIndex }"
        @click="handleClose"
      />
    </TransitionChild>
    <TransitionChild
      v-bind="{ enterFrom, enterTo, leaveFrom, leaveTo }"
      as="template"
      enter="ui-slide-panel__panel-transition"
      leave="ui-slide-panel__panel-transition"
      appear
    >
      <div
        ref="slidepanelRef"
        class="ui-slide-panel__container"
        :class="containerClasses"
        :style="containerStyles"
      >
        <div
          v-if="!isMinimized"
          ref="resizerRef"
          class="ui-slide-panel__resizer"
          @mousedown="mouseDownResizer"
        />
        <SlidePanelBody
          v-show="!isMinimized"
          v-bind="{
            title,
            subTitle,
            hideMinimiseButton,
            headerClass,
            wrapperClass,
          }"
          @click:close="handleClose"
          @click:minimize="handleMinimize"
        >
          <slot />
          <template v-if="$slots.title" #title>
            <slot name="title" />
          </template>
        </SlidePanelBody>
        <SlidePanelMinimized
          v-if="isMinimized"
          v-bind="{ title }"
          @click:close="handleClose"
          @click:unminimize="handleUnminimize"
        />
      </div>
    </TransitionChild>
  </div>
</template>

<script lang="ts" setup>
import { computed, provide, ref, VNode } from 'vue'

import { ModalSize, SlidePanelDirection } from './utils/types'

import {
  DEFAULT_LEFT_MINIMUM_MARGIN,
  DEFAULT_MIN_SLIDER_WIDTH,
} from './utils/const'

import { useCheckDirtyForm } from '@/components/hooks/useCheckDirtyForm'

import { TransitionChild } from '@headlessui/vue'
import SlidePanelBody from './SlidePanelBody.vue'
import SlidePanelMinimized from './SlidePanelMinimized.vue'

type Props = {
  title?: string | VNode
  size?: ModalSize

  subTitle?: string
  hideMinimiseButton?: boolean
  zIndex: number

  headerClass?: string
  wrapperClass?: string

  isMinimized?: boolean

  direction?: SlidePanelDirection

  withBack?: boolean
}

type Emits = {
  hide: []
  minimize: [data: boolean]
}

const { size = 'sm', ...props } = defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({
  name: 'UISlidePanel',
  inheritAttrs: false,
})

const checkDirty = useCheckDirtyForm()
provide('modal-mark-dirty', checkDirty.onDirtyChange(true))

const slidepanelRef = ref<HTMLDivElement>()
const resizerRef = ref<HTMLDivElement>()

const slidepanelWidth = ref(0)

const x = ref(0)
const w = ref(0)

const enterFrom = computed(() => {
  switch (props.direction) {
    case 'to-right':
      return '-translate-x-full'
    case 'to-top':
      return 'translate-y-full'
    case 'to-bottom':
      return '-translate-y-full'
  }
  return 'translate-x-full'
})

const enterTo = computed(() => {
  switch (props.direction) {
    case 'to-right':
      return 'translate-x-0'
    case 'to-top':
    case 'to-bottom':
      return 'translate-y-0'
  }
  return `translate-x-0`
})

const leaveFrom = computed(() => enterTo.value)
const leaveTo = computed(() => enterFrom.value)

const isVerticalDirection = computed(
  () => props.direction && ['to-top', 'to-bottom'].includes(props.direction),
)

const containerClasses = computed(() => {
  const result = [
    `ui-slide-panel__container--${isVerticalDirection.value ? 'vertical' : 'horizontal'}`,
    `ui-slide-panel__container--${props.direction || 'to-left'}`,
  ]
  if (props.withBack) {
    result.push('ui-slide-panel__container--with-back')
  }
  if (props.isMinimized) {
    result.push('ui-slide-panel__container--minimized')
  } else {
    result.push(`ui-slide-panel__container--${size}`)
  }
  return result
})

const containerStyles = computed(() => {
  const result: Record<string, any> = { zIndex: props.zIndex }

  if (slidepanelWidth.value) {
    if (isVerticalDirection.value) {
      result.height = `${slidepanelWidth.value}px`
    } else {
      result.width = `${slidepanelWidth.value}px`
    }
  }

  return result
})

const handleMinimize = () => {
  emit('minimize', true)
}

const handleUnminimize = () => {
  emit('minimize', false)
}

const handleClose = () => {
  emit('hide')
}

const mouseDownResizer = (e: MouseEvent) => {
  e.preventDefault()
  x.value = isVerticalDirection.value ? e.y : e.x
  const rect = slidepanelRef.value?.getBoundingClientRect()
  if (rect) {
    w.value = isVerticalDirection.value ? rect.height : rect.width
  }
  window.addEventListener('mousemove', mouseMoveResizer)
  window.addEventListener('mouseup', mouseUpResizer)
}

const mouseUpResizer = () => {
  window.removeEventListener('mousemove', mouseMoveResizer)
  window.removeEventListener('mouseup', mouseUpResizer)

  if (slidepanelWidth.value < DEFAULT_MIN_SLIDER_WIDTH) {
    handleMinimize()
    slidepanelWidth.value = 0
  }
}

const mouseMoveResizer = (e: MouseEvent) => {
  e.preventDefault()
  const eX = isVerticalDirection.value ? e.y : e.x
  const dx = eX - x.value
  if (w.value - dx >= DEFAULT_MIN_SLIDER_WIDTH) {
    const minWidth = isVerticalDirection.value
      ? window.innerHeight
      : window.innerWidth
    slidepanelWidth.value = Math.min(
      w.value +
        (props.direction && ['to-left', 'to-top'].includes(props.direction)
          ? dx
          : -dx),
      minWidth - DEFAULT_LEFT_MINIMUM_MARGIN,
    )
  }
}
</script>

<style scoped>
.ui-slide-panel {
  &__back-transition {
    @apply transition-opacity ease-linear duration-300;
  }

  &__panel-transition {
    @apply transform transition ease-in-out duration-500;
  }

  &__back {
    @apply fixed inset-0;
    @apply bg-gray-600 bg-opacity-75;
    @apply dark:bg-gray-900 dark:bg-opacity-75;
  }

  &__container {
    @apply min-w-80 md:min-w-0;
    @apply fixed;
    @apply flex inset-0;
    @apply pointer-events-auto;

    &--vertical {
      @apply max-h-full w-screen;
    }

    &--horizontal {
      @apply max-w-full h-screen;
    }

    &--to-top {
      @apply top-auto;
    }

    &--to-bottom {
      @apply bottom-auto;
    }

    &--to-right {
      @apply right-auto;
    }

    &--to-left {
      @apply left-auto;
    }

    &--minimized {
      @apply !min-w-0;
      @apply bg-body dark:bg-body-dark;

      &.ui-slide-panel__container--horizontal {
        @apply !w-12;
      }

      &.ui-slide-panel__container--vertical {
        @apply !h-12;
      }

      &.ui-slide-panel__container--to-top {
        @apply bottom-0;
      }

      &.ui-slide-panel__container--to-bottom {
        @apply top-0;
      }

      &.ui-slide-panel__container--to-left {
        @apply right-0;
      }

      &.ui-slide-panel__container--to-right {
        @apply left-0;
      }
    }

    &--sm {
      @apply w-1/2 sm:w-[28rem];

      &.ui-slide-panel__container--vertical {
        @apply w-auto h-1/2;
      }
    }

    &--md {
      @apply w-2/3 sm:w-[36rem];

      &.ui-slide-panel__container--vertical {
        @apply w-auto h-3/5;
      }
    }

    &--lg {
      @apply w-3/4 sm:w-[56rem];

      &.ui-slide-panel__container--vertical {
        @apply w-auto h-3/4;
      }
    }

    &--full {
      @apply w-[calc(100vw-4rem)] sm:w-[calc(100vw-8rem)];

      &.ui-slide-panel__container--vertical {
        @apply w-auto h-[calc(100vh-8rem)];
      }
    }
  }

  &__resizer {
    @apply absolute;
    @apply hidden sm:flex shrink-0;
    @apply border-dashed border-gray-200 dark:border-gray-700;
    @apply z-10;
  }

  &__container--horizontal &__resizer {
    @apply w-2;
    @apply inset-y-0;
    @apply cursor-col-resize;
  }

  &__container--vertical &__resizer {
    @apply h-2;
    @apply inset-x-0;
    @apply cursor-row-resize;
  }

  &__container--to-top &__resizer {
    @apply top-0;
    @apply border-t;
  }

  &__container--to-bottom &__resizer {
    @apply bottom-0;
    @apply border-b;
  }

  &__container--to-left &__resizer {
    @apply left-0;
    @apply border-l;
  }

  &__container--to-right &__resizer {
    @apply right-0;
    @apply border-r;
  }
}
</style>
