<template>
  <WizzardContainer
    v-if="asset && transaction"
    v-model:visited="visited"
    :model-value="currentStep"
    v-bind="{ completed, skipped, isOpen, zIndex }"
    title="Add Drawdown Fund"
    :steps="STEPS_LIST"
    shrink
    @close="handleClose"
    @update:model-value="handleStepChange"
  >
    <component
      :is="currentComponent"
      ref="currentComponentRef"
      v-bind="{
        hasExistingAccounts,
        account,
        asset,
        transaction,
        prices,
        tags,
        assignTags,
        contributions,
        distributions,
      }"
      v-model:use-existing-account="useExistingAccount"
      v-model:prices="prices"
      @update:tags="tags = $event"
      @update:contributions="contributions = $event"
      @update:distributions="distributions = $event"
    />
    <template #buttons>
      <UIButton
        v-bind="{ disabled }"
        :label="buttonLabel"
        tabindex="11"
        @click="handleButtonClick(currentStep === 3)"
      />
      <UIButton
        v-if="isSkipButtonVisible"
        label="Skip"
        variant="secondary"
        tabindex="10"
        @click="handleButtonClick()"
      />
    </template>
    <template v-if="loading" #loading>
      Please wait. <br />We are creating your Drawdown Fund...
    </template>
  </WizzardContainer>
</template>

<script setup lang="ts">
import { computed, onMounted, provide, ref, useTemplateRef, watch } from 'vue'

import {
  Asset,
  AssetClass,
  AssetPriceForm,
  ModalClass,
  ModalState,
  TagClass,
  TransactionClass,
} from '@types'

import {
  ASSET_CLASS_LIST,
  ASSET_TYPE_LIST,
  INVESTMENT_STRATEGY_LIST,
  STEPS_LIST,
  TRANSACTION_TYPE,
} from './utils/const'
import { ASSET_CLASS, INVESTMENT_STRATEGY } from '../utils/const'
import { ASSET_FIELD, NOTIFICATION_DELAY } from '@/const'

import { getCurrencySymbol } from '@/helpers/common'
import { getAssetListValues } from '@/entities/transactions/components/utils/helpers'
import { toggleItem } from '../utils/helpers'
import { getCurrentDate } from '@/helpers/dates'

import { useNotifications } from '@/plugins/notification'

import useAnalyticsStore from '@/store/analytics'
import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useAssetsStore } from '@/store/assets'
import { useRepositoriesStore } from '@/store/repositories'
import { useTagsBunchStore } from '@/store/tags/bunch'
import { useTransactionsSettingsStore } from '@/store/transactions/settings'

import { UIButton } from '@ui'
import DrawdownFundStep1 from '../modules/StepAccount.vue'
import DrawdownFundStep2 from '../modules/StepInvestment.vue'
import DrawdownFundStep3 from '../modules/StepValues.vue'
import DrawdownFundStep4 from '../modules/StepTags.vue'
import DrawdownFundStep5 from './DrawdownFundContributions.vue'
import DrawdownFundStep6 from './DrawdownFundDistributions.vue'
import DrawdownFundStep7 from './DrawdownFundSummary.vue'
import WizzardContainer from '../components/WizzardContainer.vue'

type Props = {
  modal: ModalClass<any>
}

const props = defineProps<Props>()

defineOptions({
  components: {
    DrawdownFundStep1,
    DrawdownFundStep2,
    DrawdownFundStep3,
    DrawdownFundStep4,
    DrawdownFundStep5,
    DrawdownFundStep6,
    DrawdownFundStep7,
  },
})

const { error, success } = useNotifications()

const analyticsStore = useAnalyticsStore()
const assetsStore = useAssetsStore()
const assetsBunchStore = useAssetsBunchStore()
const repositoriesStore = useRepositoriesStore()
const tagsBunchStore = useTagsBunchStore()
const transactionsSettingsStore = useTransactionsSettingsStore()

const currentComponentRef = useTemplateRef<any>('currentComponentRef')

provide('assetRestrictions', ASSET_TYPE_LIST)
provide('assetCostLabel', 'Commitment')
provide('tagAssetClassList', ASSET_CLASS_LIST)
provide('tagInvestmentStrategyList', INVESTMENT_STRATEGY_LIST)

const currentStep = ref(0)
const loading = ref(false)

const useExistingAccount = ref(true)

const account = ref<AssetClass>()
const asset = ref<AssetClass>()
const transaction = ref<TransactionClass>()
const prices = ref<AssetPriceForm[]>([])
const tags = ref({
  [ASSET_CLASS]: ASSET_CLASS_LIST[0],
  [INVESTMENT_STRATEGY]: INVESTMENT_STRATEGY_LIST[0],
})
const assignTags = ref<boolean>()
const contributions = ref<TransactionClass[]>([])
const distributions = ref<TransactionClass[]>([])

const visited = ref<number[]>([])
const completed = ref<number[]>([])
const skipped = ref<number[]>([])

const currentComponent = computed(
  () => `DrawdownFundStep${currentStep.value + 1}`,
)

const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const defaultDate = computed(() => analyticsStore.getDate || getCurrentDate({}))
provide('defaultDate', defaultDate)

const buttonLabel = computed(() => {
  const length = STEPS_LIST.length - 1
  switch (currentStep.value) {
    case 3:
      return 'Assign'
    case length:
      return 'Create fund'
    default:
      return 'Next step'
  }
})
const isSkipButtonVisible = computed(() => currentStep.value === 3)

const accountId = computed(() => transaction.value?.entries[0].account_id)

const isValidAccount = computed(() => {
  return (
    visited.value.includes(0) &&
    ((useExistingAccount.value && accountId.value) || account.value?.isValid)
  )
})

const isValidAsset = computed(() => {
  return visited.value.includes(1) && asset.value?.isValid
})

const isValidTransaction = computed(() => {
  if (!transaction.value) return false
  const keys = Object.keys(transaction.value.errors)
  return (
    visited.value.includes(1) &&
    (transaction.value.isValid ||
      keys.every(key =>
        [
          'entries[0].account_id',
          'entries[0].asset_id',
          'entries[1].asset_id',
        ].includes(key),
      ))
  )
})

const disabled = computed(() => {
  switch (currentStep.value) {
    case 0:
      return !isValidAccount.value
    case 1:
      return !isValidAsset.value || !isValidTransaction.value
  }
  return (
    !isValidAsset.value || !isValidAccount.value || !isValidTransaction.value
  )
})

const repositoryId = computed(() => repositoriesStore.getCurrentRepository?.id)
const currency = computed(() => repositoriesStore.getCurrentRepositoryCurrency)

const assetCurrency = computed(
  () =>
    asset.value?.field<string>(ASSET_FIELD.CURRENCY).value || currency.value,
)
const currencySymbol = computed(() => getCurrencySymbol(assetCurrency.value))
provide('currency', currencySymbol)

const hasExistingAccounts = computed(() => {
  if (!transaction.value?.settings) return
  const data = Array.from(assetsBunchStore.getList.values()).map(instance =>
    instance.get(),
  )

  return !!getAssetListValues(
    transaction.value.settings.entries[0].account,
    data,
  )?.length
})

const handleClose = () => {
  props.modal.close()
}

const handleCreate = async () => {
  if (!asset.value || !transaction.value) return
  loading.value = true
  try {
    const createAccount = !useExistingAccount.value && account.value
    if (createAccount) {
      await account.value?.store()
      account.value &&
        transaction.value.pasteAccountId1(account.value.id, false)
    }
    if (assignTags.value) {
      const tagsIds = []
      for await (const [tag_name, tag_value] of Object.entries(tags.value)) {
        if (!tag_value) return
        const tagInstance = tagsBunchStore.getElementByPair(tag_name, tag_value)
        let tagId
        if (tagInstance?.id) {
          tagId = tagInstance?.id
        } else {
          const tag = new TagClass({ tag_name, tag_value })
          await tag.store()
          tagId = tag.id
        }
        tagsIds.push(tagId)
      }
      if (tagsIds.length) {
        asset.value.field<string[]>(ASSET_FIELD.TAGS).value = tagsIds
      }
    }
    await asset.value.store()
    const currencyAsset = asset.value?.field<Asset | undefined>(
      ASSET_FIELD.CURRENCY_ASSET,
    ).value
    let currencyId = currencyAsset?.id
    if (!currencyId) {
      currencyId = assetsBunchStore.getElementByTicker(assetCurrency.value)?.id
    }
    transaction.value.pasteAssetId1(asset.value.id, false)
    currencyId && transaction.value.pasteAssetId2(currencyId, false)
    // assign prices
    await Promise.all(
      prices.value.map(
        price =>
          asset.value &&
          assetsStore.addAssetPrice(
            {
              ...price,
              asset_id: asset.value.id,
            },
            false,
          ),
      ),
    )
    // save contributions and distributions
    await Promise.all(
      [...contributions.value, ...distributions.value].map(item => {
        // assign account and asset
        accountId.value && item.pasteAccountId1(accountId.value, false)
        asset.value && item.pasteAssetId1(asset.value.id, false)
        currencyId && item.pasteAssetId2(currencyId, false)
        return item.store()
      }),
    )
    // save subscribe and commit
    await transaction.value.store()
    await success(
      {
        message: 'Your Drawdown Fund has been successfully created',
      },
      NOTIFICATION_DELAY,
    )
    // open asset summary
    asset.value?.openDrawer()
    handleClose()
  } catch (e) {
    typeof e !== 'object' && (await error({ message: 'Something went wrong' }))
  } finally {
    loading.value = false
  }
}

const handleStepChange = async (value: number) => {
  if (await currentComponentRef.value?.nextStepDisallowed?.()) return
  currentStep.value = value
}

const handleButtonClick = async (assign?: boolean) => {
  if (await currentComponentRef.value?.nextStepDisallowed?.()) return
  if (assign) {
    assignTags.value = true
  } else if (currentStep.value === 3) {
    assignTags.value = false
  }
  switch (currentStep.value) {
    case 2:
      toggleItem(!!prices.value.length, 2, completed, skipped)
      break
    case 4:
      toggleItem(!!contributions.value.length, 4, completed, skipped)
      break
    case 5:
      toggleItem(!!distributions.value.length, 5, completed, skipped)
      break
  }
  if (currentStep.value === STEPS_LIST.length - 1) {
    handleCreate()
    return
  }
  currentStep.value++
}

watch(
  hasExistingAccounts,
  value => {
    if (value === undefined) return
    useExistingAccount.value = value
  },
  { immediate: true },
)

watch(assignTags, value => {
  toggleItem(value, 3, completed, skipped)
})

watch(isValidAccount, value => {
  toggleItem(!!value, 0, completed)
})

watch(
  () => isValidAsset.value && isValidTransaction.value,
  value => {
    toggleItem(!!value, 1, completed)
  },
)

watch(
  () => account.value?.field(ASSET_FIELD.NAME).value,
  value => {
    account.value && (account.value.field(ASSET_FIELD.TICKER).value = value)
  },
)

watch(
  () => asset.value?.field(ASSET_FIELD.NAME).value,
  value => {
    asset.value && (asset.value.field(ASSET_FIELD.TICKER).value = value)
  },
)

watch(
  isOpen,
  value => {
    if (!value) {
      useExistingAccount.value = true
      account.value = undefined
      asset.value = undefined
      transaction.value = undefined
      prices.value = []
      currentStep.value = 0
      completed.value = []
      skipped.value = []
      visited.value = []
      contributions.value = []
      distributions.value = []
      tags.value[ASSET_CLASS] = ASSET_CLASS_LIST[0]
      tags.value[INVESTMENT_STRATEGY] = INVESTMENT_STRATEGY_LIST[0]
      assignTags.value = undefined
      return
    }
    account.value = new AssetClass({
      type: undefined,
      name: '',
      ticker: '',
      repository_id: repositoryId.value,
      currency: assetCurrency.value,
    })
    asset.value = new AssetClass({
      type: ASSET_TYPE_LIST[0],
      name: '',
      ticker: '',
      repository_id: repositoryId.value,
      currency: assetCurrency.value,
    })
    transaction.value = new TransactionClass({
      type: TRANSACTION_TYPE,
      date: defaultDate.value,
      entries: [
        { account_id: '', amount: 1, asset_id: '' },
        {
          account_id: '',
          amount: null,
          asset_id: '',
        },
      ],
    })
    setTimeout(() => {
      account.value?.validateData()
      asset.value?.validateData()
      transaction.value?.validateData()
    }, 0)
  },
  { immediate: true },
)

onMounted(() => {
  if (!transactionsSettingsStore.initFlag) {
    transactionsSettingsStore.fetch()
  }
})
</script>
