import { generateRGBPalette } from '../helpers'

export default {
  color: generateRGBPalette('#1C4ED8', '#BBC9F4', 8),
  textStyle: {},
  title: {
    textStyle: {
      color: '#c8c8c8',
    },
    subtextStyle: {
      color: '#c8c8c8',
    },
  },
  label: {
    color: '#585F6D',
    rich: {
      value: {
        fontSize: 10,
        color: '#c8c8c8',
      },
    },
  },
  legend: {
    textStyle: {
      color: '#585F6D',
    },
  },
  bar: {
    select: {
      itemStyle: {
        color: '#030712', // gray-950
        borderWidth: 0,
      },
    },
  },
  pie: {
    itemStyle: {
      borderWidth: 1,
      borderColor: '#fff',
    },
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#474C5A',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#DEE1E7',
      },
    },
    axisLabel: {
      show: true,
      color: '#474C5A',
    },
  },
  valueAxis: {
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      show: true,
      color: '#474C5A',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#DEE1E7', '#EDEDED'],
      },
    },
  },
}
