<template>
  <div class="wizzard__form">
    <TagItem
      v-model="assetClass"
      :name="ASSET_CLASS"
      :list="assetClassMappedList"
    />
    <TagItem
      v-model="investmentStrategy"
      :name="INVESTMENT_STRATEGY"
      :list="investmentStrategyMappedList"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { ASSET_CLASS, INVESTMENT_STRATEGY } from '../utils/const'

import TagItem from '../components/TagItem.vue'

type Props = {
  tags: Record<string, string>
}
const { tags } = defineProps<Props>()

defineOptions({ inheritAttrs: false })

type Emits = {
  'update:tags': [data: Record<string, string>]
}
const emit = defineEmits<Emits>()

const assetClassList = inject<string[]>('tagAssetClassList', [])
const investmentStrategyList = inject<string[]>('tagInvestmentStrategyList', [])

const assetClass = computed({
  get() {
    return tags[ASSET_CLASS]
  },
  set(value) {
    emit('update:tags', { ...tags, [ASSET_CLASS]: value })
  },
})

const investmentStrategy = computed({
  get() {
    return tags[INVESTMENT_STRATEGY]
  },
  set(value) {
    emit('update:tags', { ...tags, [INVESTMENT_STRATEGY]: value })
  },
})

const assetClassMappedList = computed(() => {
  return assetClassList.map(value => ({ value }))
})

const investmentStrategyMappedList = computed(() => {
  return investmentStrategyList.map(value => ({ value }))
})
</script>
