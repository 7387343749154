<template>
  <div class="app-timeline">
    <div class="app-timeline__container">
      <slot />
      <template v-if="!isShortMode">
        <UIValuesGuide
          v-model="sliderDate"
          :values="dates"
          variant="gray"
          size="xs"
          fill="light"
          :disabled
          :loading="isLoading"
          :formatter="dateFormatter"
          :getter-fn="valueGetterFn"
        />
        <TimelineToggler v-model="isOpen" />
      </template>
    </div>
    <div v-if="isShortMode || isOpen" class="app-timeline__panel">
      <div v-if="isShortMode" class="app-timeline__panel-area">
        <UIValuesGuide
          v-model="sliderDate"
          :values="dates"
          variant="gray"
          size="xs"
          fill="light"
          :disabled
          :loading="isLoading"
          :formatter="dateFormatter"
          :getter-fn="valueGetterFn"
        />
        <TimelineToggler v-model="isOpen" />
      </div>
      <div v-if="isOpen" class="app-timeline__panel-fields">
        <UITimeline
          v-model="sliderDate"
          v-bind="{ dates, disabled, key, timeline }"
          @update:timeline="handleUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { OpenedFlag, RepositoryTimeline } from '@types'

import { REPOSITORIES_OPENED, TREE_OPENED } from '@/const'

import { useResponsive } from '@/plugins/responsiveUI'

import { stringToDateTime, stringToLocalDateString } from '@/helpers/dates'

import useAnalyticsStore from '@/store/analytics'
import { useRepositoriesStore } from '@/store/repositories'

import { UITimeline, UIValuesGuide } from '@ui'
import TimelineToggler from './TimelineToggler.vue'

type Props = {
  disabled?: boolean
  hideSlider?: boolean
}

defineProps<Props>()

defineOptions({
  name: 'AppTimeline',
})

const { isMobile, isTablet, toDesktop } = useResponsive()

const analyticsStore = useAnalyticsStore()
const repositoriesStore = useRepositoriesStore()

const isRepositoryOpened = inject<OpenedFlag>(REPOSITORIES_OPENED)
const isTreeOpened = inject<OpenedFlag>(TREE_OPENED)

const isOpen = ref(false)

const dateFormatter = (date: string) => `${stringToLocalDateString(date)}`

const isShortMode = computed(
  () =>
    isMobile.value ||
    isTablet.value ||
    (toDesktop.value && (isRepositoryOpened?.value || isTreeOpened?.value)),
)

const key = computed(() => repositoriesStore.getCurrentRepository?.id)
const timeline = computed(() => repositoriesStore.getCurrentTimeline)

const dates = computed(() => analyticsStore.getDatesRange)

const isLoading = computed(() => dates.value.length === 0)

const sliderDate = computed({
  get() {
    return analyticsStore.getDate
  },
  set(value) {
    analyticsStore.setDate(value)
  },
})

const handleUpdate = async (
  timeline: RepositoryTimeline,
  isEndChanged = false,
) => {
  analyticsStore.cancel()
  analyticsStore.refresh(isEndChanged ? undefined : ['tree'])

  await repositoriesStore.updateTimeline(timeline, isEndChanged)
  analyticsStore.markAsDeprecated(isEndChanged ? [] : ['tree', 'irr_moic'])
}

const valueGetterFn = (item: string, value: string) => {
  const date = stringToDateTime(value)
  return !!(date && stringToDateTime(item)?.hasSame(date, 'day'))
}
</script>

<style>
.app-timeline {
  @apply w-full;
  @apply mt-0.5 md:-mt-2;

  &__container {
    @apply min-h-9;
    @apply flex items-center;
    @apply gap-2;

    .ui-preloader {
      @apply mr-auto;
    }
  }

  &__panel {
    @apply -mx-4 sm:-mx-6;
    @apply bg-body-gray;
  }

  &__panel-area {
    @apply relative;
    @apply flex justify-center;
    @apply py-1.5 pl-4 pr-8 xxs:pr-4 sm:px-6;
  }

  &__panel-fields {
    @apply py-2.5 md:py-1.5 px-4 sm:px-6 md:pr-4;
    @apply bg-body-dark;

    .ui-timeline {
      @apply w-full;
      @apply flex justify-center md:justify-end;
    }
  }
}
</style>
