export const STEPS_LIST = [
  {
    name: 'Investing or Holding Entity',
  },
  {
    name: 'Investment',
  },
  {
    name: 'Valuation',
  },
  {
    name: 'Asset tags',
    description: 'optional',
  },
  {
    name: 'Contributions',
  },
  {
    name: 'Distributions',
  },
  {
    name: 'Summary',
  },
]

export const TRANSACTION_TYPE = 'subscribe and commit'

export const ASSET_TYPE_LIST = ['private fund']

export const ASSET_CLASS_LIST = ['Alternative Investments']

export const INVESTMENT_STRATEGY_LIST = [
  'Private Equity',
  'Private Credit',
  'Growth & Venture',
]

export const CONTRIBUTION_TYPES = ['capital call', 'asset fees']

export const DISTRIBUTION_TYPES = [
  'capital return',
  'recallable capital return',
  'cash dividend',
  'cash interest',
  'realized gain or loss',
  'current income',
]
