<template>
  <AssetsDeleted
    v-bind="{ columns, items, modal, plural: `${accountType} accounts` }"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { camelCase, upperFirst } from 'lodash'

import { AssetAccountTag, AssetCategoryAccount, ModalClass } from '@types'

import { ASSET_FIELD, ASSET_FIELD_LABEL } from '@/entities/assets/utils/const'

import useFiltersService from '@/services/filters'

import { useAssetsStoreV2 } from '@/store/assets/index'
import { useTagsBunchStore } from '@/store/tags/bunch'

import AssetsDeleted from '@/modules/modals/AssetsDeleted.vue'

type Props = {
  modal: ModalClass<any>
  type: AssetCategoryAccount
}
const props = defineProps<Props>()

const assetsStoreV2 = useAssetsStoreV2()
const tagsBunchStore = useTagsBunchStore()

const { fetchOptions } = useFiltersService()

const accountType = computed(() => props.type.replace(' account', ''))
const fetchOptionsResource = computed(
  () => `${upperFirst(camelCase(props.type.toString()))}s` as any,
)

const columns = computed(() => [
  {
    name: ASSET_FIELD.NAME,
    caption: ASSET_FIELD_LABEL[ASSET_FIELD.NAME],
    filter: {
      getter: () => fetchOptions(fetchOptionsResource.value, 'account'),
    },
  },
  {
    name: AssetAccountTag.CATEGORY,
    caption: ASSET_FIELD_LABEL[AssetAccountTag.CATEGORY],
    filter: {
      getter: () => fetchOptions(fetchOptionsResource.value, 'category'),
    },
  },
  {
    name: AssetAccountTag.SUBCATEGORY,
    caption: ASSET_FIELD_LABEL[AssetAccountTag.SUBCATEGORY],
    filter: {
      getter: () => fetchOptions(fetchOptionsResource.value, 'subcategory'),
    },
  },
])

const data = computed(() =>
  props.type === AssetCategoryAccount.EXPENSE
    ? assetsStoreV2.getExpenseAccountsDeleted
    : assetsStoreV2.getIncomeAccountsDeleted,
)

const items = computed(() =>
  data.value.map(item => {
    const tags = Object.fromEntries(
      (item.tags || []).map(
        id => tagsBunchStore.getList.get(id)?.pair || ['', ''],
      ),
    )
    return {
      ...item,
      ...tags,
    }
  }),
)
</script>

<script lang="ts">
export default {
  name: 'AccountsTrashPopup',
}
</script>
