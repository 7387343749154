<template>
  <div v-circular-tab class="ui-drawer-body">
    <slot />
  </div>
</template>

<script lang="ts" setup></script>

<style>
.ui-drawer-body {
  @apply flex flex-col flex-auto;
  @apply overflow-hidden;
}
</style>
