<template>
  <div class="app-tree-menu">
    <UIButtonMenu
      :open="isOpen"
      class="app-tree-menu__button"
      @click="handleClick"
    />
    <div v-if="isOpen" class="app-tree-menu__area">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { UIButtonMenu } from '@ui'

const isOpen = ref(false)

const handleClick = () => {
  isOpen.value = !isOpen.value
}
</script>

<style>
.app-tree-menu {
  @apply h-full;
  @apply relative;
  @apply flex items-center;
  @apply z-10;

  &__area {
    @apply absolute inset-y-0 right-full;
    @apply flex items-center;
    @apply gap-2 px-2;
    @apply bg-body-dark group-hover:bg-body-gray;

    @apply after:content-[''];
    @apply after:w-10;
    @apply after:absolute after:inset-y-0 after:right-full;
    @apply after:bg-gradient-to-l;
    @apply after:from-body-dark group-hover:after:from-body-gray;

    .ui-button {
      @apply whitespace-nowrap;
    }

    .ui-slide-panel & {
      @apply bg-body group-hover:bg-body-dark;
      @apply after:from-body group-hover:after:from-body-gray;

      @apply dark:bg-body-dark dark:group-hover:bg-body-gray;
      @apply dark:after:from-body-dark dark:group-hover:after:from-body-gray;
    }

    .ui-tree__item:has(.app-tree__checkbox--checked) & {
      @apply !bg-indigo-100 dark:!bg-gray-900;
      @apply after:from-indigo-100 dark:after:from-gray-900;
    }
  }
}
</style>
