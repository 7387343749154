<template>
  <UIButton
    :leading="togglerIcon"
    variant="secondary"
    size="sm"
    fill="light"
    label="Timeline"
    class="timeline-toggler"
    :class="buttonClasses"
    @click="handleClick"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { ClockIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { UIButton } from '@ui'

const modelValue = defineModel<boolean>()

const togglerIcon = computed(() => (modelValue.value ? XMarkIcon : ClockIcon))

const buttonClasses = computed(() => ({
  'timeline-toggler--open': modelValue.value,
}))

const handleClick = () => {
  modelValue.value = !modelValue.value
}
</script>

<style>
.timeline-toggler {
  @apply absolute top-1/2 right-3 sm:right-5;
  @apply -translate-y-1/2;
  @apply flex flex-row-reverse items-center;
  @apply gap-0.5 pl-2 pr-1;
  @apply !bg-transparent;
  @apply text-gray-400 hover:text-primary hover:dark:text-indigo-300;
  @apply !ring-0 !ring-offset-0;
  @apply cursor-pointer;

  .app-timeline__container & {
    @apply static top-auto right-auto;
    @apply translate-y-0;
    @apply -mr-2;
  }

  &--open {
    @apply !bg-body-dark;
    @apply rounded-b-none;
  }

  .app-timeline__panel &--open {
    @apply h-[2.5rem];
    @apply pb-1.5 mt-[.1875rem];
  }

  .app-timeline__container &--open {
    @apply pb-[1px] -mb-[1px];
  }

  .ui-button__icon {
    @apply size-7;
  }

  &--open .ui-button__icon {
    @apply size-5;
    @apply mx-1;
  }

  .ui-button__label {
    @apply text-xs;
  }
}
</style>
