<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialogAction
      v-bind="{ actions, zIndex }"
      size="sm"
      position="center"
      title="You have an unsaved value"
      @hide="handleCancel"
    >
      You can click "Cancel" and continue editing or skip and lose the data.
    </UIDialogAction>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { DialogActionButton, ModalClass, ModalState } from '@types'

import { TransitionRoot } from '@headlessui/vue'
import { UIDialogAction } from '@ui'

type Props = {
  modal: ModalClass<any>
}

type Emits = {
  confirm: []
  cancel: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const actions = computed<DialogActionButton[]>(() => [
  {
    label: 'Skip and lose data',
    onClick: handleConfirm,
    buttonVariant: 'danger',
  },
])

const handleCancel = () => {
  props.modal.close()
  emit('cancel')
}

const handleConfirm = () => {
  props.modal.close()
  emit('confirm')
}
</script>
