<template>
  <UIButton @click="handleClick">
    <template v-if="loading || $slots.leading" #leading>
      <ArrowPathIcon
        v-if="loading"
        class="ui-button__icon"
        aria-hidden="true"
      />
      <slot v-else name="leading" />
    </template>
    <template v-if="$slots.trailing" #trailing>
      <slot name="trailing" />
    </template>
  </UIButton>
</template>

<script setup lang="ts">
import { UIButton } from '@ui'
import { ArrowPathIcon } from '@heroicons/vue/24/outline'

type Props = {
  loading?: boolean
}

type Emits = {
  click: [event?: MouseEvent]
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const handleClick = (event?: MouseEvent) => {
  emit('click', event)
}
</script>

<style scoped>
.ui-button__icon {
  @apply animate-spin;
}
</style>
