<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDrawer
      v-bind="{ zIndex, isMinimized }"
      :title="drawerTitle"
      size="lg"
      @minimize="handleMinimize"
      @unminimize="handleUnminimize"
      @close="handleClose"
    >
      <template v-if="!isNewAsset" #title>
        <AssetTitle v-bind="titleProps" />
      </template>
      <div class="asset-drawer">
        <template v-for="tab in tabsList" :key="tab.route">
          <div
            v-if="initedTabs[tab.route]"
            v-show="tab.route === activeTab"
            class="asset-drawer__content"
            :class="{
              'asset-drawer__content--active': tab.route === activeTab,
            }"
          >
            <component
              :is="getComponent(tab.route)"
              :instance
              :account-id
              :amount
              :path
              :currency="isCurrency"
              @close="handleClose"
            />
          </div>
        </template>
        <div class="asset-drawer__panel">
          <UIButtonIcon
            v-for="tab in tabsList"
            :key="tab.route"
            :data-refid="tab.dataRefId"
            :label="tab.label"
            :trailing="tab.icon"
            :variant="isActive(tab.route) ? 'secondary' : 'gray'"
            :fill="isActive(tab.route) ? undefined : 'outlined'"
            :disabled="tab.disabled"
            size="sm"
            @click="handleClickTab(tab.route)"
          />
        </div>
      </div>
    </UIDrawer>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, provide, ref, watchEffect } from 'vue'
import { TransitionRoot } from '@headlessui/vue'
import { RouteRecordNameGeneric, useRoute, useRouter } from 'vue-router'
import { orderBy } from 'lodash'

import { AssetClass } from '..'
import { ModalClass, ModalState } from '@types'

import { ASSET_FIELD, ROUTE_NAME } from '@/const'

import { useModalsStore } from '@/store/modals'

import { UIButtonIcon, UIDrawer } from '@ui'
import AssetForm from './AssetDrawerForm.vue'
import AssetContacts from './AssetDrawerContacts.vue'
import AssetDocuments from './AssetDrawerDocuments.vue'
import AssetPrices from './AssetDrawerPrices.vue'
import AssetTags from './AssetDrawerTags.vue'
import AssetPositions from './AssetDrawerPositions.vue'
import AssetSecurityMaster from './AssetDrawerSecurityMaster.vue'
import AssetTitle from './AssetDrawerTitle.vue'
import AssetTransactions from './AssetDrawerTransactions.vue'
import AssetValues from './AssetDrawerValues.vue'

import LineChartIcon from '@/assets/images/icons/line-chart.svg'
import {
  ArchiveBoxXMarkIcon,
  ArrowsRightLeftIcon,
  ChartBarIcon,
  DocumentTextIcon,
  InformationCircleIcon,
  TableCellsIcon,
  TagIcon,
  UserGroupIcon,
} from '@heroicons/vue/24/outline'

type Props = {
  modal: ModalClass<AssetClass>
  instance?: AssetClass
  restrictions?: string[]
  accountId?: string
  amount?: number
  path?: string[]
}

const props = defineProps<Props>()

const modalsStore = useModalsStore()

const route = useRoute()
const router = useRouter()

const currentTab = defineModel<string | undefined>('tab')

const initedTabs = ref<Record<string, boolean>>({})

provide('restrictions', props.restrictions)

const activeTab = computed(
  () =>
    currentTab.value ||
    (isCurrency.value ? ROUTE_NAME.CURRENCIES_ITEM : ROUTE_NAME.ASSETS_ITEM),
)

const instance = computed(() => props.instance || props.modal.entity)
const isCurrency = computed(() => instance.value.isCurrency)

const parentRoute = computed(() =>
  isCurrency.value ? ROUTE_NAME.CURRENCIES : ROUTE_NAME.ASSETS,
)

const itemRoute = computed(() =>
  isCurrency.value ? ROUTE_NAME.CURRENCIES_ITEM : ROUTE_NAME.ASSETS_ITEM,
)

const securityMasterRoute = computed(() =>
  isCurrency.value
    ? ROUTE_NAME.CURRENCIES_ITEM_SECURITY_MASTER
    : ROUTE_NAME.ASSETS_ITEM_SECURITY_MASTER,
)

const tagsRoute = computed(() =>
  isCurrency.value
    ? ROUTE_NAME.CURRENCIES_ITEM_TAGS
    : ROUTE_NAME.ASSETS_ITEM_TAGS,
)

const pricesRoute = computed(() =>
  isCurrency.value
    ? ROUTE_NAME.CURRENCIES_ITEM_PRICES
    : ROUTE_NAME.ASSETS_ITEM_PRICES,
)

const isItAssetsRoute = computed(() => {
  return route.name?.toString().startsWith(parentRoute.value)
})

const isNewAsset = computed(() => instance.value.isNew)

const drawerTitle = computed(() => {
  return isNewAsset.value ? 'New Asset' : assetName.value
})

const isItRoutedAsset = computed(() => {
  return (
    route.matched.some(item => item.name === itemRoute.value) &&
    route.params?.id === instance.value.id
  )
})

const assetType = computed(
  () => instance.value.field<string>(ASSET_FIELD.TYPE).value,
)

const assetTicker = computed(
  () => instance.value.field<string>(ASSET_FIELD.TICKER).value,
)

const assetName = computed(
  () => instance.value.field<string>(ASSET_FIELD.NAME).value,
)

const tabsList = computed(() => {
  return [
    {
      label: 'Basic info',
      route: itemRoute.value,
      icon: InformationCircleIcon,
    },
    {
      label: 'Tags',
      route: tagsRoute.value,
      icon: TagIcon,
      disabled: isNewAsset.value,
      dataRefId: 'assetDrawerTags',
    },
    {
      label: 'Prices',
      route: pricesRoute.value,
      icon: LineChartIcon,
      disabled: isNewAsset.value,
      dataRefId: 'assetDrawerPrices',
    },
    ...(isCurrency.value
      ? []
      : [
          {
            label: 'Values',
            route: ROUTE_NAME.ASSETS_ITEM_VALUES,
            icon: ChartBarIcon,
            disabled: isNewAsset.value,
          },
        ]),
    ...(isCurrency.value
      ? []
      : [
          {
            label: 'Positions',
            route: ROUTE_NAME.ASSETS_ITEM_POSITIONS,
            icon: TableCellsIcon,
            disabled: isNewAsset.value,
          },
          {
            label: 'Transactions',
            route: ROUTE_NAME.ASSETS_ITEM_TRANSACTIONS,
            icon: ArrowsRightLeftIcon,
            disabled: isNewAsset.value,
          },
        ]),
    {
      label: 'Sec master',
      route: securityMasterRoute.value,
      icon: ArchiveBoxXMarkIcon,
      disabled: isNewAsset.value,
    },
    ...(isCurrency.value
      ? []
      : [
          {
            label: 'Contacts',
            route: ROUTE_NAME.ASSETS_ITEM_CONTACTS,
            icon: UserGroupIcon,
            disabled: isNewAsset.value,
            dataRefId: 'assetDrawerContacts',
          },
          {
            label: 'Documents',
            route: ROUTE_NAME.ASSETS_ITEM_DOCUMENTS,
            icon: DocumentTextIcon,
            disabled: isNewAsset.value,
            dataRefId: 'assetDrawerDocuments',
          },
        ]),
  ]
})

const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const isMinimized = computed(() => props.modal.state === ModalState.HIDDEN)

const zIndex = computed(() => props.modal.zIndex)

const showDate = computed(
  () =>
    isItRoutedAsset.value &&
    activeTab.value === ROUTE_NAME.ASSETS_ITEM_TRANSACTIONS,
)

const titleProps = computed(() => ({
  name: assetName.value,
  ticker: assetTicker.value,
  type: assetType.value,
  showDate: showDate.value,
}))

const getComponent = (routeName: string) => {
  switch (routeName) {
    case ROUTE_NAME.ASSETS_ITEM_SECURITY_MASTER:
    case ROUTE_NAME.CURRENCIES_ITEM_SECURITY_MASTER:
      return AssetSecurityMaster
    case ROUTE_NAME.ASSETS_ITEM_PRICES:
    case ROUTE_NAME.ASSETS_ITEM_PRICES_TABLE:
    case ROUTE_NAME.CURRENCIES_ITEM_PRICES:
    case ROUTE_NAME.CURRENCIES_ITEM_PRICES_TABLE:
      return AssetPrices
    case ROUTE_NAME.ASSETS_ITEM_POSITIONS:
      return AssetPositions
    case ROUTE_NAME.ASSETS_ITEM_TRANSACTIONS:
      return AssetTransactions
    case ROUTE_NAME.ASSETS_ITEM_VALUES:
      return AssetValues
    case ROUTE_NAME.ASSETS_ITEM_TAGS:
    case ROUTE_NAME.CURRENCIES_ITEM_TAGS:
      return AssetTags
    case ROUTE_NAME.ASSETS_ITEM_CONTACTS:
      return AssetContacts
    case ROUTE_NAME.ASSETS_ITEM_DOCUMENTS:
      return AssetDocuments
    default:
      return AssetForm
  }
}

const isActive = (name: string) => activeTab.value === name

const handleClickTab = (name: string) => {
  currentTab.value = name
  if (!isItRoutedAsset.value) return
  router.push({
    name,
    params: { id: instance.value.id },
  })
}

const handleMinimize = () => {
  props.modal.hide()
}

const handleUnminimize = () => {
  props.modal.show(modalsStore.getZIndex())
}

const handleClose = () => {
  props.modal.conditionalClose()
  const nextModal = orderBy(modalsStore.getOpened, 'zIndex', 'desc').find(
    item => item.entity instanceof AssetClass,
  )
  if (!nextModal) return
  router.push({
    name: (nextModal.entity as AssetClass).isCurrency
      ? ROUTE_NAME.CURRENCIES_ITEM
      : ROUTE_NAME.ASSETS_ITEM,
    // @ts-ignore
    params: { id: nextModal.entity.id },
  })
}

const getRouteName = (routeName: RouteRecordNameGeneric) => {
  switch (routeName) {
    case ROUTE_NAME.ASSETS_ITEM_PRICES_TABLE:
      return ROUTE_NAME.ASSETS_ITEM_PRICES
    case ROUTE_NAME.CURRENCIES_ITEM_PRICES_TABLE:
      return ROUTE_NAME.CURRENCIES_ITEM_PRICES
    case ROUTE_NAME.ASSETS:
    case ROUTE_NAME.CURRENCIES:
      return
    default:
      return routeName?.toString()
  }
}

watchEffect(() => {
  activeTab.value && (initedTabs.value[activeTab.value] = true)
})

onBeforeMount(() => {
  if (activeTab.value?.startsWith(ROUTE_NAME.ASSETS_ITEM) && isCurrency.value) {
    currentTab.value = currentTab.value?.replace(
      ROUTE_NAME.ASSETS_ITEM,
      ROUTE_NAME.CURRENCIES_ITEM,
    )
  }
  if (currentTab.value || !isItAssetsRoute.value) return
  currentTab.value = getRouteName(route.name)
})
</script>

<style>
.asset-drawer {
  @apply flex flex-auto flex-row-reverse;
  @apply overflow-hidden;

  &__content {
    @apply flex flex-col flex-auto;
    @apply overflow-hidden;
  }

  &__panel {
    @apply flex flex-col shrink-0;
    @apply relative;
    @apply gap-4 py-2 px-1;
    @apply shrink-0;
    @apply bg-body-gray;
    @apply border-r border-light-border;
    @apply overflow-y-auto;

    .ui-button-icon__label {
      @apply max-w-none;
    }
  }

  &__form {
    @apply flex;
    @apply gap-2 py-4 px-4 sm:px-default;

    .ui-panel {
      @apply flex-auto;
      @apply m-0;
    }

    &--second {
      @apply pt-0;
    }
  }

  &__form-input {
    @apply flex-auto;
  }

  &__pagination {
    @apply px-4 sm:px-default;
  }
}
</style>
