<template>
  <div
    v-if="isSlotFilled"
    class="ui-grid__actions"
    :class="mainClasses"
    @mouseup.stop
    @mousedown.stop
  >
    <UIButtonMenu :open="isOpen" @click="handleClick" />
    <div v-if="isOpen" class="ui-grid__actions-area" :class="areaClasses">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, inject, ref, useSlots } from 'vue'

import { UIButtonMenu } from '@ui'

const slots = useSlots()

const isHovered = inject<Ref<boolean>>('isHovered')

const isOpen = ref(false)

const isSlotFilled = computed(() => slots.default?.()?.[0]?.children?.length)

const mainClasses = computed(() => ({
  'ui-grid__actions--opened': isOpen.value,
  'ui-grid__actions--opened-hovered': isOpen.value && isHovered?.value,
}))

const areaClasses = computed(() => ({
  'ui-grid__actions-area--hovered': isHovered?.value,
}))

const handleClick = () => {
  isOpen.value = !isOpen.value
}
</script>

<style>
.ui-grid__actions {
  @apply absolute right-0.5 -bottom-1.5;
  @apply flex items-center;
  @apply z-10;

  &--opened {
    @apply bg-body;

    &-hovered {
      @apply bg-body-gray;
    }
  }

  &-area {
    @apply absolute inset-y-0 right-full;
    @apply flex items-center;
    @apply gap-2 px-1;
    @apply bg-body;

    @apply after:content-[''];
    @apply after:w-10;
    @apply after:absolute after:inset-y-0 after:right-full;
    @apply after:bg-gradient-to-l;
    @apply after:from-body;

    .ui-button {
      @apply whitespace-nowrap;
    }
  }
}

.ui-grid:not(.ui-grid--collapsed) {
  .ui-grid__actions {
    @apply inset-y-0 right-auto left-0;

    &-area {
      @apply right-auto left-full;

      @apply after:right-auto after:left-full;
      @apply after:bg-gradient-to-r;

      &--hovered {
        @apply bg-body-gray;
        @apply after:from-body-gray;
      }
    }
  }
}
</style>
