<template>
  <div v-if="isPanelVisible" class="app-reload-page">
    <SpinnerIcon class="app-reload-page__spinner" />
    <span>Reloading the page...</span>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useSwipe, UseSwipeDirection } from '@vueuse/core'

import SpinnerIcon from '@/assets/images/icons/spinner.svg'

useSwipe(document.body, {
  threshold: 100,
  onSwipeEnd: (e: TouchEvent, direction: UseSwipeDirection) => {
    if (!e.cancelable || direction !== 'down') return
    isPanelVisible.value = true
    setTimeout(() => {
      window.location.reload()
    }, 1500)
  },
})

const isPanelVisible = ref(false)
</script>

<style>
.app-reload-page {
  @apply fixed inset-x-0 top-0;
  @apply flex items-center justify-center;
  @apply gap-2 -mb-safe pt-safe-offset-2 pb-2;
  @apply bg-primary-dark bg-opacity-95;
  @apply text-primary-selected;
  @apply z-[10000];

  &__spinner {
    @apply size-7;
    @apply animate-spin;
  }
}
</style>
