<template>
  <UIButton ref="buttonRef" v-bind="{ label }" class="min-w-28" />
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue'

import { UIButton } from '@ui'

type Props = {
  label?: string
}

const { label = 'Save' } = defineProps<Props>()

const buttonRef = useTemplateRef('buttonRef')

defineExpose({
  focus() {
    buttonRef.value?.focus()
  },
})
</script>
