<template>
  <UIButton ref="buttonRef" v-bind="{ size, variant, fill }">
    <template v-if="count" #trailing>
      <div class="ui-button-count" :class="countClasses">
        {{ count }}
      </div>
    </template>
  </UIButton>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'

import { ButtonFill, ButtonSize, ButtonVariant } from '@types'

import UIButton from './Button.vue'

type Props = {
  count?: number

  size?: ButtonSize
  variant?: ButtonVariant
  fill?: ButtonFill
}

const {
  count,
  size = 'default',
  variant = 'primary',
  fill = 'filled',
} = defineProps<Props>()

const buttonRef = useTemplateRef('buttonRef')

defineExpose({
  focus() {
    buttonRef.value?.focus()
  },
})

const countClasses = computed(() => [
  `ui-button-count--${size} ui-button-count--${variant}-${fill}`,
])
</script>

<style>
.ui-button-count {
  @apply w-[1.15rem] h-[1.15rem];
  @apply flex items-center justify-center;
  @apply shrink-0;
  @apply -ml-0.5 -my-1;
  @apply rounded-full;
  @apply text-[10px] font-bold;

  &--primary-filled {
    @apply bg-[var(--button-primary-text)];
    @apply text-[var(--button-primary-ring)];
  }

  &--primary-light,
  &--primary-outlined {
    @apply text-[var(--button-primary-text)];
    @apply bg-[var(--button-primary-hover)];
  }

  &--secondary-filled {
    @apply bg-[var(--button-secondary-text)];
    @apply text-[var(--button-secondary-ring)];
  }

  &--secondary-light,
  &--secondary-outlined {
    @apply text-[var(--button-secondary-text)];
    @apply bg-[var(--button-secondary-hover)];
  }

  &--danger-filled {
    @apply bg-[var(--button-danger-text)];
    @apply text-[var(--button-danger-ring)];
  }

  &--danger-light,
  &--danger-outlined {
    @apply text-[var(--button-danger-text)];
    @apply bg-[var(--button-danger-hover)];
  }

  &--gray-filled {
    @apply bg-[var(--button-gray-text)];
    @apply text-[var(--button-gray-ring)];
  }

  &--gray-light,
  &--gray-outlined {
    @apply text-[var(--button-gray-text)];
    @apply bg-[var(--button-gray-hover)];
  }
}
</style>
