<template>
  <div v-circular-tab class="slide-panel-body">
    <div class="slide-panel-body__header" :class="headerClass">
      <component :is="title" v-if="typeof title === 'object'" />
      <div v-else class="slide-panel-body__title">
        <slot name="title" />
        <template v-if="!$slots.title">
          <span>{{ title }}</span>
          <span v-if="subTitle" class="slide-panel-body__subtitle">{{
            subTitle
          }}</span>
        </template>
      </div>
      <div class="slide-panel-body__actions">
        <FoldButton
          v-if="!hideMinimiseButton"
          data-refid="slidePanelFold"
          tabindex="-1"
          class="slide-panel-body__fold"
          @click="handleMinimize"
        />
        <CloseButton
          data-refid="slidePanelClose"
          tabindex="-1"
          @click="handleClose"
        />
      </div>
    </div>
    <div class="slide-panel-body__content" :class="wrapperClass">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { VNode } from 'vue'

import FoldButton from './components/FoldButton.vue'
import CloseButton from './components/CloseButton.vue'

type Props = {
  title?: string | VNode
  subTitle?: string

  headerClass?: string
  wrapperClass?: string

  hideMinimiseButton?: boolean
}

type Emits = {
  'click:close': []
  'click:minimize': []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const handleMinimize = () => {
  emit('click:minimize')
}

const handleClose = () => {
  emit('click:close')
}
</script>

<style scoped>
.slide-panel-body {
  @apply h-full w-full;
  @apply flex flex-col;
  @apply bg-body dark:bg-body-dark;
  @apply shadow-lg;
  @apply shadow-[0_0_20px_0_rgba(0,0,0,0.1)];

  .ui-slide-panel__container--with-back & {
    @apply bg-body;
  }

  &__header {
    @apply flex items-center md:items-start justify-between;
    @apply shrink-0;
    @apply px-4 sm:px-6 py-4;
  }

  .ui-slide-panel__container--horizontal &__header {
    @apply pb-4 pt-safe-offset-3;
  }

  &__title {
    @apply flex flex-col w-full;
    @apply text-lg leading-none font-medium;
    @apply text-gray-900 dark:text-gray-200;
  }

  &__subtitle {
    @apply text-sm leading-none;
    @apply text-gray-500 dark:text-gray-400;
  }

  &__actions {
    @apply flex;
    @apply shrink-0;
    @apply gap-2 -mr-2;
    @apply text-gray-400 hover:text-gray-500;
    @apply dark:text-gray-500 dark:hover:text-gray-400;
  }

  &__content {
    @apply flex flex-col flex-auto;
    @apply pb-safe px-4 sm:px-6;
    @apply overflow-x-hidden overflow-y-auto;
  }

  &__fold {
    .ui-slide-panel__container--vertical & {
      @apply rotate-90;
    }
  }
}
</style>
