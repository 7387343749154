<template>
  <div class="create-actions">
    <slot v-bind="{ items, disabled }">
      <UIButtonIcon
        v-for="item in items"
        :key="item.label"
        v-bind="{ disabled }"
        :leading="item.icon"
        :label="item.label"
        variant="gray"
        fill="outlined"
        size="2xl"
        @click="item.action"
      />
      <div v-if="syncing" class="create-actions__loading">
        <UIPreloader message="Your accounts are now being refreshed" />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed, markRaw } from 'vue'

import { useModalsStore } from '@/store/modals'
import { useLinkedDataConnector } from '../LinkedData/hooks/useLinkedDataConnector'

import { UIButtonIcon, UIPreloader } from '@ui'
import {
  BanknotesIcon,
  HomeModernIcon,
  Square3Stack3DIcon,
} from '@heroicons/vue/24/outline'
import ArchIcon from '@/assets/images/icons/arch.svg'
import PrivateInvestmentIcon from '@/assets/images/icons/private-investment.svg'

import AddAccount from '@/views/LinkedData/Connectors/components/AddAccount.vue'
import ArchAccount from '@/modules/wizards/ArchAccount/ArchAccount.vue'
import DrawdownFund from '@/modules/wizards/DrawdownFund/DrawdownFund.vue'
import PrivateInvestment from '@/modules/wizards/PrivateInvestment/PrivateInvestment.vue'
import RealEstate from '@/modules/wizards/RealEstate/RealEstate.vue'

import { useTasksService } from '@/services/tasks'

type Emits = {
  click: []
}

const emit = defineEmits<Emits>()

const { loading, syncing } = useLinkedDataConnector()

const modalsStore = useModalsStore()
useTasksService()

const disabled = computed(() => syncing.value || loading.value)

const items = computed(() => [
  {
    icon: BanknotesIcon,
    label: 'Connect Bank, Investment & Crypto Accounts',
    action: handleClickAddAccount,
  },
  {
    icon: HomeModernIcon,
    label: 'Add Real Estate',
    action: handleClickCreateRealEstate,
  },
  {
    icon: Square3Stack3DIcon,
    label: 'Add Drawdown Fund',
    action: handleClickCreateDrawdownFund,
  },
  {
    icon: PrivateInvestmentIcon,
    label: 'Add Private Investment',
    action: handleClickCreatePrivateInvestment,
  },
  {
    icon: ArchIcon,
    label: 'Add Arch Account',
    action: handleClickAddArchAccount,
  },
])

const handleClickAddAccount = () => {
  emit('click')
  const modalInstance = modalsStore.init('add-account', markRaw(AddAccount))
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClickAddArchAccount = () => {
  emit('click')
  const modalInstance = modalsStore.init('arch-account', markRaw(ArchAccount))
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClickCreateRealEstate = () => {
  emit('click')
  const modalInstance = modalsStore.init('real-estate', markRaw(RealEstate))
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClickCreateDrawdownFund = () => {
  emit('click')
  const modalInstance = modalsStore.init('drowdown-fund', markRaw(DrawdownFund))
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClickCreatePrivateInvestment = () => {
  emit('click')
  const modalInstance = modalsStore.init(
    'private-investment',
    markRaw(PrivateInvestment),
  )
  modalInstance?.open(modalsStore.getZIndex())
}
</script>

<style scoped>
.create-actions {
  @apply grid grid-cols-2;
  @apply gap-6 sm:p-4;

  &__loading {
    @apply absolute inset-0;
    @apply flex items-center justify-center;
    @apply bg-white dark:bg-gray-800 bg-opacity-80;
  }
}
</style>
