<template>
  <div class="asset-drawer-form">
    <component :is="component" :instance @close="emit('close')" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { AssetClass } from '..'

import AssetForm from './AssetForm.vue'
import CurrencyForm from './CurrencyForm.vue'

type Props = {
  instance: AssetClass
  currency?: boolean
}

type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({ inheritAttrs: false })

const component = computed(() => (props.currency ? CurrencyForm : AssetForm))
</script>

<style>
.asset-drawer-form {
  @apply flex flex-col flex-auto;
  @apply gap-4 py-4 px-default;
  @apply overflow-auto;
}
</style>
