<template>
  <AssetsDeleted v-bind="{ columns, items, modal, plural: 'currencies' }" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { ModalClass } from '@types'

import { ASSET_FIELD, ASSET_FIELD_LABEL } from '@/entities/assets/utils/const'

import useFiltersService from '@/services/filters'

import { useAssetsStoreV2 } from '@/store/assets/index'

import AssetsDeleted from '@/modules/modals/AssetsDeleted.vue'

type Props = {
  modal: ModalClass<any>
}
defineProps<Props>()

const assetsStoreV2 = useAssetsStoreV2()

const { fetchOptions } = useFiltersService()

const columns = computed(() => [
  {
    name: ASSET_FIELD.NAME,
    caption: ASSET_FIELD_LABEL[ASSET_FIELD.NAME],
    filter: {
      getter: () => fetchOptions('Currencies', ASSET_FIELD.NAME),
    },
  },
  {
    name: ASSET_FIELD.TICKER,
    caption: ASSET_FIELD_LABEL[ASSET_FIELD.TICKER],
    filter: {
      getter: () => fetchOptions('Currencies', ASSET_FIELD.TICKER),
    },
  },
])

const items = computed(() => assetsStoreV2.getCurrenciesDeleted)
</script>

<script lang="ts">
export default {
  name: 'CurrenciesTrashPopup',
}
</script>
