<template>
  <New
    :disabled="isEditMode"
    label="View"
    size="xs"
    variant="secondary"
    fill="light"
    @click="handleClick"
  />
</template>

<script setup lang="ts">
import { computed, markRaw } from 'vue'
import { useRouter } from 'vue-router'

import { Widget } from '@types'

import { ROUTE_NAME } from '@/const'
import { CUSTOM_VIEW_NAME } from '../utils/const'

import { useDashboardsSettingsStore } from '@/store/settings/dashboards'
import { useModalsStore } from '@/store/modals'
import { useUserSettingsStore } from '@/store/user/settings'

import New from '@/components/UI/Panel/components/New.vue'
import AddViewDialog from './AddViewDialog.vue'

const router = useRouter()

const dashboardSettingsStore = useDashboardsSettingsStore()
const modalsStore = useModalsStore()
const userSettingsStore = useUserSettingsStore()

const isEditMode = computed(() => dashboardSettingsStore.getEditMode)

const handleClick = () => {
  const modalInstance = modalsStore.init(
    'AddViewDialog',
    markRaw(AddViewDialog),
  )
  modalInstance?.open(modalsStore.getZIndex(), {
    onClick: (widgets: Widget[], name: string, slug: string) => {
      const index = dashboardSettingsStore.getDashboards?.filter(item =>
        new RegExp(`^${name}(\\s\\d+)?$`).test(item.name),
      )?.length
      dashboardSettingsStore.addDashboard({
        name: `${name}${index ? ` ${index}` : ''}`,
        slug,
        widgets,
      })
      userSettingsStore.pinMenu(
        `${ROUTE_NAME.DASHBOARDS_DASHBOARD}${JSON.stringify({ slug })}`,
      )
      router.push({ name: ROUTE_NAME.DASHBOARDS_DASHBOARD, params: { slug } })
      if (name === CUSTOM_VIEW_NAME) {
        dashboardSettingsStore.setEditMode(true)
      }
    },
  })
}
</script>
