<template>
  <div ref="timelineRef" class="ui-timeline" :class="timelineClasses">
    <div class="ui-timeline__container">
      <div class="ui-timeline__periods">
        <Periods v-bind="props" ref="periodsRef" v-model="periodsModel" :size />
        <Freq
          v-bind="props"
          ref="freqRef"
          v-model="freqModel"
          :size
          @update="handleUpdatePeriods"
        />
      </div>
      <div class="ui-timeline__end">
        <div class="ui-timeline__label">Periods <br />Ending:</div>
        <End
          v-bind="props"
          v-model="endModel"
          :size
          @update="handleUpdateEnd"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, useTemplateRef, watch } from 'vue'
import { isEqual, pick } from 'lodash'
import { useResizeObserver } from '@vueuse/core'

import { RepositoryTimeline } from '@types'

import { getCurrentDate } from '@/helpers/dates'

import End from './components/End.vue'
import Freq from './components/Freq.vue'
import Periods from './components/Periods.vue'

type Props = {
  timeline: RepositoryTimeline
  dates: string[]
  disabled?: boolean
}

type Emits = {
  'update:timeline': [data: RepositoryTimeline, isEndChanged?: boolean]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({
  name: 'UITimeline',
})

const freqRef = useTemplateRef('freqRef')
const timelineRef = useTemplateRef('timelineRef')

const isInit = ref(true)
const isShortMode = ref(false)

const periodsModel = ref<RepositoryTimeline['periods']>()
const freqModel = ref<RepositoryTimeline['freq']>()
const endModel = ref<RepositoryTimeline['end']>()

useResizeObserver(timelineRef, entries => {
  isShortMode.value = entries[0]?.target?.parentElement
    ? entries[0]?.target?.parentElement?.clientWidth < 450
    : false
})

const size = computed(() => (isShortMode.value ? 'sm' : undefined))

const timelineClasses = computed(() => ({
  'ui-timeline--short': isShortMode.value,
}))

const currentValue = computed(() => ({
  periods: periodsModel.value || props.timeline.periods || 1,
  freq: freqModel.value || props.timeline.freq || 'day',
  end: endModel.value || props.timeline.end || getCurrentDate({}),
}))

const newTimeline = computed(() => ({
  ...props.timeline,
  ...currentValue.value,
}))

const handleUpdate = (isEndChanged?: boolean) => {
  if (!props.timeline) return
  const timelineValue = pick(props.timeline, 'periods', 'freq', 'end')
  if (isEqual(currentValue.value, timelineValue) && !isEndChanged) return
  emit('update:timeline', newTimeline.value, isEndChanged)
}

const handleUpdatePeriods = () => {
  handleUpdate()
}

const handleUpdateEnd = () => {
  handleUpdate(true)
}

const initModels = async (data: RepositoryTimeline) => {
  isInit.value = true
  if (Object.keys(data).length === 0) return
  if (periodsModel.value !== data.periods) {
    periodsModel.value = data.periods
  }
  if (freqModel.value !== data.freq) {
    freqModel.value = data.freq
  }
  if (data.end_is_today === true) {
    endModel.value = getCurrentDate({})
  } else if (endModel.value !== data.end) {
    endModel.value = data.end
  }
  await nextTick()
  isInit.value = false
}

watch(periodsModel, async () => {
  if (isInit.value) return
  await nextTick()
  setTimeout(() => {
    freqRef.value?.focus()
  })
})

watch(() => props.timeline, initModels, { immediate: true })
</script>

<style>
.ui-timeline {
  &__container {
    @apply flex items-end;
    @apply gap-x-2;
  }

  &__periods {
    @apply flex items-center;
    @apply gap-x-2;
  }

  &__end {
    @apply flex items-center;
    @apply gap-x-2;
  }

  &--short &__container,
  &--short &__end,
  &--short &__periods {
    @apply gap-x-1;
  }

  &__label {
    @apply text-xs;
    @apply leading-4;
    @apply text-gray-500 dark:text-gray-400;
    @apply uppercase;
  }

  &--short &__label {
    @apply text-[0.65rem];
    @apply leading-tight;
  }
}
</style>
