import { ref } from 'vue'
import { useNotifications } from '@/plugins/notification'
import { useMainStore } from '@/store/main'

export const useCheckDirtyForm = () => {
  const { warn } = useNotifications()
  const mainStore = useMainStore()

  const dirty = ref()
  const notificationId = ref()

  const onDirtyChange =
    (checkGlobally = false) =>
    (value: boolean): void => {
      if (checkGlobally) {
        mainStore.slideOverFormDirty = value
      } else {
        dirty.value = value
      }
    }

  const onFormCheck =
    (checkGlobally = false) =>
    async (callback: () => void, rejectCallback?: () => void) => {
      let isDirty = dirty.value
      if (checkGlobally) {
        isDirty = mainStore.slideOverFormDirty
      }
      if (isDirty) {
        notificationId.value = await warn({
          message: 'Warning',
          description:
            'You have unsaved changes in the previous form, do you want to continue editing?',
          actionRequired: true,
          actions: [
            {
              label: 'Continue editing',
              onClick: remove => {
                remove && remove()
                rejectCallback?.()
              },
            },
            {
              label: 'Discard form changes',
              buttonVariant: 'secondary',
              onClick: remove => {
                remove && remove()
                dirty.value = false
                callback()
              },
            },
          ],
        })
      } else {
        callback()
      }
    }
  return {
    onDirtyChange,
    onFormCheck,
  }
}
