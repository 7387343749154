import { RouteComponent, RouteRecordRaw } from 'vue-router'

import { ROUTE_NAME } from '@/const'

import {
  isAlreadyAuth,
  requireAuth,
  redirectToSignin,
  requireAnalyticsDebug,
} from './utils/helpers'

const { VITE_APP_MODE } = import.meta.env

const isProdMode = ['production', 'testing'].includes(VITE_APP_MODE)

const EmptyComponent = { template: '<router-view></router-view>' }

const routes = [
  {
    path: '/',
    name: ROUTE_NAME.INDEX,
    component: (): RouteComponent => import('@/views/Index/Index.vue'),
    meta: {
      layout: 'EmptyLayout',
      title: 'A better way to manage your financial and investment life',
      hideIntercomLauncher: true,
    },
    beforeEnter: redirectToSignin,
  },
  // Dashboards routes
  {
    path: '/dashboards',
    component: (): RouteComponent =>
      import('@/views/Dashboards/Dashboards.vue'),
    props: route => ({
      slug: route.params.slug as string,
    }),
    children: [
      {
        path: '',
        name: ROUTE_NAME.DASHBOARDS,
        component: EmptyComponent,
        meta: {},
      },
      {
        path: ':slug',
        name: ROUTE_NAME.DASHBOARDS_DASHBOARD,
        component: EmptyComponent,
      },
    ],
    meta: {
      sidebar: true,
      layout: 'MainLayout',
      analytics: true,
      title: 'Portfolio Dashboards',
    },
    beforeEnter: requireAuth,
  },
  // User routes
  {
    path: '/login',
    name: ROUTE_NAME.SIGNIN,
    component: (): RouteComponent => import('@/views/User/SignIn.vue'),
    beforeEnter: isAlreadyAuth,
    meta: {
      title: 'Sign in - Allposit Inc.',
      hideIntercomLauncher: true,
    },
  },
  {
    path: '/signin-confirmation',
    name: ROUTE_NAME.SIGNIN_CONFIRMATION,
    component: (): RouteComponent =>
      import('@/views/User/SignInConfirmation.vue'),
    beforeEnter: isAlreadyAuth,
    meta: {
      title: 'Sign in confirmation - Allposit Inc.',
      hideIntercomLauncher: true,
    },
  },
  {
    path: '/confirm',
    name: ROUTE_NAME.CONFIRM,
    component: (): RouteComponent => import('@/views/User/Confirm.vue'),
    beforeEnter: isAlreadyAuth,
    meta: {
      title: 'Enter code - Allposit Inc.',
      hideIntercomLauncher: true,
    },
  },
  {
    path: '/forgot',
    name: ROUTE_NAME.FORGOT,
    component: (): RouteComponent => import('@/views/User/Forgot.vue'),
    beforeEnter: isAlreadyAuth,
    meta: {
      title: 'Reset your password - Allposit Inc.',
      hideIntercomLauncher: true,
    },
  },
  // Analytics routes
  {
    path: '/analytics-value',
    name: ROUTE_NAME.ANALYTICS_VALUE,
    component: (): RouteComponent => import('@/views/Analytics/Value.vue'),
    meta: {
      sidebar: true,
      layout: 'MainLayout',
      analytics: true,
      title: 'Value Analytics',
    },
    beforeEnter: [requireAuth, requireAnalyticsDebug],
  },
  {
    path: '/analytics-income',
    name: ROUTE_NAME.ANALYTICS_INCOME,
    component: (): RouteComponent => import('@/views/Analytics/Income.vue'),
    meta: {
      sidebar: true,
      layout: 'MainLayout',
      analytics: true,
      title: 'Income Analytics',
    },
    beforeEnter: [requireAuth, requireAnalyticsDebug],
  },
  {
    path: '/analytics-position',
    name: ROUTE_NAME.ANALYTICS_POSITION,
    component: (): RouteComponent => import('@/views/Analytics/Position.vue'),
    meta: {
      sidebar: true,
      layout: 'MainLayout',
      analytics: true,
      title: 'Position Analytics',
    },
    beforeEnter: [requireAuth, requireAnalyticsDebug],
  },
  {
    path: '/analytics-value-change',
    name: ROUTE_NAME.ANALYTICS_VALUE_CHANGE,
    component: (): RouteComponent =>
      import('@/views/Analytics/ValueChange.vue'),
    meta: {
      sidebar: true,
      layout: 'MainLayout',
      analytics: true,
      title: 'Value Change Analytics',
    },
    beforeEnter: [requireAuth, requireAnalyticsDebug],
  },
  // Data routes
  {
    path: '/transactions',
    component: (): RouteComponent =>
      import('@/views/Data/Transactions/Transactions.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAME.TRANSACTIONS,
        component: EmptyComponent,
        meta: {},
      },
      {
        path: ':id',
        name: ROUTE_NAME.TRANSACTIONS_ITEM,
        component: EmptyComponent,
      },
    ],
    meta: {
      sidebar: true,
      layout: 'MainLayout',
      title: 'Transactions',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/assets',
    component: (): RouteComponent => import('@/views/Data/Assets/Assets.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAME.ASSETS,
        component: EmptyComponent,
        meta: {},
      },
      {
        path: ':id',
        name: ROUTE_NAME.ASSETS_ITEM,
        component: EmptyComponent,
        children: [
          {
            path: 'prices',
            children: [
              {
                path: '',
                name: ROUTE_NAME.ASSETS_ITEM_PRICES,
                component: EmptyComponent,
              },
              {
                path: 'table',
                name: ROUTE_NAME.ASSETS_ITEM_PRICES_TABLE,
                component: EmptyComponent,
              },
            ],
          },
          {
            path: 'positions',
            name: ROUTE_NAME.ASSETS_ITEM_POSITIONS,
            component: EmptyComponent,
          },
          {
            path: 'transactions',
            name: ROUTE_NAME.ASSETS_ITEM_TRANSACTIONS,
            component: EmptyComponent,
          },
          {
            path: 'values',
            name: ROUTE_NAME.ASSETS_ITEM_VALUES,
            component: EmptyComponent,
          },
          {
            path: 'tags',
            name: ROUTE_NAME.ASSETS_ITEM_TAGS,
            component: EmptyComponent,
          },
          {
            path: 'security-master',
            name: ROUTE_NAME.ASSETS_ITEM_SECURITY_MASTER,
            component: EmptyComponent,
          },
          {
            path: 'contacts',
            name: ROUTE_NAME.ASSETS_ITEM_CONTACTS,
            component: EmptyComponent,
          },
          {
            path: 'documents',
            name: ROUTE_NAME.ASSETS_ITEM_DOCUMENTS,
            component: EmptyComponent,
          },
        ],
      },
    ],
    meta: {
      sidebar: true,
      layout: 'MainLayout',
      title: 'Assets',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/tags',
    component: (): RouteComponent => import('@/views/Data/Tags/Tags.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAME.TAGS,
        component: EmptyComponent,
      },
      {
        path: ':id',
        name: ROUTE_NAME.TAGS_ITEM,
        component: EmptyComponent,
      },
    ],
    meta: {
      sidebar: true,
      layout: 'MainLayout',
      title: 'Tags',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/income-accounts',
    component: (): RouteComponent =>
      import('@/views/Data/Accounts/AccountsIncome.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAME.INCOME_ACCOUNTS,
        component: EmptyComponent,
      },
      {
        path: ':id',
        name: ROUTE_NAME.INCOME_ACCOUNTS_ITEM,
        component: EmptyComponent,
      },
    ],
    meta: {
      sidebar: true,
      layout: 'MainLayout',
      title: 'Income accounts',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/expense-accounts',
    component: (): RouteComponent =>
      import('@/views/Data/Accounts/AccountsExpense.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAME.EXPENSE_ACCOUNTS,
        component: EmptyComponent,
      },
      {
        path: ':id',
        name: ROUTE_NAME.EXPENSE_ACCOUNTS_ITEM,
        component: EmptyComponent,
      },
    ],
    meta: {
      sidebar: true,
      layout: 'MainLayout',
      title: 'Expense accounts',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/currencies',
    component: (): RouteComponent =>
      import('@/views/Data/Currencies/Currencies.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAME.CURRENCIES,
        component: EmptyComponent,
        meta: {},
      },
      {
        path: ':id',
        name: ROUTE_NAME.CURRENCIES_ITEM,
        component: EmptyComponent,
        children: [
          {
            path: 'prices',
            children: [
              {
                path: '',
                name: ROUTE_NAME.ASSETS_ITEM_PRICES,
                component: EmptyComponent,
              },
              {
                path: 'table',
                name: ROUTE_NAME.ASSETS_ITEM_PRICES_TABLE,
                component: EmptyComponent,
              },
            ],
          },
          {
            path: 'tags',
            name: ROUTE_NAME.CURRENCIES_ITEM_TAGS,
            component: EmptyComponent,
          },
          {
            path: 'security-master',
            name: ROUTE_NAME.CURRENCIES_ITEM_SECURITY_MASTER,
            component: EmptyComponent,
          },
        ],
      },
    ],
    meta: {
      sidebar: true,
      layout: 'MainLayout',
      title: 'Currencies',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/documents',
    component: (): RouteComponent =>
      import('@/views/Data/Documents/Documents.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAME.DOCUMENTS,
        component: EmptyComponent,
      },
      {
        path: ':id',
        name: ROUTE_NAME.DOCUMENTS_ITEM,
        component: EmptyComponent,
      },
    ],
    meta: {
      sidebar: true,
      layout: 'MainLayout',
      title: 'Documents',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/contacts',
    component: (): RouteComponent =>
      import('@/views/Data/Contacts/Contacts.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAME.CONTACTS,
        component: EmptyComponent,
      },
      {
        path: ':id',
        name: ROUTE_NAME.CONTACTS_ITEM,
        component: EmptyComponent,
      },
    ],
    meta: {
      sidebar: true,
      layout: 'MainLayout',
      title: 'Contacts',
    },
    beforeEnter: requireAuth,
  },
  // Linked data routes
  {
    path: '/connectors',
    component: (): RouteComponent =>
      import('@/views/LinkedData/LinkedData.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAME.LINKED_DATA,
        component: (): RouteComponent =>
          import('@/views/LinkedData/LinkedDataNoData.vue'),
      },
      {
        path: ':connector_id',
        children: [
          {
            path: '',
            name: ROUTE_NAME.LINKED_DATA_CONNECTOR,
            component: (): RouteComponent =>
              import('@/views/LinkedData/Connectors/Connectors.vue'),
            props: route => ({
              connectorId: route.params.connector_id as string,
            }),
          },
          {
            path: ':account_id',
            component: (): RouteComponent =>
              import('@/views/LinkedData/Accounts/Account.vue'),
            props: route => ({
              connectorId: route.params.connector_id as string,
              accountId: route.params.account_id as string,
            }),
            children: [
              {
                path: ':event_id?',
                name: ROUTE_NAME.LINKED_DATA_ACCOUNT,
                component: (): RouteComponent =>
                  import('@/views/LinkedData/Accounts/AccountData.vue'),
                children: [
                  {
                    path: '',
                    name: ROUTE_NAME.LINKED_DATA_ACCOUNT_INFO,
                    component: (): RouteComponent =>
                      import('@/views/LinkedData/Accounts/AccountDataInfo.vue'),
                    props: route => ({
                      eventId: route.params.event_id,
                    }),
                  },
                  {
                    path: 'positions',
                    name: ROUTE_NAME.LINKED_DATA_ACCOUNT_POSITIONS,
                    component: (): RouteComponent =>
                      import(
                        '@/views/LinkedData/Accounts/AccountDataPositions.vue'
                      ),
                    props: route => ({
                      eventId: route.params.event_id,
                    }),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/linked-transactions',
        name: ROUTE_NAME.LINKED_DATA_TRANSACTIONS,
        component: (): RouteComponent =>
          import('@/views/LinkedData/Transactions/Transactions.vue'),
        meta: {
          title: 'Linked transactions',
        },
      },
      {
        path: '/linked-mapping-rules',
        name: ROUTE_NAME.LINKED_DATA_MAPPING_RULES,
        component: (): RouteComponent =>
          import('@/views/LinkedData/Rules/Rules.vue'),
        meta: {
          title: 'Mapping rules',
        },
      },
    ],
    meta: {
      sidebar: true,
      connectors: true,
      layout: 'MainLayout',
      title: 'Connectors',
    },
    beforeEnter: requireAuth,
  },
  // Settings routes
  {
    path: '/settings',
    component: (): RouteComponent => import('@/views/Settings/Settings.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAME.SETTINGS,
        component: (): RouteComponent =>
          import('@/views/Settings/Common/Common.vue'),
        meta: {
          permanent: true,
        },
      },
      {
        path: 'account',
        name: ROUTE_NAME.SETTINGS_ACCOUNT,
        component: (): RouteComponent =>
          import('@/views/Settings/Account/Account.vue'),
        meta: {
          title: 'Account settings',
          permanent: true,
        },
      },
    ],
    meta: {
      sidebar: true,
      layout: 'MainLayout',
      title: 'Common settings',
    },
    beforeEnter: requireAuth,
  },
  // Subscriptions
  {
    path: '/subscription',
    children: [
      {
        path: '',
        name: ROUTE_NAME.SUBSCRIPTION,
        component: (): RouteComponent =>
          import('@/views/Subscription/Subscription.vue'),
        meta: {
          title: 'Subscriptions',
        },
      },
      {
        path: 'plans',
        name: ROUTE_NAME.SUBSCRIPTION_PLANS,
        component: (): RouteComponent =>
          import('@/views/Subscription/SubscriptionPlans.vue'),
        meta: {
          title: 'Plans',
        },
      },
    ],
    meta: {
      sidebar: true,
      permanent: true,
      layout: 'MainLayout',
      title: 'Subscription',
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/policy',
    name: ROUTE_NAME.POLICY,
    component: (): RouteComponent => import('@/views/Legal/Policy.vue'),
    meta: {
      layout: 'EmptyLayout',
      title: 'Privacy policy - Allposit Inc.',
      hideIntercomLauncher: true,
    },
  },
  {
    path: '/terms',
    name: ROUTE_NAME.TERMS,
    component: (): RouteComponent => import('@/views/Legal/Terms.vue'),
    meta: {
      layout: 'EmptyLayout',
      title: 'Terms and conditions - Allposit Inc.',
      hideIntercomLauncher: true,
    },
  },
  {
    path: '/permission-denied',
    name: ROUTE_NAME.PERMISSION_DENIED,
    component: (): RouteComponent => import('@/views/Error.vue'),
    props: { title: 'Error 403', subtitle: 'Permission denied' },
    meta: {
      title: 'Permission denied - Error 403',
      hideIntercomLauncher: true,
    },
  },
  {
    path: '/upgrade-plan',
    name: ROUTE_NAME.UPGRADE_PLAN,
    component: (): RouteComponent => import('@/views/Error.vue'),
    props: { title: 'Error 403', subtitle: 'Permission denied' },
    meta: {
      title: 'Permission denied - Error 403',
      hideIntercomLauncher: true,
    },
  },
  {
    path: '/:catchAll(.*)',
    component: (): RouteComponent => import('@/views/Error.vue'),
    props: { title: 'Error 404', subtitle: 'Page not found' },
    meta: {
      title: 'Page not found - Error 404',
      hideIntercomLauncher: true,
    },
  },
] as RouteRecordRaw[]

if (!isProdMode) {
  routes.push(
    {
      path: '/signup',
      name: ROUTE_NAME.SIGNUP,
      component: (): RouteComponent => import('@/views/User/SignUp.vue'),
      meta: {
        title: 'Sign up - Allposit Inc.',
      },
      beforeEnter: isAlreadyAuth,
    },
    {
      path: '/signup-confirmation-code',
      name: ROUTE_NAME.SIGNUP_CONFIRMATION_CODE,
      component: (): RouteComponent =>
        import('@/views/User/SignUpConfirmationCode.vue'),
      props: route => ({
        username: route.query.username as string,
        code: route.query.code as string,
      }),
      meta: {
        title: 'Sign up confirmation - Allposit Inc.',
      },
      beforeEnter: isAlreadyAuth,
    },
  )
}

export default routes
