<template>
  <UIPanel
    v-model="clearedSearch"
    v-model:storage="searchStorage"
    placeholder="Search positions..."
  />
  <UIGrid
    v-bind="{ columns, sm }"
    v-model:sort="sort"
    :items="outputItems"
    wrapped
    scrollable
  >
    <template #actions="{ item, size }">
      <UIButton
        v-if="item?.raw_json.allposit_id"
        v-bind="{ size }"
        label="View Transaction"
        :leading="PencilIcon"
        variant="secondary"
        fill="light"
        @click="handleClickViewTransaction(item)"
      />
      <UIButton
        v-if="item"
        v-bind="{ size }"
        label="Source view"
        :leading="ArrowTopRightOnSquareIcon"
        variant="gray"
        fill="light"
        @click="handleOpenJsonCLick(item)"
      />
    </template>
  </UIGrid>
  <Teleport to="body">
    <UIModal
      :is-open="dialogJSON"
      :is-minimized="dialogJsonMinimized"
      :type="ModalType.SLIDEPANEL"
      title="JSON data"
      @hide="dialogJSON = false"
      @minimize="handleDialogJsonMinimize"
    >
      <UIJsonTree v-bind="{ json }" size="xxsmall">JSON data</UIJsonTree>
    </UIModal>
  </Teleport>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { LinkedDataConnectorItemPosition, ModalType, Sort } from '@types'

import { stringToLocalDateString } from '@/helpers/dates'
import { numberFormat } from '@/helpers/numbers'

import { useModalsStore } from '@/store/modals'
import { useTransactionsBunchStore } from '@/store/transactions/bunch'

import { UIModal, UIGrid, UIPanel, UIButton, UIJsonTree } from '@ui'
import {
  ArrowTopRightOnSquareIcon,
  PencilIcon,
} from '@heroicons/vue/24/outline'
import useGridSort from '@/components/hooks/gridSort'
import useGridFilter from '@/components/hooks/gridFilter'

type Props = {
  items: LinkedDataConnectorItemPosition[]
}

const props = defineProps<Props>()

const modalsStore = useModalsStore()
const transactionsBunchStore = useTransactionsBunchStore()

const searchStorage = defineModel<string | undefined>('search')
const sort = defineModel<Sort[] | undefined>('sort')

const clearedSearch = ref<string>()

const json = ref()
const dialogJSON = ref(false)
const dialogJsonMinimized = ref(false)

const sm = '1fr 1fr'
const columns = computed(() => [
  {
    name: 'normalized_data.description',
    caption: 'Description',
  },
  {
    name: 'normalized_data.symbol',
    caption: 'Symbol',
  },
  {
    name: 'normalized_data.price',
    caption: 'Price',
    headerValueClasses: 'ui-grid__header-value--right',
    cellValueClasses: 'ui-grid__cell--right blurable-number',
    formatter: numberFormat,
    default: '.5fr',
  },
  {
    name: 'normalized_data.qty',
    caption: 'Qty',
    headerValueClasses: 'ui-grid__header-value--right',
    cellValueClasses: 'ui-grid__cell--right blurable-number',
    formatter: numberFormat,
    default: '.5fr',
  },
  {
    name: 'normalized_data.value',
    caption: 'Value',
    headerValueClasses: 'ui-grid__header-value--right',
    cellValueClasses: 'ui-grid__cell--right blurable-number',
    formatter: numberFormat,
    default: '.5fr',
  },
  {
    name: 'normalized_data.last_updated',
    caption: 'As of',
    formatter: (value: string) =>
      stringToLocalDateString(value, { relative: true }),
    default: '6rem',
  },
])

const inputItems = computed(() => props.items || [])
const filteredItems = useGridFilter<LinkedDataConnectorItemPosition>(
  columns,
  inputItems,
  clearedSearch,
)
const outputItems = useGridSort(sort, columns, filteredItems, [
  'normalized_data.price',
  'normalized_data.qty',
  'normalized_data.value',
])

const handleClickViewTransaction = (
  position: LinkedDataConnectorItemPosition,
) => {
  event?.stopPropagation()
  if (position.raw_json.allposit_id) {
    const instance = transactionsBunchStore.getElementById(
      position.raw_json.allposit_id,
    )
    if (!instance) return
    const modalInstance = modalsStore.init(instance.id, instance)
    modalInstance?.open(modalsStore.getZIndex())
  } else {
    const instance = transactionsBunchStore.createElement()
    instance.set({
      linked_id: position.raw_json.id,
    })
    const modalInstance = modalsStore.init(instance.id, instance)
    modalInstance?.open(modalsStore.getZIndex())
  }
}

const handleOpenJsonCLick = (position: LinkedDataConnectorItemPosition) => {
  json.value = position.raw_json
  dialogJSON.value = true
}

const handleDialogJsonMinimize = (data: boolean) => {
  dialogJsonMinimized.value = data
}

defineOptions({
  name: 'AccountPositions',
})
</script>
