<template>
  <div class="wizzard__form">
    <div class="wizzard-step-account">
      <UIRadiobox
        v-model="useExisting"
        label="Use existing account"
        :disabled="!hasExistingAccounts"
        :value="true"
      />
      <UIRadiobox
        v-model="useExisting"
        label="Create new account"
        :value="false"
      />
    </div>
    <template v-if="useExisting">
      <component
        :is="transaction.getFormAccountId1()"
        :focus-on-load="focusNameOnLoad"
        :instance="transaction"
        disallowCreate
        label=""
        tabindex="1"
      />
    </template>
    <template v-else>
      <component
        :is="account.getFormName()"
        :focus-on-load="focusNameOnLoad"
        :instance="account"
        label="Name"
        placeholder="Ex. John Smith Trust"
        tabindex="1"
      />
      <component
        v-bind="{ restrictions }"
        :is="account.getFormType()"
        :focus-on-load="focusTypeOnLoad"
        :instance="account"
        label="Type"
        tabindex="2"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { AssetClass, TransactionClass } from '@types'

import { ASSET_FIELD } from '@/const'

import { UIRadiobox } from '@ui'

type Props = {
  transaction: TransactionClass
  account: AssetClass
  asset: AssetClass
  hasExistingAccounts: boolean
}

const props = defineProps<Props>()

defineOptions({ inheritAttrs: false })

const useExisting = defineModel<boolean>('useExistingAccount')

const restrictions = computed(
  () => props.transaction.settings?.entries[0].account?.includes,
)

const focusNameOnLoad = computed(
  () => !props.account.field(ASSET_FIELD.NAME).value,
)

const focusTypeOnLoad = computed(
  () => !focusNameOnLoad.value && !props.account.field(ASSET_FIELD.TYPE).value,
)
</script>

<style>
.wizzard-step-account {
  @apply flex flex-col gap-2;
}
</style>
